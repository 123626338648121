import React from 'react'
import { handleDateToddmmyyyy } from '../../../helpers/genericFunc'
import NumberInput from '../../genericComponents/NumberInput'
const GroupConctactForm = ({data,currentIndex,handleOnChangeFunction,handleDeleteFunction,handlemanualSendWelcomeEmail,totalRegister,groupEnterpriseGroupId,totalContactAdmin,}) => {
    const {
        idWelcomeEmail,
        contactType,
        contactName,
        contactlastname,
        contactEmail,
        contactNumber,
        contactSendDate,
        contactStatus,
    } = data
  return (
    <>
        <div className='col-11 mt-3 justify-content-center'>
            <div className='row'>
                <div className='col-lg-2 col-xl-2 col-xxl-2 px-1'>
                    <div className='form'>
                        <select
                            className="form__input fs-075em px-0"
                            id={`group-contactType${currentIndex}`}
                            name="contactType"
                            value={contactType}
                            onChange={(e) => handleOnChangeFunction(e, currentIndex,false)}
                        > 
                            <option value={''}> </option>
                            <option value={7}>Owner</option>
                            <option value={2}>Administrador</option>
                        </select>
                        <label className='form__label'>
                            Tipo contacto
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2'>
                    <div className='form'>
                        { groupEnterpriseGroupId > 0 && idWelcomeEmail > 0 &&(
                            idWelcomeEmail !== '' && (
                                contactType === 2 && (
                                    <img
                                        src={
                                            contactStatus === 1 || contactStatus === '' ? "../assets/img/icons/icono_warning_amarillo.png"
                                                : contactStatus === 2 ? "../assets/img/icons/icono_aprobado_verde_activo.png"
                                                    : "../assets/img/icons/icono_error_activo.png"
                                        }
                                        className="mx-1 icon-size-16x15 icon-input"
                                        alt="img-date"
                                    />
                                )
                            )
                        )}
                        <input
                            type='text'
                            /* className={`form__input fs-075em ${groupEnterpriseGroupId > 0 && idWelcomeEmail > 0 ? 'ps-4' : 'px-0'}`} */
                            className={`form__input fs-075em px-0 ${groupEnterpriseGroupId === 0 ? 'px-0' : idWelcomeEmail === '' ? 'px-0' : contactType === 2 ? 'ps-4' : 'px-0' }`}
                            placeholder=' '
                            id={`group-contactName${currentIndex}`}
                            name="contactName"
                            autoComplete='off'
                            maxLength="200"
                            value={contactName}
                            data-bs-toggle="tooltip" 
                            title={contactName}
                            onChange={(e) => handleOnChangeFunction(e, currentIndex,false)}
                        />
                        <label className='form__label'>
                            Nombre contacto
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2'>
                    <div className='form'>
                        <input
                            type='text'
                            className='form__input fs-075em px-0'
                            placeholder=' '
                            autoComplete='off'
                            id={`group-contactlastname${currentIndex}`}
                            name="contactlastname"
                            maxLength="200"
                            value={contactlastname}
                            data-bs-toggle="tooltip" 
                            title={contactlastname}
                            onChange={(e) => handleOnChangeFunction(e, currentIndex,false)}
                        />
                        <label className='form__label'>
                            Apellidos
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2 px-0'>
                    <div className='form'>
                        <input
                            type='text'
                            className='form__input fs-075em px-0'
                            placeholder=' '
                            id={`group-contactEmail${currentIndex}`}
                            name="contactEmail"
                            maxLength="200"
                            autoComplete='off'
                            value={contactEmail}
                            data-bs-toggle="tooltip" 
                            title={contactEmail}
                            onChange={(e) => handleOnChangeFunction(e, currentIndex, false, contactStatus)}
                        />
                        <label className='form__label'>
                        Correo electrónico
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2'>
                    <NumberInput
                        label='Teléfono'
                        generalValue={{}}
                        value={contactNumber}
                        setValue={handleOnChangeFunction}
                        maxValue={10}
                        autoComplete='off'
                        name='contactNumber'
                        id={`group-contactNumber${currentIndex}`}
                        index={currentIndex}
                    />
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2 px-0 mx-0'>
                    {contactSendDate !== '' && (
                        <div className='form input-img'>
                        <input
                            type='text'
                            className='form__input fs-075em border-0 px-0'
                            placeholder=' '
                            disabled={true}
                            id={`group-contactSendDate${currentIndex}`}
                            name="contactSendDate"
                            value={groupEnterpriseGroupId > 0 ? ((currentIndex + 1) < totalRegister ? contactSendDate === '' ? (handleDateToddmmyyyy(new Date())) : (handleDateToddmmyyyy(contactSendDate)) : '') : ('')}
                        />
                        <label className='form__label'>
                            Fecha envio
                        </label>
                        {groupEnterpriseGroupId > 0  && (currentIndex + 1) < totalRegister && (
                            contactType === 2 && (
                                <img
                                    src={contactStatus === 2 || idWelcomeEmail === '' ? "../assets/img/icons/Icono_enviar_inactivo.png" : "../assets/img/icons/Icono_enviar.png"}
                                    className="mx-1 icon-size-16x15"
                                    alt="img-date"
                                    onClick={() => {
                                        if (contactStatus === 2 || idWelcomeEmail === '') {
                                            return
                                        }
                                        handlemanualSendWelcomeEmail(contactStatus,idWelcomeEmail)
                                    }}
                                />
                            )
                        )}
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className='col-1 d-flex justify-content-start flex-wrap align-content-center px-0 mx-0'>
            {/* <span>{currentIndex} totalContactAdmin {totalContactAdmin} totalRegister {totalRegister}</span> */}
            {groupEnterpriseGroupId > 0 ? (
                    parseInt(contactType) === 2 ? (
                        totalContactAdmin > 1 && (
                           <img
                               src="../assets/img/icons/Icono_eliminar_activo.png"
                               className="mx-0 icon-size-16x15 pointer"
                               alt="img-trash"
                               onClick={() => handleDeleteFunction(currentIndex,groupEnterpriseGroupId,idWelcomeEmail)}
                           />
                        )
                    ) : (
                        (currentIndex + 1) < totalRegister && (
                           <img
                               src="../assets/img/icons/Icono_eliminar_activo.png"
                               className="mx-0 icon-size-16x15 pointer"
                               alt="img-trash"
                               onClick={() => handleDeleteFunction(currentIndex,groupEnterpriseGroupId,idWelcomeEmail)}
                           />
                        )
                    )

                ) : (
                    (currentIndex + 1) < totalRegister && (
                        <img
                        src="../assets/img/icons/Icono_eliminar_activo.png"
                        className="mx-0 icon-size-16x15 pointer"
                        alt="img-trash"
                        onClick={() => handleDeleteFunction(currentIndex,groupEnterpriseGroupId,idWelcomeEmail)}
                        />
                    )

                )
            }
        </div>
    </>
  )
}
export default GroupConctactForm