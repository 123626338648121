import React, { useState, useEffect } from 'react'
import {
    getEnterpriseActive,
    getProductsEnterprise,
    getLaunchsByService,
    //getEnterpriseJob,
    //getBossEnterprise,
    getBranches,
    getCategoryJob,
    getAreas,
    getCollabWithOutAssingSpecificLaunch,
} from '../../models/enterprise_model'
// TODO: Components
import CollabTable from './CollabTable';
import * as toastr from 'toastr';
import $ from 'jquery';

export const AddUsers = () => {
    const [enterprises, setEnterprises] = useState([])
    const [enterpriseSelected, setEnterpriseSelected] = useState(0)
    const [services, setServices] = useState([])
    const [serviceSelected, setServiceSelected] = useState([])
    const [launchs, setLaunchs] = useState([])
    const [launchsSelected, setLaunchsSelected] = useState([])
    //const [jobsCat, setJobsCat] = useState([])
    //const [bossesCat, setBossesCat] = useState([])
    const [branchesCat, setBranchesCat] = useState([])
    const [categoriesJobCat, setCategoriesJobCat] = useState([])
    const [areasCat, setAreasCat] = useState([])
    const [collaborators, setCollaborators] = useState([])
    const [showTable, setShowTable] = useState(false)
    useEffect(() => {
        getEnterpriseActive().then(response => {
            if (response) {
                setEnterprises(response)
            } else {
                toastr.error('Hubo un error al cargar las empresas.', '¡Ooops!');
            }
        });

    }, [])

    useEffect(() => {
        if (enterpriseSelected > 0) {
            getProductsEnterprise(enterpriseSelected).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            if (response.listProducts.length > 0) {
                                let activeServices = response.listProducts.filter((prod) => prod.acquired === 1)
                                if (activeServices.length > 0) {
                                    setServices(activeServices)
                                    //getEnterpriseJob(enterpriseSelected).then(response => setJobsCat(response.enterprisesJobs))
                                    //getBossEnterprise(enterpriseSelected).then(response => setBossesCat(response.listBoss))
                                    getBranches(enterpriseSelected).then(response => setBranchesCat(response.branches))
                                    getCategoryJob(enterpriseSelected).then(response => setCategoriesJobCat(response.categorys))
                                    getAreas(enterpriseSelected).then(response => setAreasCat(response.areas))
                                } else {
                                    toastr.warning('La empresa no tiene productos activos.')
                                }
                            } else {
                                toastr.warning('La empresa no tiene productos activos.')
                            }
                            break;
                        case 1001:
                            toastr.error('La empresa seleccionado no existe o no se encuentra activa.')
                            break;
                        case 1003:
                            toastr.error('No existen productos activos.')
                            break;
                        default:
                            toastr.error('Hubo un error al cargar los productos de la empresa.', '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Hubo un error al cargar los productos de la empresa.', '¡Ooops!');
                }
            });

        } else {
            setServices([])
            setServiceSelected([])
            setLaunchs([])
            setLaunchsSelected([])
        }

    }, [enterpriseSelected])

    useEffect(() => {
        if ((serviceSelected !== "" && parseInt(serviceSelected) !== 0 && serviceSelected.length > 0) && enterpriseSelected > 0) {
            let parameters = serviceSelected.split("-");
            getLaunchsByService(enterpriseSelected, parameters[0], parameters[1], parameters[2]).then(response => {
                if (response) {
                    switch (response.code) {
                        case 1:
                            if (response.listLauch.length > 0) {
                                let launchsAllowed = response.listLauch.filter((launch) => (launch.status === 1 || launch.status === 2) && launch.active === 1)
                                if (launchsAllowed.length > 0) {
                                    setLaunchs(launchsAllowed)
                                } else {
                                    toastr.warning('La empresa no tiene ejercicios activos.')
                                }
                            } else {
                                toastr.warning('La empresa no tiene ejercicios.')
                            }
                            break;
                        case 1001:
                            toastr.error('La empresa seleccionado no existe o no se encuentra activa.')
                            break;
                        case 1002:
                            toastr.error('No existen ejercicios activos.')
                            break;
                        default:
                            toastr.error('Hubo un error al cargar los ejercicios de la empresa.', '¡Ooops!');
                            break;
                    }
                } else {
                    toastr.error('Hubo un error al cargar los ejercicios.', '¡Ooops!');
                }
            });

        } else {
            setLaunchsSelected([])
            setLaunchs([])
        }

    }, [enterpriseSelected, serviceSelected])


    const handleShowTable = groupId => {
        if (groupId !== '0') {
            getCollabWithOutAssingSpecificLaunch(enterpriseSelected, groupId, 0, 0).then(response => {
                if (response.code === 1 || response.code === 200) {
                    let collabArr = []
                    response.listCollab.forEach(collab => {
                        let obj = {}
                        //bossesCat.forEach(e => { if (e.bossEntId === collab.bossEntId) { obj = { ...collab, bossEntId: e.description, } } })
                        //jobsCat.forEach(e => { if (e.jobEntId === collab.jobEntId) { obj = { ...obj, jobEntId: e.description, } } })
                        branchesCat.forEach(e => { if (e.branchEntId === collab.branchEntId) { obj = { ...collab, branchEntId: e.description, } } })
                        areasCat.forEach(e => { if (e.areaId === collab.areaEntId) { obj = { ...obj, areaEntId: e.description, } } })
                        categoriesJobCat.forEach(e => { if (e.categoryEntId === collab.categoryEntId) { obj = { ...obj, categoryEntId: e.description, } } })
                        collabArr.push(obj)
                    })
                    //console.log(collabArr)
                    setCollaborators(collabArr)
                    setShowTable(true)
                } else {
                    setShowTable(false)
                    toastr.info('No hay colaboradores para asignar.', '¡Ooops!');
                }
            })
        }
    }

    return (
        <div className="col-12 my-5">
            <div className="row">
                <div className="col-11 mx-auto mb-3">
                    <h3>Asignar Usuarios a Ejercicios en Curso</h3>
                </div>
                <div className="col-11 mx-auto">
                    <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
                        <div className='card-body'>
                            <div className='col-sm-11 col-lg-12 col-xxl-12 mx-auto generic__show-container top container-1'>
                                <div className="row mb-3">
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='row'>
                                                    <div className="col-3 text-center">
                                                        <span className="fw-bold w-35">Empresa</span>
                                                    </div>
                                                    <div className="col-9">
                                                        <select
                                                            className="form-select bg-white selectBorder"
                                                            id="enterprises"
                                                            name="enterprises"
                                                            value={enterpriseSelected}
                                                            onChange={(e) => {
                                                                setShowTable(false)
                                                                setServices([])
                                                                setServiceSelected([])
                                                                setLaunchs([])
                                                                setLaunchsSelected([])
                                                                if (e.target.value > 0) {
                                                                    $('#enterprises').addClass("selectBorder");
                                                                    $('#enterprises').removeClass('border-danger');

                                                                } else {
                                                                    $('#enterprises').addClass('border-danger');
                                                                    $('#enterprises').removeClass('selectBorder');
                                                                }
                                                                setEnterpriseSelected(e.target.value)
                                                            }}
                                                        >
                                                            <option key={"enterprise-" + 0} value={0}>Seleccione...</option>
                                                            {enterprises.map(data => (
                                                                <option key={"enterprise-" + data.enterpriseId} value={data.enterpriseId}>{data.comercial}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {services.length > 0 &&
                                                <div className='col-6'>
                                                    <div className='row'>
                                                        <div className="col-3 text-center">
                                                            <span className="fw-bold w-35">Servicios</span>
                                                        </div>
                                                        <div className="col-9">
                                                            <select
                                                                className="form-select bg-white selectBorder"
                                                                id="services"
                                                                name="services"
                                                                value={serviceSelected}
                                                                onChange={(e) => {
                                                                    setShowTable(false)
                                                                    setLaunchsSelected([])
                                                                    setLaunchs([])
                                                                    if (e.target.value !== 0) {
                                                                        $('#services').addClass("selectBorder");
                                                                        $('#services').removeClass('border-danger');

                                                                    } else {
                                                                        $('#services').addClass('border-danger');
                                                                        $('#services').removeClass('selectBorder');
                                                                    }
                                                                    setServiceSelected(e.target.value)
                                                                }}
                                                            >
                                                                <option key={"enterprise-" + 0} value={0}>Seleccione...</option>
                                                                {services.map(data => (
                                                                    <option key={`service-${data.idProduct}-${data.idCategory}-${data.idSubcategory}`} value={`${data.idProduct}-${data.idCategory}-${data.idSubcategory}`}>{data.nameSubcategory !== "" ? `${data.nameCategory}-${data.nameSubcategory}` : data.nameCategory}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {services.length > 0 && launchs.length > 0 &&
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="row">
                                                {launchs.length > 0 &&
                                                    <div className='col-6'>
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <span className="fw-bold w-35">Ejercicios</span>
                                                            </div>
                                                            <div className="col-9">
                                                                <select
                                                                    className="form-select bg-white selectBorder"
                                                                    id="launchs"
                                                                    name="launchs"
                                                                    value={launchsSelected}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== 0) {
                                                                            $('#launchs').addClass("selectBorder");
                                                                            $('#launchs').removeClass('border-danger');

                                                                        } else {
                                                                            $('#launchs').addClass('border-danger');
                                                                            $('#launchs').removeClass('selectBorder');
                                                                        }
                                                                        setLaunchsSelected(e.target.value)
                                                                        handleShowTable(e.target.value)
                                                                    }}
                                                                >
                                                                    <option key={"enterprise-" + 0} value={0}>Seleccione...</option>
                                                                    {launchs.map(data => (
                                                                        <option key={`idGroup-${data.idGroup}`} value={`${data.idGroup}`}>{data.description}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {showTable && (collaborators.length > 0 ? (
                                    <div className='row mt-3'>
                                        <CollabTable
                                            collaborators={collaborators}
                                            launchsSelected={launchsSelected}
                                            handleShowTable={handleShowTable}
                                        />
                                    </div>
                                ) : (
                                    <div className='row mt-3'>
                                        <h3 className='text-center'>No hay colaboradores por mostrar</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}