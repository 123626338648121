import $ from 'jquery'

/**
 * 
 * @param {*} target 
 * @param {*} elementId 
 * @param {*} elementClass 
 * @param {*} elementTag 
 * @returns 
 */
export const sortArray = ({ target }, elementId, elementClass, elementTag) => {
    let filter, ul, li, a, i, txtValue;
    filter = target.value.toLowerCase();
    ul = document.getElementById(elementId);
    li = ul.getElementsByClassName(elementClass);
    let records = 0;
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName(elementTag)[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toLowerCase().indexOf(filter) > -1) {
            li[i].style.display = '';
            records = records = 1;
        } else {
            li[i].style.display = 'none';
        }
    }
    if (records === 0) {
        return true
    } else {
        return false
    }
}

export const handleDropdown = id => {
    if ($(`#divDetalle-${id}`).hasClass('d-none')) {
        $(`#divDetalle-${id}`).removeClass('d-none')
        $(`#divDetalle-${id}`).addClass('detail-show')
        $(`#imgDetail-${id}`).attr('src', '../assets/img/icons/icono_agregar_menos_activo.png')
    } else {
        $(`#divDetalle-${id}`).removeClass('detail-show')
        $(`#divDetalle-${id}`).addClass('d-none')
        $(`#imgDetail-${id}`).attr('src', '../assets/img/icons/Icono_agregar_activo.png')
    }
}
