//const
const GET_GROUP = 'GET_GROUP'
const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS'
const GET_GROUP_ERROR = 'GET_GROUP_ERROR'

const ADD_GROUP = 'ADD_GROUP'

const SET_GROUP_INITIALSTATE = 'SET_GROUP_INITIALSTATE'

const initialState = {
    fetching: false,
    error: false,
    groupDetails: {},
}

//reducer
const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GROUP:
            return {
                ...state,
                fetching: true
            }

        case GET_GROUP_SUCCESS:
            return {
                ...state,
                fetching: false,
                error: false,
                groupDetails: {
                    ...action.payload
                }
            }

        case GET_GROUP_ERROR:
            return {
                ...state,
                fetching: false,
                error: {
                    message: action.payload
                },
                groupDetails: {}
            }

        case ADD_GROUP:
            return {
                ...state,
                groupDetails: {
                    idGroup:0,
                }
            }

        case SET_GROUP_INITIALSTATE:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default groupReducer

//actions
export const doSetGroupQuizAction = (group) => (dispatch, _getState) => {
    // console.log('entro doSetGroupQuizAction');
    // console.log('type: ', typeof group, ' grupo: ',group);
    // console.log(' length: ',Object.entries(group).length);
    dispatch({
        type: GET_GROUP
    })
    if(group && Object.entries(group).length > 0){
        dispatch({
            type: GET_GROUP_SUCCESS,
            payload: group
        })
    }else{
        dispatch({
            type: GET_GROUP_ERROR,
            payload: 'Ocurrió un error al obtener la información del grupo.'
        })
    }

}

export const doSetGroupInitialState = () => (dispatch, _getState) => {
    dispatch({
        type: SET_GROUP_INITIALSTATE,
    })
}
