import axios from 'axios'
import * as toastr from 'toastr'

export const getAllPlans = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}planController/getAllPlans`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    obj.validation = false
                    //toastr.info('No hay promociones activas.', '¡Ooops!')
                    //obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const savePlanInfo = async (
    plan,
    benefitsArr,
) => {
    try {
        let formData = new FormData()
        formData.append('idPlan', plan.id)
        formData.append('codePlan', plan.code)
        formData.append('title', plan.title)
        formData.append('description', plan.description)
        formData.append('active', plan.active)
        formData.append('benefits', JSON.stringify(benefitsArr))
        const result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}planController/savePlanInfo`,
            data: formData,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error);
    }
}
