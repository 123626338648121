import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// API calls
import { recoveryPassword } from '../../models/sprUser_model';
// Tools
import toastr from 'toastr';
import Swal from 'sweetalert2';

const PasswordRecovery = () => {
    // Variables
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="col-12 text-center my-5">
                <h1>¿Olvidaste tu contraseña?</h1>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 mx-auto rounded mb-5">
                <div className="card shadow">
                    <div className="card-body">
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                let emailRegex = /^\w+([.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/;
                                if (!emailRegex.test(email)) {
                                    toastr.warning('Formato de correo incorrecto. Ej: correo@correo.com', '¡Ooops!');
                                    return;
                                }
                                recoveryPassword(email).then(response => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                            case 200:
                                                Swal.fire({
                                                    icon: 'success',
                                                    text: 'Se envió el correo correctamente.',
                                                    confirmButtonText: 'Login',
                                                }).then(() => {
                                                    navigate('sponsor-access/login');
                                                });
                                                break;
                                            case 1001:
                                                toastr.error('Problemas con el formato del correo.', '¡Ooops!');
                                                break;
                                            case 1002:
                                                toastr.error('El correo no está registrado en la plataforma.', '¡Ooops!');
                                                break;
                                            default:
                                                toastr.error('Problemas con el envío de correo. Contacta a soporte. Codigo: ' + response.code + ' Mensaje: ' + response.message, '¡Ooops!');
                                                break;
                                        }
                                    } else {
                                        toastr.error('Problemas con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                                    }
                                });
                            }}
                        >
                            <div className="col-12 text-center py-3">
                                <p>Recuperación de contraseña</p>
                                <p>Ingresa tu correo electrónico para recuperar tu contraseña</p>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control form-control-sm"
                                        required
                                        placeholder="Correo electrónico. Ej. correo@correo.com"
                                        onChange={e => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 py-3 d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-secondary"
                                    onClick={() => {
                                        navigate('sponsor-access/login');
                                    }}
                                >Regresar</button>
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                >Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PasswordRecovery;
