import axios from 'axios';

/**
 * Metodo para obtener la lista de campañas
 * @returns JSON Campaign Array
 */
export const getCampaigns = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getCampaigns",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para actualizar o agregar nuevas campaña
 * idCampaign 0 when new
 * @param {object} campaign 
 * @returns JSON response success
 */
export const setUpdateCampaign = async (campaign) => {
    const formData = new FormData();

    formData.append("request", JSON.stringify({
        ...campaign
    }));

    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "sponsor/setUpdateCampaign",
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData,
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener las organizaciones
 * @param {int} idCampaign 
 * @returns JSON object
 */
export const getOrganizationsFree = async (idCampaign) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getOrganizationsFree?idCampaign=" + idCampaign,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para obtener los patrocinadores
 * @param {int} idCampaign 
 * @returns JSON object
 */
export const getSponsors = async (idCampaign) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getSponsors?idCampaign=" + idCampaign,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Endpoint to get all organization registed as free
 * @returns 
 */
export const getFreeOrganizations = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getFreeOrganizations",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * Endpoint to get all sponsors
 * @returns 
 */
export const getSponsorList = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getSponsorList",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {number} idSponsorProfile 
 * @returns 
 */
export const getSponsorProfileById = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sponsor/getSponsorProfileById?idSponsorProfile=" + idSponsorProfile,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
