import React, { useEffect } from 'react';
// API calls
import { getAddressByCP } from '../../models/catalog_model';
// Tools
import $ from 'jquery';
import PropTypes from 'prop-types';

const OrgProfile = ({
    entProfile,
    sectors,
    countries,
    subSectors,
    setSection,
    setEntProfile,
}) => {
    const {
        comercial,
        short,
        email,
        sector,
        subSector,
        country,
        anio,
        collaborators,
        zip,
        idState,
        idTown,
        idNeighborhood,
        street,
        numExt,
        numInt,
    } = entProfile;
    useEffect(() => {
        getAddressByCP(zip).then(response => {
            $("#idState, #idTown, #idNeighborhood").html("");
            $("#idState").append('<option value="' + response[0].idState + '">' + response[0].nameState + '</option>');
            $("#idTown").append('<option value="' + response[0].idTown + '">' + response[0].nameTown + '</option>');
            $.each(response, function (i, item) {
                $("#idNeighborhood").append('<option value="' + response[i].idNeighborhood + '">' + response[i].nameNeighborhood + '</option>');
            });
            setEntProfile({
                ...entProfile,
                idState: response[0].idState,
                idTown: response[0].idTown,
                idNeighborhood: response[0].idNeighborhood,
            });
        })
        // eslint-disable-next-line
    }, [/* entProfile, setEntProfile, zip */]);
    return (
        <div className="col-11 mx-auto">
            <div className='card border-0 shadow rounded-25-px py-3 generic__show-main-container container-1'>
                <div className='card-body'>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                    >
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto mx-auto generic__show-container left container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Nombre Comercial</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            maxLength="150"
                                            id="comercial"
                                            name="comercial"
                                            value={comercial}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Nombre Corto</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            maxLength="150"
                                            id="short"
                                            name="short"
                                            value={short}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto mx-auto generic__show-container rigth container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Correo Institucional</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            maxLength="150"
                                            id="email"
                                            name="email"
                                            value={email}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Sector o Industria</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="sector"
                                            name="sector"
                                            value={sector}
                                        >
                                            {
                                                sectors.map(data => (
                                                    <option key={"sector-" + data.sector_id}>{data.description}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto mx-auto generic__show-container left container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Subsector</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="subSector"
                                            name="subSector"
                                            value={subSector}
                                        >
                                            {
                                                subSectors.map(data => (
                                                    <option key={"subSector-" + data.sub_sector_id}>{data.description}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Año de Fundación</span>
                                        <input
                                            type="number"
                                            className="form-control bg-white"
                                            disabled
                                            id="anio"
                                            name="anio"
                                            value={anio}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto mx-auto generic__show-container rigth container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Colaboradores</span>
                                        <input
                                            type="number"
                                            className="form-control bg-white"
                                            disabled
                                            id="collaborators"
                                            name="collaborators"
                                            value={collaborators}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6"></div>
                            </div>
                        </div>
                        <h3 className='generic__show-container top container-2'>Dirección</h3>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Código Postal</span>
                                        <input
                                            type="number"
                                            className="form-control bg-white"
                                            disabled
                                            id="zip"
                                            name="zip"
                                            value={zip}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">País</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="country"
                                            name="country"
                                            value={country}
                                        >
                                            {
                                                countries.map(data => (
                                                    <option key={"country-" + data.id}>{data.description}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container rigth container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Estado</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="idState"
                                            name="idState"
                                            value={idState}
                                        >
                                            <option>Selecciona</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Ciudad</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="idTown"
                                            name="idTown"
                                            value={idTown}
                                        >
                                            <option>Selecciona</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Colonia</span>
                                        <select
                                            className="form-select bg-white"
                                            disabled
                                            id="idNeighborhood"
                                            name="idNeighborhood"
                                            value={idNeighborhood}
                                        >
                                            <option>Selecciona</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Calle</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            id="street"
                                            name="street"
                                            value={street}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container rigth container-1'>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Núm. Exterior</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            id="numExt"
                                            name="numExt"
                                            value={numExt}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-xxl-6">
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text w-35 fw-bold">Núm. Interior</span>
                                        <input
                                            type="text"
                                            className="form-control bg-white"
                                            disabled
                                            id="numInt"
                                            name="numInt"
                                            value={numInt}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 generic__show-container container-2'>
                            <button
                                type='button'
                                className='btn btn-sm bg-blue-navy text-white'
                                onClick={() => setSection(1)}
                            >Regresar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

OrgProfile.propTypes = {
    entProfile: PropTypes.object.isRequired,
    sectors: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    subSectors: PropTypes.array.isRequired,
    setSection: PropTypes.func.isRequired,
    setEntProfile: PropTypes.func.isRequired,
}

export default OrgProfile;
