import axios from 'axios'

export const getNotifications = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}notificationController/getNotifications`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

export const markNotificationRead = async (idAdmNotification) => {
    try {
        let formData = new FormData()
        formData.append("idAdmNotification", idAdmNotification)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}notificationController/markNotificationRead`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
