import React from 'react'
import { EnterpriseCard } from './EnterpriseCard'
import { handleDateToyyyymmdd } from '../../../helpers/genericFunc'

const GroupDetail = ({
    setHideHeader,
    setShowForm,
    setEnterpriseObj,
    contactsList,
    groupName,
    idGroup,
    setGroupInfo,
    setGroupContactsList,
    setServices,
    listEnterprises,
    setEntRegisterDate,
    handleDeleteCard
}) => {

    const handleGroupContactList=(contacts)=>{
        let contactArr = []
        if(contacts.length>0){
            contacts.forEach(data => {
                contactArr.push({
                    idWelcomeEmail: data.idWelcomeEmail,
                    contactType: data.idRol,
                    contactName: data.name,
                    contactlastname: data.lastName,
                    contactEmail: data.email,
                    contactNumber: data.phone,
                    contactSendDate: data.sendDate,
                    contactStatus: data.status ? data.status : ''
                })
            })
        }
        return contactArr

    }
    const handleGroupServices = products =>{
        // console.log('prod: ',products);
        const arrServices = [
            {
                idProduct: 1,
                idCategory: 1,
                idSubCategory: 1,
                description: 'Estado Emocional',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 1,
                idSubCategory: 2,
                description: 'Entorno Laboral',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 2,
                idSubCategory: 0,
                description: 'Clima Laboral',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 4,
                idSubCategory: 0,
                description: 'Cultura Organizacional',
                checked: true,
                status: 1,
                onDate: '',
            },
        ]
        let newArr = []
            arrServices.forEach(subData => {
                let recorded = false
                products.forEach(data => {
                    if (data.idProduct === subData.idProduct && data.idCategory === subData.idCategory && data.idSubCategory === subData.idSubCategory) {
                        newArr.push({
                            ...subData,
                            checked: data.active === 1 ? true : false,
                            onDate: handleDateToyyyymmdd(data.onDate),
                        })
                        recorded = true
                    } 
                })
                if (!recorded) {
                    newArr.push({
                        ...subData,
                        checked: false,
                    })
                    
                }
            })
            return (newArr)
    }
  return (
    <div className='d-flex mx-auto my-3'>
        <div className='col-2 mx-3 justify-content-center'>
            <div
                className='card rounded-10px border-0 shadow-sm py-3 h-100 pointer text-decoration-none w-75'
                onClick={() => {
                    setShowForm(true)
                    setHideHeader(true)
                    setGroupInfo({
                        idgroup:idGroup,
                        groupLabel:groupName,
                        isFromGroupForm: false,
                    })
                    setGroupContactsList(handleGroupContactList(contactsList))
                    setServices(handleGroupServices(listEnterprises.length >0 ? listEnterprises[0].productEnterprise.length > 0 ? listEnterprises[0].productEnterprise : [] : []))
                    setEntRegisterDate(new Date())
                }}
            >
                <div className='card-body d-flex flex-wrap align-content-center'>
                    <div className='row d-flex'>
                        <div className='col-12 mb-3 d-flex justify-content-center'>
                            <img
                                src='./assets/img/icons/icono_agregar_inactivo_disable.png'
                                className='img-h30px'
                                alt='logo'
                            />
                        </div>
                        <div className='col-12 mb-1 d-flex justify-content-center'>
                            <span className='fs-0625em font-family-quicksand text-ad-grey-white-guay text-center'>Agregar organización</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            listEnterprises && listEnterprises.length > 0  &&
            listEnterprises.map(data => (
                <EnterpriseCard
                    enterpriseData={data}
                    setEnterpriseObj={setEnterpriseObj}
                    handleGroupServices={handleGroupServices}
                    setShowForm={setShowForm}
                    setHideHeader={setHideHeader}
                    idGroup={idGroup}
                    groupName={groupName}
                    setGroupInfo={setGroupInfo}
                    setGroupContactsList={setGroupContactsList}
                    contactsList={handleGroupContactList(contactsList)}
                    setEntRegisterDate={setEntRegisterDate}
                    totalEnterprisesGroup={listEnterprises.length}
                    handleDeleteCard={handleDeleteCard}
                    key={`group-${idGroup}-enterprise-${data.idEnterprise}`}
                />
            ))
        }
    </div>
  )
}

export default GroupDetail;

