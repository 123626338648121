import React, {
    useState,
    useEffect,
} from 'react'
// TODO: API calls
import { deleteEnterprise } from '../../../models/organization_model'
// TODO: Components
import Loader from '../../genericComponents/Loader'
import EnterpriseFilters from './EnterpriseFilters'
import EnterpriseCard from './EnterpriseCard'
import EnterpriseForm from './EnterpriseForm'
// TODO: Generic func
import { handleScroll } from '../../../helpers/genericFunc'
// TODO: redux
import { connect } from 'react-redux'
import { getOrganizations } from '../../../redux/getOrganizationList'
// TODO: Tools
import * as toastr from 'toastr'
import Swal from 'sweetalert2'

const EnterprisePanel = ({
    // Redux
    organizationList,
    getOrganizations,
    // props
    auth,
    showEnterpriseNotification,
    setHideHeader,
}) => {

    // string
    const [entRegisterDate, setEntRegisterDate] = useState('')

    // number
    const [selectedFilter, setSelectedFilter] = useState(10)

    // object
    const [enterpriseObj, setEnterpriseObj] = useState({})
    const [groupInfo, setGroupInfo] = useState(({
        idgroup: '',
        groupLabel: '',
        isFromGroupForm: false,
    }))

    // arr
    const [enterpriseList, setEnterpriseList] = useState([])
    const [enterpriseListBackup, setEnterpriseListBackup] = useState([])

    const [enterprisesListAdd, setEnterprisesListAdd] = useState([])
    const groupContactsList = []

    // bool
    const [noResultsMessage, setNoResultsMessage] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [contactDeleted, setContactDeleted] = useState(false)

    useEffect(() => {
        if (organizationList.organizationList) {
            if (organizationList.organizationList.length > 0) {
                if (organizationList.fetching) {
                    setEnterpriseList(organizationList.organizationList)
                    setEnterpriseListBackup(organizationList.organizationList)
                    setShowLoader(false)
                    if (showEnterpriseNotification) {
                        organizationList.organizationList.forEach(enterpriseData => {
                            if (enterpriseData.idEnterprise === parseInt(sessionStorage.getItem('showEnterpriseNotification'))) {
                                setEnterpriseObj({
                                    ...enterpriseData,
                                    registerDate: enterpriseData.productEnterprise.length > 0 ? new Date(enterpriseData.productEnterprise[0].onDate) : '',
                                })
                                setEntRegisterDate(enterpriseData.registerDate)
                                setShowForm(true)
                                setHideHeader(true)
                                sessionStorage.removeItem('showEnterpriseNotification')
                            }
                        })
                    }
                } else {
                    setShowLoader(false)
                    toastr.info('Hubo un error al cargar los grupos.', '¡Ooops!')
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationList])

    useEffect(() => {
        if (document.getElementById('top-page') !== null) {
            handleScroll('top-page')
        }
    }, [showForm])

    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue
        filter = target.value.toLowerCase()
        ul = document.getElementById("enterprisesList")
        li = ul.getElementsByTagName('div')
        let records = 0
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("p")[0]
            if (a !== undefined) {
                txtValue = a.textContent || a.innerText
                if (txtValue.toLowerCase().indexOf(filter) > -1) {
                    li[i].style.display = ""
                    records = records = 1
                } else {
                    li[i].style.display = "none"
                }
            }

        }
        if (records === 0) {
            setNoResultsMessage(true)
        } else {
            setNoResultsMessage(false)
        }
    }

    const handleFilters = filterID => {
        if (filterID === 10) {
            setEnterpriseList(enterpriseListBackup)
            setShowLoader(false)
        } else {
            const getFiltered = enterpriseListBackup.filter(data => data.color === filterID)
            //console.log(getFiltered)
            setEnterpriseList(getFiltered)
            setShowLoader(false)
        }
    }

    const handleCleanForm = () => {
        if (contactDeleted) {
            getOrganizations()
            setContactDeleted(false)
        }
        setHideHeader(false)
        setShowForm(false)
        setEnterpriseObj({})
    }

    const handleReloadData = () => {
        setSelectedFilter(10)
        getOrganizations()
        setShowLoader(true)
    }

    const handleDeleteCard = (idEnterprise, enterpriseName, activeCollaborators) => {
        Swal.fire({
            icon: 'warning',
            title: `¿Estás seguro de eliminar esta organización: ${enterpriseName}?`,
            //text: 'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
            html:
                `${activeCollaborators > 0 ? ('<span class="text-danger fs-8">* Esta organización ya cuenta con colaboradores activos.</span></br>') : ''}` +
                'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
            showCancelButton: true,
            cancelButtonText: 'No, Cancelar',
            confirmButtonText: 'Si, Eliminar',
            confirmButtonColor: '#af2f72',
            cancelButtonColor: '#15253c',
            customClass: {
                actions: 'my-actions-alert',
                cancelButton: 'order-1 right-gap-alert',
                confirmButton: 'order-2-alert',
                denyButton: 'order-3-alert',
                icon: 'icon-size-modal',
                title: 'fs-5',
            },
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEnterprise(idEnterprise).then(response => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                toastr.info('Se eliminó correctamente la organización.', '¡Éxito!')
                                handleReloadData()
                                break;
                            case 1000:
                                toastr.info('La empresa ya está inactiva o no se encuentra.', '¡Ooops!')
                                break;
                            case 1001:
                                toastr.info('Por lo menos debe haber una empresa asiganada a un grupo.', '¡Ooops!')
                                break;
                            case 99:
                                toastr.info('Code 99: Revisa tu conexión a internet e intenta de nuevo. Si el error persiste, contacta al equipo de soporte.', '¡Ooops!')
                                break;
                            default:
                                toastr.info('Error desconocido: Revisa tu conexión a internet e intenta de nuevo. Si el error persiste, contacta al equipo de soporte.', '¡Ooops!')
                                break;
                        }
                    } else {
                        toastr.info('Error desconocido. Revisa tu conexión a internet e intenta de nuevo. Si el error persiste, contacta al equipo de soporte.', '¡Ooops!')
                    }
                })
            }
        })
    }

    return (
        <div className='col-12'>
            <div id='enterprisePanel' />
            {showLoader ? (
                <Loader />
            ) : (
                showForm ? (
                    <EnterpriseForm
                        userID={auth.id}
                        enterpriseObj={enterpriseObj}
                        setShowForm={setShowForm}
                        handleCleanForm={handleCleanForm}
                        handleReloadData={handleReloadData}
                        entRegisterDate={entRegisterDate}
                        setEnterprisesListAdd={setEnterprisesListAdd}
                        enterprisesListAdd={enterprisesListAdd}
                        groupInfo={groupInfo}
                        groupContactsListCop={groupContactsList}
                        getOrganizations={getOrganizations}
                        setContactDeleted={setContactDeleted}
                    />
                ) : (
                    <div className='card rounded-10px border-0 shadow py-3 generic__show-main-container container-1'>
                        <div className='card-body'>

                            <div className="col-12 mx-auto generic__show-container top container-2">

                                <EnterpriseFilters
                                    sortArray={sortArray}
                                    handleFilters={handleFilters}
                                    selectedFilter={selectedFilter}
                                    setSelectedFilter={setSelectedFilter}
                                />

                                <div
                                    className='row generic__show-container top container-1'
                                    id="enterprisesList"
                                >

                                    <div className='col-2 mb-4'>
                                        <div
                                            className='card rounded-10px border-0 shadow-sm py-3 h-100 pointer text-decoration-none w-75'
                                            onClick={() => {
                                                setShowForm(true)
                                                setHideHeader(true)
                                                setGroupInfo({
                                                    idgroup: '',
                                                    groupLabel: '',
                                                    isFromGroupForm: false,
                                                })
                                            }}
                                        >
                                            <div className='card-body d-flex flex-wrap align-content-center'>
                                                <div className='row'>
                                                    <div className='col-12 mb-3 d-flex justify-content-center'>
                                                        <img
                                                            src='./assets/img/icons/icono_agregar_inactivo_disable.png'
                                                            className='img-h30px'
                                                            alt='logo'
                                                        />
                                                    </div>
                                                    <div className='col-12 mb-1 d-flex justify-content-center'>
                                                        <span className='fs-0625em text-ad-grey-white-guay font-family-quicksand text-center'>Agregar organización</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {enterpriseList.map(data => (
                                        <EnterpriseCard
                                            enterpriseData={data}
                                            setEnterpriseObj={setEnterpriseObj}
                                            setShowForm={setShowForm}
                                            setHideHeader={setHideHeader}
                                            setEntRegisterDate={setEntRegisterDate}
                                            setGroupInfo={setGroupInfo}
                                            handleDeleteCard={handleDeleteCard}
                                            key={`enterprise-${data.idEnterprise}`}
                                        />
                                    ))}

                                    {noResultsMessage && (
                                        <div className='col-12'>
                                            <p className='text-center'>No se encontraron empresas con ese nombre</p>
                                        </div>
                                    )}

                                </div>

                            </div>
                        </div>
                    </div>
                )
            )}

        </div >
    )
}

const mapStateToProps = ({ organizationList }) => {
    return {
        organizationList,
    }
}

export default connect(mapStateToProps, { getOrganizations })(EnterprisePanel)