import React, { Fragment, useState } from 'react';
// API calls
import { getSubSectorCat } from '../../models/catalog_model';
import { getSponsorProfileById } from '../../models/sponsor_model';
// Tools
import PropTypes from 'prop-types';

const SponsorList = ({
    sponsors,
    setSponsorProfile,
    setSection,
    setSubSectors,
}) => {
    const [showMessage, setShowMessage] = useState(false)
    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById("sponsorList");
        li = ul.getElementsByClassName('sponsor-card');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toLowerCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                records = records = 1;
            } else {
                li[i].style.display = "none";
            }
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }
    const habdleCardClick = id => {
        getSponsorProfileById(id).then(response => {
            if (response) {
                getSubSectorCat(response.profile[0].sector).then(response => {
                    if (response) {
                        setSubSectors(response);
                    }
                });
                setSponsorProfile({
                    logo: response.profile[0].logo,
                    register_date: response.profile[0].register_date,
                    dateOfRenovation: response.profile[0].dateOfRenovation,
                    organization: response.profile[0].organization,
                    sector: response.profile[0].sector,
                    subSector: response.profile[0].subSector,
                    webPage: response.profile[0].webPage,
                    facebook: response.profile[0].facebook,
                    instagram: response.profile[0].instagram,
                    twitter: response.profile[0].twitter,
                    linkedin: response.profile[0].linkedin,
                });
                setSection(2);
            }
        });
    }
    return (
        <Fragment>
            <div className="col-12">
                <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2">
                                <div className='row d-flex justify-content-end'>
                                    <div className="col-sm-12 col-lg-5 col-xxl-4">
                                        <div className="input-group input-group-guay mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Buscar"
                                                onChange={sortArray}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row sponsorList generic__show-container rigth container-1' id="sponsorList">
                                    {showMessage && <h2 className='text-center p-3'>No hay resultados</h2>}
                                    {
                                        sponsors.map(data => (
                                            <div
                                                key={data.id}
                                                className='col-sm-12 col-md-6 col-lg-3 col-xxl-2 mb-3 hm-100 sponsor-card'
                                            >
                                                <div
                                                    className='card h-100 border-0 shadow pointer jumping'
                                                    onClick={() => habdleCardClick(data.id)}
                                                >
                                                    <div className='card-body'>
                                                        <div className='col-12 text-center h-25'>
                                                            <span>{data.description}</span>
                                                        </div>
                                                        <div className='col-12 d-flex justify-content-center flex-wrap align-content-center h-75'>
                                                            <img
                                                                className='sponsor-img'
                                                                src={data.logo}
                                                                alt={'logoGuay'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

SponsorList.propTypes = {
    sponsors: PropTypes.array.isRequired,
    setSponsorProfile: PropTypes.func.isRequired,
    setSection: PropTypes.func.isRequired,
    setSubSectors: PropTypes.func.isRequired,
}

export default SponsorList;
