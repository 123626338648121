import React, { useState, useEffect } from 'react';
// API calls
import { getOrderSpecific } from '../../../../models/shop_model';
import { getSectorCat } from '../../../../models/catalog_model';
// Tools
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import * as toastr from 'toastr';

const StatusTable = ({
    setShowDetails,
    setDetails,
    setNotes,
    showTable,
    listClosed,
    listPending,
    listProgressing,
    setOrderID
}) => {

    // Globla variables
    const [sectors, setSectors] = useState([]);

    useEffect(() => {
            getSectorCat().then(response => {
                setSectors(response);
            });
    }, []);

    // Table Props
    const options = {
        noDataText: 'No se encontraron solicitudes',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    };

    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setIcon = (cell, row, rowIndex) => {
        return (
            <div>
                <i
                    onClick={() => {
                        setOrderID(row.idOrder);
                        getOrderSpecific(row.idOrder).then(response => {
                            if (response) {
                                switch (response.code) {
                                    case 1:
                                        setDetails(response);
                                        setNotes(response.orderLogList);
                                        setShowDetails(true);
                                        break;
                                    default:
                                        toastr.error(`No se encuentra detalle de la solicitud`);
                                        break;
                                }
                            }
                        });
                    }}
                    className='bi bi-eye text-pink pointer font-medium-1'
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Ver detalles"
                />
            </div>
        )
    }

    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setSector = (cell, row, rowIndex) => {
        for (let x in sectors) {
            if (sectors[x].sector_id === cell) {
                return (
                    <span>{sectors[x].description}</span>
                )
            }
        }
    }

    return (
        <div className="col-12 generic__show-container container-1">
            <BootstrapTable
                data={showTable === 0 ? (listPending) : (showTable === 1 ? (listProgressing) : (listClosed))}
                hover
                striped
                pagination={true}
                options={options}
                tableStyle={{ border: 'none', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                trClassName='fs-7 border-0'
                trStyle={{ border: '.5px' }}
            >
                <TableHeaderColumn
                    dataField="idOrder"
                    isKey
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataFormat={setIcon}
                    width="5%"
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="order"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >No. Solicitud</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="descStatus"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >Estatus</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="enterpriseName"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >Empresa</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="phone"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >Teléfono</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="numCollabs"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >Colaboradores</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="sectorId"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataFormat={setSector}
                >Sector</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="register_date"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >Fecha</TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
}

StatusTable.propTypes = {
    setShowDetails: PropTypes.func.isRequired,
    setDetails: PropTypes.func.isRequired,
    setNotes: PropTypes.func.isRequired,
    showTable: PropTypes.number.isRequired,
    listClosed: PropTypes.array.isRequired,
    listPending: PropTypes.array.isRequired,
    listProgressing: PropTypes.array.isRequired,
    setOrderID: PropTypes.func.isRequired
}

export default StatusTable;
