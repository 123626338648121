import React, { useEffect, useState } from 'react'
import GeneralDataGroupForm from './GeneralDataGroupForm'
import GroupHeader from './GroupHeader'
import EnterpriseForm from '../enterprise/EnterpriseForm';
import { handleDateTextFormat,handleScroll,handleDateToyyyymmdd } from '../../../helpers/genericFunc'
import $ from "jquery";
import * as toastr from 'toastr';
// TODO: redux
import { connect } from 'react-redux'
import { deleteGroupWelcomeEmail, saveOrgGroupInfo, manualSendWelcomeEmail } from '../../../models/groups_model';
import { deleteEnterprise } from '../../../models/organization_model';
import { getGroups } from '../../../redux/getGroupList';
import Swal from 'sweetalert2'
import { getAllPlans } from '../../../models/benefits_model';

const GroupForm = ({groupSpecific,auth,getGroups}) => {
    // console.log('groupSpecific: ',groupSpecific,' type: ',typeof groupSpecific);
    // console.log('groupDetails: ',groupDetails,' type: ',typeof groupDetails);
    // Detalles del grupo cuando es edicion 
    const { groupDetails } = groupSpecific
    // id de usuario en sesion
    const [userId, setuserId] = useState(auth.id)
    const [totalContactAdmin, setTotalContactAdmin] = useState(0)

    // Bool
    const [hideHeader, setHideHeader] = useState(false)
    const [showForm, setShowForm] = useState(false)

    // text 
    const [groupNameLabel, setGroupNameLabel] = useState('')
    const [entRegisterDate, setEntRegisterDate] = useState(new Date())

    // object
    const [enterpriseObj, setEnterpriseObj] = useState({})
    const [generalData, setGeneralData] = useState({
        groupEnterpriseGroupId : 0,
        groupCommercialName : '',
        registerDate: handleDateTextFormat(new Date()),
        renewalDate: new Date(),
        adminType: 2,
        listOrganization: [],
        idUser: userId,
    })
    const [groupInfo, setGroupInfo] = useState({
        idgroup:0,
        groupLabel:'',
        isFromGroupForm: true,
    })
    // array
    const [groupBenefitPlans, setGroupBenefitPlans] = useState([
       {
            idPlanEnterprise: '',
            idPlan: '',
            numPolicy: '',
            onDate: '',
        }
    ])
    const [groupContactsList, setGroupContactsList] = useState([
        {
            idWelcomeEmail: '',
            contactType: '',
            contactName: '',
            contactlastname: '',
            contactEmail: '',
            contactNumber: '',
            contactSendDate: '',
            contactStatus: '',
        },
    ])
    const [groupContactsListCop, setGroupContactsListCop] = useState([])
    const [enterprisesListAdd, setEnterprisesListAdd] = useState([])

    const [indexTemporalEnterprise, setIndexTemporalEnterprise] = useState('')
    const [warningMessages, setWarningMessages] = useState([])
    const [groupProducts, setGroupProducts] = useState([])
    const [services, setServices] = useState([])
    const [groupServices, setGroupServices] = useState([])
    const [planList, setPlanList] = useState([])
    useEffect(() => {
        handleScroll('top-page')
    }, [showForm])

    // console.log('services: ',services);
    // console.log('generalData: ', generalData,' id: ',generalData.groupEnterpriseGroupId, 'groupContactsListCop: ',groupContactsListCop);
    // console.log('groupContactsList: ', groupContactsList);
    // console.log('enterprisesListAdd: ',enterprisesListAdd );
    useEffect(() => {
        setuserId(auth.id)
    }, [auth,auth.id])
    
    useEffect(() => {
        if(groupSpecific && Object.entries(groupSpecific).length > 0 && Object.entries(groupDetails).length > 0){
            // console.log('modifica form: ',groupDetails);
            setGeneralData({
                groupEnterpriseGroupId : groupDetails.groupEnterpriseGroupId,
                groupCommercialName : groupDetails.groupCommercialName,
                registerDate: handleDateTextFormat(groupDetails.groupRegisterDate),
                renewalDate: groupDetails.groupRenewalDate && groupDetails.groupRenewalDate !== '' ? new Date(groupDetails.groupRenewalDate): new Date(groupDetails.groupRegisterDate) ,
                adminType: 2,
                listOrganization: groupDetails.groupOrganizations,
                idUser: userId,
            })
            setGroupNameLabel(groupDetails.groupCommercialName)
            setGroupInfo({
                idgroup:groupDetails.groupEnterpriseGroupId,
                groupLabel:groupDetails.groupCommercialName,
                isFromGroupForm: true,
            })
            if(groupDetails.groupPlans.length > 0){
                let plansArr = []
                groupDetails.groupPlans.forEach(data => {
                    plansArr.push({
                        idPlanEnterprise:data.idPlanEnterprise,
                        idPlan: data.idPlan,
                        numPolicy: data.numPolicy,
                        onDate: data.onDate !== '' ? data.onDate : ''
                    })
                })
                plansArr.push({
                    idPlanEnterprise: '',
                    idPlan: '',
                    numPolicy: '',
                    onDate: '',
                })
                setGroupBenefitPlans(plansArr)
            }
            // let objKeys = Object.values(groupDetails.groupWelcomeEmails)
            // if(Object.entries(groupDetails.groupWelcomeEmails).length > 0){
            if(groupDetails.groupWelcomeEmails.length > 0){
                let contactArr = []
                let totalAdmin = 0
                // objKeys.forEach(data => {
                groupDetails.groupWelcomeEmails.forEach(data => {
                    contactArr.push({
                        idWelcomeEmail: data.idWelcomeEmail,
                        contactType: data.idRol,
                        contactName: data.name,
                        contactlastname: data.lastName,
                        contactEmail: data.email,
                        contactNumber: data.phone,
                        contactSendDate: data.sendDate,
                        contactStatus: data.status ? data.status : '',
                    })
                    if (data.idRol === 2) {
                        totalAdmin++
                    }
                })
                contactArr.push({
                    idWelcomeEmail: '',
                    contactType: '',
                    contactName: '',
                    contactlastname: '',
                    contactEmail: '',
                    contactNumber: '',
                    contactSendDate: '',
                    contactStatus:'',
                })
                setGroupContactsList(contactArr)
                setTotalContactAdmin(totalAdmin)
            }
            if(groupDetails.groupOrganizations.length > 0){
                console.log('groupProducts: ',groupDetails.groupOrganizations[0].productEnterprise);
                setGroupProducts(groupDetails.groupOrganizations[0].productEnterprise)
            }
            if (sessionStorage.getItem('showEnterpriseNotification') !== null) {
                groupDetails.groupOrganizations.forEach((enterpriseData, index) => {
                    if (enterpriseData.idEnterprise === parseInt(sessionStorage.getItem('showEnterpriseNotification'))) {
                        setEnterpriseObj({
                            ...enterpriseData,
                            registerDate: enterpriseData.productEnterprise.length > 0 ? new Date(enterpriseData.productEnterprise[0].onDate) : '',
                        })
                        setEntRegisterDate(enterpriseData.registerDate)
                        setShowForm(true)
                        setHideHeader(true)
                        setIndexTemporalEnterprise(index)
                        sessionStorage.removeItem('showEnterpriseNotification')
                    }
                })
            }
        }else{
            setGeneralData({
                groupEnterpriseGroupId : 0,
                groupCommercialName : '',
                registerDate: handleDateTextFormat(new Date()),
                renewalDate: new Date(),
                adminType: 2,
                listOrganization: [],
                idUser: userId,
            })
            setGroupNameLabel('Grupo')
            setGroupInfo({
                idgroup:0,
                groupLabel:'Grupo',
                isFromGroupForm: true,
            })
            setGroupContactsList([
                {
                    idWelcomeEmail: '',
                    contactType: '',
                    contactName: '',
                    contactlastname: '',
                    contactEmail: '',
                    contactNumber: '',
                    contactSendDate: '',
                    contactStatus: '',
                }
            ])
            setGroupBenefitPlans([
                {
                    idPlanEnterprise: '',
                    idPlan: '',
                    numPolicy: '',
                    onDate: '',
                }
            ])
        } 
    }, [groupSpecific,groupDetails,userId])

    useEffect(() => {
      if(groupContactsList.length > 0){
        // const arr = groupContactsList.filter(contactData => contactData.contactType !== '')
        let arr = []
            groupContactsList.forEach(data => {
                if (data.contactType !== '') {
                    arr.push({
                        idWelcomeEmail: data.idWelcomeEmail,
                        contactType: data.contactType,
                        contactName: data.contactName,
                        contactlastname: data.contactlastname,
                        contactEmail: data.contactEmail,
                        contactNumber: data.contactNumber,
                        contactRegisterDate: data.contactSendDate,
                        // contactRegisterDate: handleDateToyyyymmdd(new Date()),
                    })
                }
            })
        setGroupContactsListCop(arr)
      }else{
        setGroupContactsListCop([])
      }
    
    }, [groupContactsList])

    useEffect(() => {
        if(services.length > 0){
            setGroupServices(services)
        }else{
            setGroupServices([])
        }
    }, [services])

    useEffect(() => {
        getAllPlans().then(response => {
            if (response.validation) {
                setPlanList(response.data.plans.filter(data => data.active === 1))
            }
        })
    }, [])
    

    const handleOnChangeBenefitPlan = (e, currentIndex, isDate) => {
        $(`input`).removeClass('border-danger')
        let arr = groupBenefitPlans
        let newRegister = true
        let obj = {
            ...arr[currentIndex],
        }
        let objKeys = Object.values(obj)

        objKeys.forEach(element => {
            if (element !== '' ) {
                newRegister = false
            }
        })
        if (currentIndex === 0 && groupBenefitPlans.length === 1) {
            newRegister = true
        }
        if (currentIndex + 1 < groupBenefitPlans.length) {
            newRegister = false
        }
        // console.log(obj)
        let newArr = []
        arr.forEach((element, index) => {
            if (currentIndex === index) {
                if (newRegister) {
                    if (isDate) {
                        newArr.push(
                            {
                                ...obj,
                                onDate: e,
                            },
                            {
                                ...obj,
                            },
                        )
                    } else {
                        if(e.target.name === 'idPlan' && groupBenefitPlans.length > 0){
                            let valueExist = groupBenefitPlans.filter(data => parseInt(data.idPlan) === parseInt(e.target.value))
                            if(valueExist.length > 0){
                                toastr.warning('No puede repetir planes', '¡Ooops!')
                                newArr.push(
                                    {
                                        ...obj,
                                    },
                                )
                            }else{
                                newArr.push(
                                    {
                                        ...obj,
                                        [e.target.name]: e.target.value,
                                    },
                                    {
                                        ...obj,
                                    },
                                )
                            }
                        }else{
                            newArr.push(
                                {
                                    ...obj,
                                    [e.target.name]: e.target.value,
                                },
                                {
                                    ...obj,
                                },
                            )
                        }
                        
                    }
                } else {
                    if (isDate) {
                        newArr.push({
                            ...obj,
                            onDate: e,
                        })
                    } else {
                        if(e.target.name === 'idPlan' && groupBenefitPlans.length > 0){
                            let valueExist = groupBenefitPlans.filter(data => parseInt(data.idPlan) === parseInt(e.target.value))
                            if(valueExist.length > 0){
                                toastr.warning('No puede repetir planes', '¡Ooops!')
                                newArr.push(
                                    {
                                        ...obj,
                                    },
                                )
                            }else{
                                newArr.push(
                                    {
                                        ...obj,
                                        [e.target.name]: e.target.value,
                                    },
                                )
                            }
                        }else{
                            newArr.push({
                                ...obj,
                                [e.target.name]: e.target.value,
                            })
                        }
                    }
                }
            } else {
                newArr.push(element)
            }
        })
        setGroupBenefitPlans(newArr)

    }
    
    const handleDeleteBenefitPlan = (currentIndex,idPlanEnterprise) => {
        let arr = groupBenefitPlans
        let newArr = []
        if(idPlanEnterprise > 0 && idPlanEnterprise !== ''){
            toastr.warning('Este plan no puede ser eliminado debido a que ya fue contratado.', '¡Ooops!')
            return
        }else{
            newArr = arr.filter((element,index) => currentIndex !== index);
            // console.log('el nuevo array sera: ',newArr);
            setGroupBenefitPlans(newArr)
        }
    }

    const handleOnChangeContact = (e, currentIndex, isDate, status) => {
        $(`input`).removeClass('border-danger')
        // console.log(e.target.value)
        // console.log(e.target.name)
        // console.log(currentIndex)
        // console.log(groupContactsList)
        let arr = groupContactsList
        let newRegister = true
        let obj = {
            ...arr[currentIndex],
        }
        let objKeys = Object.values(obj)
        // console.log('objKeys',objKeys)
        // objKeys[0] = ''
        // console.log('objKeys2',objKeys)
        objKeys.forEach(element => {
            if (element !== '' ) {
                newRegister = false
            }
        })
        if (currentIndex === 0 && groupContactsList.length === 1) {
            newRegister = true
        }
        if (currentIndex + 1 < groupContactsList.length) {
            newRegister = false
        }
        // console.log(obj)
        let newArr = []
        let totalAdmin = 0
        arr.forEach((element, index) => {
            if (parseInt(element.contactType) === 2 && currentIndex !== index) {
                totalAdmin++
            }
            if (currentIndex === index) {
                if (newRegister) {
                    if (isDate) {
                        newArr.push(
                            {
                                ...obj,
                                contactSendDate: e,
                            },
                            {
                                ...obj,
                            },
                        )
                    } else {
                        newArr.push(
                            {
                                ...obj,
                                [e.target.name]: e.target.value,
                            },
                            {
                                ...obj,
                            },
                        )
                    }
                } else {
                    if (isDate) {
                        newArr.push({
                            ...obj,
                            contactSendDate: e,
                        })
                    } else {
                        newArr.push({
                            ...obj,
                            [e.target.name]: e.target.value,
                        })
                    }
                }
            } else {
                newArr.push(element)
            }
        })
        if (e.target.name === 'contactType' && e.target.value === '2') {
            totalAdmin++
        }
        //console.log(totalAdmin)
        setTotalContactAdmin(totalAdmin)
        // Validation
        if (generalData.groupEnterpriseGroupId > 0 && totalAdmin === 0 && e.target.name === 'contactType' && e.target.value !== '2') {
            toastr.warning('Debe tener por lo menos un administrador.', '¡Ooops!')
            return
        }
        // console.log('newArr: ',newArr)
        // setGroupContactsList(newArr)
        if (status === 2 && e.target.name === 'contactEmail') {
            const getMessage = warningMessages.filter(data => data === currentIndex)
            let messages = warningMessages
            if (getMessage.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Este correo ya tiene la cuenta activa, al registrar un nuevo correo se tendría que volver a activar. ¿Estás seguro que quieres editar el correo?',
                    showCancelButton: true,
                    confirmButtonText: 'Si, editar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setTotalContactAdmin(totalAdmin)
                        setGroupContactsList(newArr)
                        messages.push(currentIndex)
                        setWarningMessages(messages)
                    }
                })
            } else {
                setTotalContactAdmin(totalAdmin)
                setGroupContactsList(newArr)
            }
        } else {
            setTotalContactAdmin(totalAdmin)
            setGroupContactsList(newArr)
        }
    }

    const handleDeleteContact = (currentIndex,groupEnterpriseGroupId,idWelcomeEmail) => {
        // console.log('currentIndex: ',currentIndex,' groupEnterpriseGroupId: ',groupEnterpriseGroupId,'idWelcomeEmail: ',idWelcomeEmail);
        let arr = groupContactsList
        let newArr = []
        let totalAdmin2 = arr.filter((element,index) => currentIndex !== index && parseInt(element.contactType) === 2).length
        if(idWelcomeEmail > 0 && idWelcomeEmail !== ''){
            Swal.fire({
                icon: 'warning',
                text: `¿Estás seguro que quieres eliminar al contacto: ${arr[currentIndex].contactName} ${arr[currentIndex].contactlastname}?`,
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteGroupWelcomeEmail(groupEnterpriseGroupId, idWelcomeEmail).then(response => {
                        if(response){
                            switch (response?.code) {
                                case 1:
                                    // newArr = arr.filter((element,index) => currentIndex !== index);
                                    newArr = arr.filter((element) => element.idWelcomeEmail !== idWelcomeEmail);
                                    toastr.success('El contacto se elimino correctamente','¡Éxito!')
                                    // console.log('el nuevo array sera: ',newArr);
                                    setTotalContactAdmin(totalAdmin2)
                                    setGroupContactsList(newArr)
                                    break
                                case 1000:
                                    toastr.error('No se encontro el grupo o no esta activo.')
                                    totalAdmin2 = arr.filter((element) => parseInt(element.contactType) === 2).length
                                    setTotalContactAdmin(totalAdmin2)
                                    setGroupContactsList(arr)
                                    break
                                case 1001:
                                    toastr.error('No se encontro el contacto o no esta activo.')
                                    totalAdmin2 = arr.filter((element) => parseInt(element.contactType) === 2).length
                                    setTotalContactAdmin(totalAdmin2)
                                    setGroupContactsList(arr)
                                    break
                                case 1002:
                                    toastr.error('No se puede eliminar el contacto debido a que es el unico administrador de alguna empresa.')
                                    totalAdmin2 = arr.filter((element) => parseInt(element.contactType) === 2).length
                                    setTotalContactAdmin(totalAdmin2)
                                    setGroupContactsList(arr)
                                    break
                                default:
                                    totalAdmin2 = arr.filter((element) => parseInt(element.contactType) === 2).length
                                    setTotalContactAdmin(totalAdmin2)
                                    setGroupContactsList(arr)
                                    toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                                    break
                            }

                        }
                    })
                    
                }else{
                    totalAdmin2 = arr.filter((element) => parseInt(element.contactType) === 2).length
                    setTotalContactAdmin(totalAdmin2)
                    setGroupContactsList(arr)
                }
            })
        }else{
            newArr = arr.filter((element,index) => currentIndex !== index);
            // console.log('el nuevo array sera: ',newArr);
            setTotalContactAdmin(totalAdmin2)
            setGroupContactsList(newArr)
        }
    }

    const handlemanualSendWelcomeEmail= (contactStatus,idWelcomeEmail) => {
        // console.log('se enviara el recordatorio a ',idWelcomeEmail, ' con status: ',contactStatus);
        let arr = groupContactsList.filter((element) => element.idWelcomeEmail === idWelcomeEmail )
        // console.log('arr: ',arr);
        
        if(idWelcomeEmail > 0 && contactStatus !== 2){
            // console.log('se enviara');
            let obj = {
                contactEmail: arr[0].contactEmail,
                contactName: arr[0].contactName,
                contactNumber: arr[0].contactNumber,
                contactSendDate: new Date(),
                contactStatus: arr[0].contactStatus,
                contactType: arr[0].contactType,
                contactlastname: arr[0].contactlastname,
                idWelcomeEmail: arr[0].idWelcomeEmail,
            }
            manualSendWelcomeEmail(idWelcomeEmail).then(response => {
                if(response){
                    switch (response?.code) {
                        case 1:
                            toastr.success('Se envió el recordatorio.','¡Éxito!')
                            // console.log('quedara: ',obj);
                            const newArr = groupContactsList.map((element) => element.idWelcomeEmail === idWelcomeEmail ? obj : element)
                            // console.log('newArr: ',newArr);
                            setGroupContactsList(newArr)
                            break
                        case 1000:
                            toastr.error('No se encontro el contacto o no esta activo.')
                            break
                        case 1001:
                            toastr.error('No se encontro el grupo o no esta activo.')
                            break
                        default:
                            toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                            break
                    }

                }
            })

        }

    }

    
    const handleDeleteCard = (idEnterprise, enterpriseName, indexTemporalEnterprise, activeCollaborators) => {
        // console.log('eliminar empresa: ',idEnterprise, ' nombre: ',enterpriseName, ' index: ',indexTemporalEnterprise);
        Swal.fire({
            icon: 'warning',
            title: `¿Estás seguro de eliminar esta organización: ${enterpriseName}?`,
            //text: 'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
            html:
                `${activeCollaborators > 0 ? ('<span class="text-danger fs-8">* Esta organización ya cuenta con colaboradores activos.</span></br>') : ''}` +
                'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
            showCancelButton: true,
            cancelButtonText: 'No, Cancelar',
            confirmButtonText: 'Si, Eliminar',
            confirmButtonColor: '#af2f72',
            cancelButtonColor: '#15253c',
            customClass: {
                actions: 'my-actions-alert',
                cancelButton: 'order-1 right-gap-alert',
                confirmButton: 'order-2-alert',
                denyButton: 'order-3-alert',
                icon: 'icon-size-modal',
                title: 'fs-5',
            },
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                if(idEnterprise === 0){
                    const empresasTemActualizadas = enterprisesListAdd.filter((ent, index) => index !== indexTemporalEnterprise && ent)
                    // console.log('empresasTemActualizadas: ',empresasTemActualizadas);
                    setEnterprisesListAdd(empresasTemActualizadas)
                    toastr.success('Se eliminó correctamente la organización.', '¡Éxito!')
                }else{
                    deleteEnterprise(idEnterprise).then(response => {
                        if (response) {
                            switch (response?.code) {
                                case 1:
                                    const empresasTemActualizadas = generalData.listOrganization.filter((ent) => ent.idEnterprise !== idEnterprise && ent)
                                    console.log('empresasTemActualizadas: ',empresasTemActualizadas);
                                    setGeneralData({
                                        ...generalData,
                                        listOrganization: empresasTemActualizadas,
                                    })
                                    toastr.success('Se eliminó correctamente la organización.', '¡Éxito!')
                                    break
                                case 99:
                                    toastr.error('Ocurrió un error, verifique su conexión a internet.')
                                    break
                                case 1000:
                                    toastr.error('La Organización que desea eliminar no se encontró o ya fue desactivada anteriormente.')
                                    break
                                case 1001:
                                    toastr.error('El grupo organizacional no se puede quedar sin organizaciones. Por lo tanto la organización seleccionada no puede ser eliminada.')
                                    break
                                default:
                                    toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                                    break
                            }
                            
                        }else{
                            toastr.error(`Ocurrió un error desconocido, verifique su conexión a internet.`)
                        }
                    })
                }
                
            }
        })
    }

    const handleSubmitForm = e =>{
        e.preventDefault();
        let countErr = 0
        // validations
        if(generalData.groupCommercialName === '' || $('#groupCommercialName ').val() === ''){
            toastr.warning('El campo Nombre grupo es obligatorio', '¡Ooops!')
            $('#groupCommercialName ').addClass('border-bottom-red')
            handleScroll('groupCommercialName ')
            countErr++
            return
        }

        if(groupContactsList.length === 0){
            toastr.warning('Debe agregar por lo menos un contacto', '¡Ooops!');
            handleScroll('contactGroupDiv')
            countErr++
            return
        }else {
            const getAdmin = groupContactsList.filter(data => data.contactType === '2' || data.contactType === '7' || data.contactType === 2 || data.contactType === 7)
            // console.log('groupContactsList: ', groupContactsList);
            if (getAdmin.length === 0) {
                toastr.warning('Debes tener por lo menos un administrador', '¡Ooops!')
                handleScroll('contactGroupDiv')
                countErr++
                return
            }
        }

        let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
        let emailArr = []
        let nombresArr = []

        groupContactsList.forEach((data, index) => {
            if (index + 1 < groupContactsList.length) {
                if (data.contactType === '') {
                    toastr.warning('El tipo de contacto son obligatorios.', '¡Ooops!')
                    $(`#group-contactType${index}`).addClass('border-danger')
                    handleScroll(`group-contactType${index}`)
                    countErr++
                    return
                }
                if (data.contactName === '') {
                    toastr.warning('El nombre de contacto son obligatorios.', '¡Ooops!')
                    $(`#group-contactName${index}`).addClass('border-danger')
                    handleScroll(`group-contactName${index}`)
                    countErr++
                    return
                }
                if (data.contactlastname === '') {
                    toastr.warning('El apellido de contacto son obligatorios.', '¡Ooops!')
                    $(`#group-contactlastname${index}`).addClass('border-danger')
                    handleScroll(`group-contactlastname${index}`)
                    countErr++
                    return
                }
                if (data.contactEmail === '') {
                    toastr.warning('El correo electrónico de contacto son obligatorios.', '¡Ooops!')
                    $(`#group-contactEmail${index}`).addClass('border-danger')
                    handleScroll(`group-contactEmail${index}`)
                    countErr++
                    return
                } else {
                    if (!regex.test(data.contactEmail)) {
                        toastr.warning('El formato del correo electrónico de contacto no es correcto.', '¡Ooops!')
                        $(`#group-contactEmail${index}`).addClass('border-danger')
                        handleScroll(`group-contactEmail${index}`)
                        countErr++
                        return
                    }
                }
                if (data.contactNumber !== '' && data.contactNumber.length < 10) {
                    toastr.warning('El Teléfono debe contener 10 dígitos.', '¡Ooops!')
                    $(`#group-contactNumber${index}`).addClass('border-danger')
                    handleScroll(`group-contactNumber${index}`)
                    countErr++
                    return
                }

                emailArr.push(data.contactEmail)
                nombresArr.push(`${data.contactName} ${data.contactlastname}`)
            }  
        })
        let duplicados = []
        let nombresDuplicados = []

        const tempArray = [...emailArr].sort()
        const tempArray2 = [...nombresArr].sort()


        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i + 1] === tempArray[i]) {
                duplicados.push(tempArray[i])
            }
        }

        for (let i = 0; i < tempArray2.length; i++) {
            if (tempArray2[i + 1] === tempArray2[i]) {
                nombresDuplicados.push(tempArray2[i])
            }
        }

        emailArr.forEach((data, index) => {
            if (data === duplicados[0]) {
                toastr.warning('Existen correos duplicados. Cada correo debe ser distinto.', '¡Ooops!')
                $(`#group-contactEmail${index}`).addClass('border-danger')
                countErr++
                return
            }
        })

        nombresArr.forEach((data, index) => {
            if (data === nombresDuplicados[0]) {
                toastr.warning('Existen nombre y apellido duplicados. Cada nombre debe ser distinto.', '¡Ooops!')
                $(`#group-contactName${index}`).addClass('border-danger')
                $(`#group-contactlastname${index}`).addClass('border-danger')
                countErr++
                return
            }
        })
        if(generalData.listOrganization.length === 0 && enterprisesListAdd.length === 0){
            toastr.warning('Debe agregar por lo menos una Organización', '¡Ooops!');
            handleScroll('contactGroupOrg')
            countErr++
            return
        }

        groupBenefitPlans.forEach((data, index) => {
            if (index + 1 < groupBenefitPlans.length) {
                if (data.idPlan !== '') {
                    if( data.numPolicy === '' ||  parseInt(data.numPolicy) === 0 ){
                        toastr.warning('Falta completar el numero de licencias en el plan seleccionado', '¡Ooops!');
                        $(`#group-numPolicy${index}`).addClass('border-danger')
                        handleScroll(`group-numPolicy${index}`)
                        countErr++
                        return
                    }
                    if(data.onDate === '' || data.onDate === undefined){
                        toastr.warning('Falta completar la fecha de inicio para el plan seleccionado', '¡Ooops!');
                        $(`#group-onDate${index}`).addClass('border-danger')
                        handleScroll(`group-onDate${index}`)
                        countErr++
                        return
                    }
                }
            }
        })
        
        if(countErr === 0){
            // if(generalData.groupEnterpriseGroupId === 0){
                // Get contacts
                let getContacts = []
                groupContactsList.forEach(data => {
                    if (data.contactType !== '') {
                        getContacts.push({
                            idWelcomeEmail: data.idWelcomeEmail !== '' && data.idWelcomeEmail > 0 ? data.idWelcomeEmail : 0,
                            idRol: data.contactType,
                            name: data.contactName,
                            lastName: data.contactlastname,
                            email: data.contactEmail,
                            phone: data.contactNumber,
                            sendDate: handleDateToyyyymmdd(new Date()),
                        })
                    }
                })

                // get plans
                let getGroupPlans = []
                if(groupBenefitPlans.length > 0){
                    groupBenefitPlans.forEach(data => {
                        if (data.idPlan !== '') {
                            // if(generalData.groupEnterpriseGroupId > 0 ){
                            if(data.idPlanEnterprise !== '' ){
                                getGroupPlans.push({
                                    idPlanEnterprise: parseInt(data.idPlanEnterprise),
                                    idPlan: parseInt(data.idPlan),
                                    numPolicy: parseInt(data.numPolicy),
                                    onDate: handleDateToyyyymmdd(data.onDate),
                                })
                            }else{
                                getGroupPlans.push({
                                    idPlan: parseInt(data.idPlan),
                                    numPolicy: parseInt(data.numPolicy),
                                    onDate: handleDateToyyyymmdd(data.onDate),
                                })
                            }
                        }
                    })
                }
                
                let getNewEnterprises = []
                if(generalData.groupEnterpriseGroupId > 0){
                    // console.log('es edicion de grupo: ',generalData.listOrganization);
                    generalData.listOrganization.forEach(data => {
                        let productEnterpriseArr = []
                        // let productEnterpriseArr2 = []

                        const getSelectedProduct = services.filter(data => data.checked)

                        getSelectedProduct.forEach(prod => {
                            productEnterpriseArr.push({
                                idProduct: prod.idProduct,
                                idCategory: prod.idCategory,
                                idSubCategory: prod.idSubCategory,
                                onDate: handleDateToyyyymmdd(prod.onDate),
                            })
                        })
                        // console.log('welcome ', data.welcomeEmails);
                        let getContactsEnterprise = []
                        data.welcomeEmails.forEach(data => {
                            if (data.idRol !== '') {
                                getContactsEnterprise.push({
                                    idWelcomeEmail: data.idWelcomeEmail !== '' && data.idWelcomeEmail > 0 ? data.idWelcomeEmail : 0,
                                    idRol: data.idRol,
                                    name: data.name,
                                    lastName: data.lastName,
                                    email: data.email,
                                    phone: data.phone,
                                    sendDate: handleDateToyyyymmdd(new Date()),
                                })
                            }
                        })
                        getNewEnterprises.push({
                            // idGroup: data.idGroup,
                            idEnterprise: data.idEnterprise,
                            commercialName: data.commercialName,
                            numCollabs: data.collaborators,
                            productEnterprise: productEnterpriseArr,
                            benefitEnterprise: data.benefitEnterprise,
                            // welcomeEmails:data.welcomeEmails,
                            welcomeEmails:getContactsEnterprise
                            // idUser:data.idUser,
                        })

                    })
                }else{
                    // console.log('es nuevo grupo');
                    enterprisesListAdd.forEach(data => {
                        getNewEnterprises.push({
                            // idGroup: data.idGroup,
                            idEnterprise: data.idEnterprise,
                            commercialName: data.commercialName,
                            numCollabs: data.collaborators,
                            productEnterprise: data.productEnterprise,
                            benefitEnterprise: data.benefitEnterprise,
                            welcomeEmails:data.welcomeEmails,
                            // idUser:data.idUser,
                        })
                    })
                    
                }

                let objGroup = {
                    groupEnterpriseGroupId: generalData.groupEnterpriseGroupId,
                    groupCommercialName: generalData.groupCommercialName,
                    administrationType: parseInt(generalData.adminType),
                    listOrganization: getNewEnterprises,
                    welcomeEmails: getContacts,
                    groupPlans: getGroupPlans,
                    idUser: userId,
                }
                console.log('objGroup: ',objGroup, ' services: ',services);
                saveOrgGroupInfo(objGroup).then(response => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                objGroup.groupEnterpriseGroupId > 0 ? (
                                    toastr.success(`Se editó correctamente el grupo.`, '¡Éxito!')
                                ):(
                                    toastr.success(`Se creó correctamente el grupo con sus organizaciónes`, '¡Éxito!')
                                )
                                getGroups()
                                setTimeout(() => {
                                    window.location = '/groups-enterprises'
                                }, 2000 );
                                break
                            case 99:
                                toastr.error('Ocurrió un error, verifique su conexión a internet.')
                                break
                            case 1000:
                                toastr.error('Verifique que el nombre del grupo, tipo de administración estén completos o que el grupo tenga organizaciones.')
                                break
                            case 1001:
                                toastr.error('En el listado de organizaciones una organización tiene información incompleta.')
                                break
                            case 1002:
                                toastr.error('Una organización del grupo no tiene contactos de envio de correo de bienvenida.')
                                break
                            case 1003:
                                toastr.error('Una organización del grupo no tiene contacto de envio de correo de bienvenida con rol de Administrador.')
                                break
                            case 1004:
                                toastr.error('El tipo de administración seleccionada no esta disponible por el momento')
                                break
                            case 1005:
                                toastr.error('No se encontro el grupo que quiere modificar.')
                                break
                            default:
                                toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                                break
                        }
                        
                    }else{
                        toastr.error(`Ocurrió un error desconocido, verifique su conexión a internet.`)
                    }
                })

            // }else{
            //     toastr.warning(`Se esta trabajando en la edicion`, '¡Ooops!')
            // }
        }
    }
            

    const handleCleanForm = () => {
        setHideHeader(false)
        setShowForm(false)
        setEnterpriseObj({})
    }

    const handleReloadData = () => {
    }
    
  return (
    <div className='col-12 mx-auto my-5'>
        {
            showForm ? (
                <EnterpriseForm
                    userID={userId}
                    enterpriseObj={enterpriseObj}
                    handleCleanForm={handleCleanForm}
                    handleReloadData={handleReloadData}
                    entRegisterDate={entRegisterDate}

                    setEnterprisesListAdd={setEnterprisesListAdd}
                    enterprisesListAdd={enterprisesListAdd}

                    groupInfo={groupInfo}
                    groupContactsListCop={groupContactsListCop}
                    groupServices={groupServices}
                    indexTemporalEnterprise={indexTemporalEnterprise}
                />
            ) : (
                <>
                    {!hideHeader &&  <GroupHeader generalData={generalData} groupNameLabel={groupNameLabel}/>}
                    <div className='card rounded-10px border-0'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 mx-auto border-bottom'>
                                        <div className='row'>
                                            <div className='col-11 mx-auto border-bottom'>
                                                <div className='row'>
                                                    <div className='col-3 d-flex justify-content-center px-3 pt-3 pb-0 border-active-guay'>
                                                        <span className='fw-bold fs-1em font-family-quicksand'>Datos generales</span>
                                                    </div>
                                                    <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                                        <span className='fw-bold fs-1em font-family-quicksand'>Seguimiento</span>
                                                    </div>
                                                    <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                                        <span className='fw-semibold text-ad-grey-guay fs-1em font-family-quicksand'>Facturación</span>
                                                    </div>
                                                    <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                                        <span className='fw-semibold text-ad-grey-guay fs-1em font-family-quicksand'>Contactos</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-11 mx-auto'>
                                        <GeneralDataGroupForm 
                                            generalData={generalData}
                                            setGeneralData={setGeneralData}
                                            groupContactsList={groupContactsList}
                                            groupProducts={groupProducts}
                                            setServices={setServices}
                                            services={services}
                                            handleOnChangeContact={handleOnChangeContact}
                                            handleDeleteContact={handleDeleteContact}
                                            handlemanualSendWelcomeEmail={handlemanualSendWelcomeEmail}
                                            handleSubmitForm={handleSubmitForm}
                                            setHideHeader={setHideHeader}
                                            setShowForm={setShowForm}
                                            setEnterpriseObj={setEnterpriseObj}
                                            setEntRegisterDate={setEntRegisterDate}
                                            totalContactAdmin={totalContactAdmin}
                                            enterprisesListAdd={enterprisesListAdd}
                                            setGroupInfo={setGroupInfo}
                                            groupName={groupNameLabel}
                                            setIndexTemporalEnterprise={setIndexTemporalEnterprise}
                                            handleDeleteCard={handleDeleteCard}
                                            planList={planList}
                                            groupBenefitPlans={groupBenefitPlans}
                                            handleOnChangeBenefitPlan={handleOnChangeBenefitPlan}
                                            handleDeleteBenefitPlan={handleDeleteBenefitPlan}
                                        />
                                    </div>

                                </div>
                            </div>
                    </div>
                </>
            )
}
    </div>
  )
}
// export default GroupForm
const mapStateToProps = ({ groupSpecific,auth}) => {
    return {
        groupSpecific,
        auth
    }
}

export default connect(mapStateToProps,{getGroups})(GroupForm)