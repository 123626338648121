import React, {
    useState,
    useEffect,
} from 'react'
// TODO: Components
import EnterpriseHeader from './EnterpriseHeader'
import GeneralDataForm from './GeneralDataForm'
// TODO: API calls
import { deleteEnterpriseWelcomeEmail } from '../../../models/organization_model'
// TODO: Generic func
import {
    handleDateTextFormat,
    handleScroll,
} from '../../../helpers/genericFunc'
// TODO: Tools
import $ from 'jquery'
import * as toastr from 'toastr'
import Swal from 'sweetalert2'
import { getAllPlans } from '../../../models/benefits_model'

const EnterpriseForm = ({
    userID,
    enterpriseObj,
    handleCleanForm,
    handleReloadData,
    entRegisterDate,
    setEnterprisesListAdd,
    enterprisesListAdd,
    groupInfo,
    groupContactsListCop,
    groupServices = [],
    getOrganizations,
    indexTemporalEnterprise = '',
    setContactDeleted,
}) => {
    //console.log('groupInfo: ',groupInfo,' groupContactsListCop: ',groupContactsListCop, ' indexTemporalEnterprise: ',indexTemporalEnterprise,' enterprisesListAdd: ',enterprisesListAdd, ' groupServices: ',groupServices);
    // string
    const [todayDate, setTodayDate] = useState('')

    // number
    const [totalContactAdmin, setTotalContactAdmin] = useState(0)
    const [totalContactAdminGroup, setTotalContactAdminGroup] = useState(0)

    // object
    const [generalData, setGeneralData] = useState({
        idEnterprise: 0,
        commercialName: '',
        registerDate: '',
        collaborators: '',
        activeCollaborators: '',
        productEnterprise: [],
        benefitEnterprise: [],
        logo: '',
    })

    // array
    const [contactList, setContactList] = useState([
        {
            idWelcomeEmail: '',
            contactType: '',
            contactName: '',
            contactlastname: '',
            contactEmail: '',
            contactNumber: '',
            contactRegisterDate: '',
            status: '',
        },
    ])
    const [warningMessages, setWarningMessages] = useState([])
    const [enterpriseBenefitPlans, setEnterpriseBenefitPlans] = useState([
        {
             idPlanEnterprise: '',
             idPlan: '',
             numPolicy: '',
             onDate: '',
         }
     ])
     const [planList, setPlanList] = useState([])

    // boolean
    const [groupContactAdmin, setGroupContactAdmin] = useState(false)
    const [unregisterContactGroup, setUnregisterContactGroup] = useState(false)
    

    useEffect(() => {
        //console.log('groupContactsListCop2: ', groupContactsListCop);
        //console.log('enterpriseObj: ',enterpriseObj)
        if (Object.keys(enterpriseObj).length > 0) {
            setGeneralData({
                idEnterprise: enterpriseObj.idEnterprise,
                commercialName: enterpriseObj.commercialName,
                registerDate: enterpriseObj.registerDate,
                collaborators: enterpriseObj.collaborators,
                activeCollaborators: enterpriseObj.activeCollaborators,
                productEnterprise: enterpriseObj.productEnterprise,
                benefitEnterprise: enterpriseObj.benefitEnterprise ? enterpriseObj.benefitEnterprise : [],
                logo: enterpriseObj.logo,
            })
            let contactArr = []
            let totalAdmin = 0
            enterpriseObj.welcomeEmails.forEach(data => {
                contactArr.push({
                    idWelcomeEmail: data.idWelcomeEmail,
                    contactType: data.idRol,
                    contactName: data.name,
                    contactlastname: data.lastName,
                    contactEmail: data.email,
                    contactNumber: data.phone,
                    contactRegisterDate: data.sendDate,
                    status: data.status === undefined ? '' : data.status,
                })
                if (data.idRol === 2) {
                    totalAdmin++
                }
            })
            contactArr.push({
                idWelcomeEmail: '',
                contactType: '',
                contactName: '',
                contactlastname: '',
                contactEmail: '',
                contactNumber: '',
                contactRegisterDate: '',
                status: '',
            })
            let newContactList = []
            let totalAdminGroup = 0
            contactArr.forEach(data => {
                let duplicate = false
                groupContactsListCop.forEach(subdata => {
                    if (data.contactType === subdata.contactType &&
                        data.contactName === subdata.contactName &&
                        data.contactlastname === subdata.contactlastname &&
                        data.contactEmail === subdata.contactEmail) {
                        duplicate = true
                    }
                    if (parseInt(subdata.contactType) === 2) {
                        totalAdminGroup++
                    }
                })
                if (!duplicate) {
                    newContactList.push(data)
                }
            })
            setContactList(newContactList)
            setTotalContactAdmin(totalAdmin)
            setTotalContactAdminGroup(totalAdminGroup)
            if(enterpriseObj.planEnterprise && enterpriseObj.planEnterprise.length > 0){
                let plansArr = []
                enterpriseObj.planEnterprise.forEach(data => {
                    plansArr.push({
                        idPlanEnterprise:data.idPlanEnterprise,
                        idPlan: data.idPlan,
                        numPolicy: data.numPolicy,
                        onDate: data.onDate !== '' ? data.onDate : ''
                    })
                })
                plansArr.push({
                    idPlanEnterprise: '',
                    idPlan: '',
                    numPolicy: '',
                    onDate: '',
                })
                setEnterpriseBenefitPlans(plansArr)
            }
        } else {
            setTotalContactAdminGroup(groupContactsListCop.length)
        }
        handleScroll('top-page')
    }, [enterpriseObj, groupContactsListCop])

    useEffect(() => {
        if (groupInfo.idgroup > 0 && groupContactsListCop.length > 0) {
            let contactGPendientes = groupContactsListCop.filter(data => data.idWelcomeEmail === '' || data.idWelcomeEmail === 0)
            if (contactGPendientes.length > 0) {
                toastr.info('Tiene contactos de grupo pendientes de guardar', '¡Ooops!')
                setUnregisterContactGroup(true)
            } else {
                setUnregisterContactGroup(false)
            }
        } else {
            setUnregisterContactGroup(false)
        }
    }, [groupContactsListCop, groupInfo])


    useEffect(() => {
        setTodayDate(handleDateTextFormat(Object.keys(enterpriseObj).length === 0 ? (new Date()) : (new Date(entRegisterDate))))
    }, [enterpriseObj, setTodayDate, entRegisterDate])

    useEffect(() => {
        if (groupInfo.idgroup === ''){
            getAllPlans().then(response => {
                if (response.validation) {
                    setPlanList(response.data.plans.filter(data => data.active === 1))
                }
            })
        }
    }, [groupInfo.idgroup])

    const handleOnChangeBenefitPlan = (e, currentIndex, isDate) => {
        $(`input`).removeClass('border-danger')
        let arr = enterpriseBenefitPlans
        let newRegister = true
        let obj = {
            ...arr[currentIndex],
        }
        let objKeys = Object.values(obj)

        objKeys.forEach(element => {
            if (element !== '' ) {
                newRegister = false
            }
        })
        if (currentIndex === 0 && enterpriseBenefitPlans.length === 1) {
            newRegister = true
        }
        if (currentIndex + 1 < enterpriseBenefitPlans.length) {
            newRegister = false
        }
        // console.log(obj)
        let newArr = []
        arr.forEach((element, index) => {
            if (currentIndex === index) {
                if (newRegister) {
                    if (isDate) {
                        newArr.push(
                            {
                                ...obj,
                                onDate: e,
                            },
                            {
                                ...obj,
                            },
                        )
                    } else {
                        if(e.target.name === 'idPlan' && enterpriseBenefitPlans.length > 0){
                            let valueExist = enterpriseBenefitPlans.filter(data => parseInt(data.idPlan) === parseInt(e.target.value))
                            if(valueExist.length > 0){
                                toastr.warning('No puede repetir planes', '¡Ooops!')
                                newArr.push(
                                    {
                                        ...obj,
                                    },
                                )
                            }else{
                                newArr.push(
                                    {
                                        ...obj,
                                        [e.target.name]: e.target.value,
                                    },
                                    {
                                        ...obj,
                                    },
                                )
                            }
                        }else{
                            newArr.push(
                                {
                                    ...obj,
                                    [e.target.name]: e.target.value,
                                },
                                {
                                    ...obj,
                                },
                            )
                        }
                        
                    }
                } else {
                    if (isDate) {
                        newArr.push({
                            ...obj,
                            onDate: e,
                        })
                    } else {
                        if(e.target.name === 'idPlan' && enterpriseBenefitPlans.length > 0){
                            let valueExist = enterpriseBenefitPlans.filter(data => parseInt(data.idPlan) === parseInt(e.target.value))
                            if(valueExist.length > 0){
                                toastr.warning('No puede repetir planes', '¡Ooops!')
                                newArr.push(
                                    {
                                        ...obj,
                                    },
                                )
                            }else{
                                newArr.push(
                                    {
                                        ...obj,
                                        [e.target.name]: e.target.value,
                                    },
                                )
                            }
                        }else{
                            newArr.push({
                                ...obj,
                                [e.target.name]: e.target.value,
                            })
                        }
                    }
                }
            } else {
                newArr.push(element)
            }
        })
        setEnterpriseBenefitPlans(newArr)

    }
    
    const handleDeleteBenefitPlan = (currentIndex,idPlanEnterprise) => {
        let arr = enterpriseBenefitPlans
        let newArr = []
        if(idPlanEnterprise > 0 && idPlanEnterprise !== ''){
            toastr.warning('Este plan no puede ser eliminado debido a que ya fue contratado.', '¡Ooops!')
            return
        }else{
            newArr = arr.filter((element,index) => currentIndex !== index);
            // console.log('el nuevo array sera: ',newArr);
            setEnterpriseBenefitPlans(newArr)
        }
    }

    const handleOnChangeContact = (e, currentIndex, isDate, status) => {
        $(`input`).removeClass('border-danger')
        //console.log(e.target.value)
        //console.log(e.target.name)
        //console.log(currentIndex)
        //console.log(contactList)
        let arr = contactList
        let newRegister = true
        let obj = {
            ...arr[currentIndex],
        }
        let objKeys = Object.values(obj)
        //console.log(objKeys)
        objKeys.forEach(element => {
            if (element !== '') {
                newRegister = false
            }
        })
        //console.log(obj)
        if (currentIndex === 0 && contactList.length === 1) {
            newRegister = true
        }
        if (currentIndex + 1 < contactList.length) {
            newRegister = false
        }
        let newArr = []
        let totalAdmin = 0
        arr.forEach((element, index) => {
            if (parseInt(element.contactType) === 2 && currentIndex !== index) {
                totalAdmin++
            }
            if (currentIndex === index) {
                if (newRegister) {
                    if (isDate) {
                        newArr.push(
                            {
                                ...obj,
                                contactRegisterDate: e,
                            },
                            {
                                ...obj,
                            },
                        )
                    } else {
                        newArr.push(
                            {
                                ...obj,
                                [e.target.name]: e.target.value,
                            },
                            {
                                ...obj,
                            },
                        )
                    }
                } else {
                    if (isDate) {
                        newArr.push({
                            ...obj,
                            contactRegisterDate: e,
                        })
                    } else {
                        newArr.push({
                            ...obj,
                            [e.target.name]: e.target.value,
                        })
                    }
                }
            } else {
                newArr.push(element)
            }
        })
        if (e.target.name === 'contactType' && e.target.value === '2') {
            totalAdmin++
        }
        //console.log(totalAdmin)
        //setTotalContactAdmin(totalAdmin)
        // Validation
        if (!groupContactAdmin) {
            if (generalData.idEnterprise > 0 && totalAdmin === 0 && e.target.name === 'contactType' && e.target.value !== '2') {
                toastr.warning('Debe tener por lo menos un administrador.', '¡Ooops!')
                return
            }
        }
        //console.log(newArr)
        //setContactList(newArr)
        //console.log(status)
        if (status === 2) {
            const getMessage = warningMessages.filter(data => data === currentIndex)
            let messages = warningMessages
            if (getMessage.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Este correo ya tiene la cuenta activa, al registrar un nuevo correo se tendría que volver a activar. ¿Estás seguro que quieres editar el correo?',
                    showCancelButton: true,
                    confirmButtonText: 'Si, editar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setTotalContactAdmin(totalAdmin)
                        setContactList(newArr)
                        messages.push(currentIndex)
                        setWarningMessages(messages)
                    }
                })
            } else {
                setTotalContactAdmin(totalAdmin)
                setContactList(newArr)
            }
        } else {
            setTotalContactAdmin(totalAdmin)
            setContactList(newArr)
        }
    }

    const handleDeleteContact = (currentIndex, idEnterprise, idWelcomeEmail) => {
        //console.log(currentIndex, idEnterprise, idWelcomeEmail)
        const removeFromList = () => {
            let arr = contactList
            //console.log(arr)
            let newArr = []
            let totalAdmin = 0
            arr.forEach((element, index) => {
                if (currentIndex !== index) {
                    if (parseInt(element.contactType) === 2) {
                        totalAdmin++
                    }
                    newArr.push(element)
                }
            })
            //console.log(totalAdmin)
            setTotalContactAdmin(totalAdmin)
            //console.log(newArr)
            setContactList(newArr)
            toastr.success('Se eliminó correctamente el contacto.', '¡Éxito!')
        }
        if (idWelcomeEmail === '' || idWelcomeEmail === undefined) {
            removeFromList()
        } else {
            deleteEnterpriseWelcomeEmail(idEnterprise, idWelcomeEmail).then(response => {
                if (response.code === 1) {
                    setContactDeleted(true)
                    removeFromList()
                }else{
                    toastr.error('No se eliminó correctamente el contacto. Intente nuevamente.', '¡Ooops!')
                }
            })
        }
    }

    return (
        <div className='col-12 mx-auto mb-3'>

            <EnterpriseHeader
                todayDate={todayDate}
                logo={generalData.logo}
                groupNameLabel={groupInfo.groupLabel}
                enterpriseName={generalData.commercialName !== '' ? generalData.commercialName : 'Organización'}
            />

            <div className='card rounded-10px border-0'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 mx-auto border-bottom'>
                            <div className='row'>
                                <div className='col-11 mx-auto border-bottom'>
                                    <div className='row'>
                                        <div className='col-3 d-flex justify-content-center px-3 pt-3 pb-0 border-active-guay'>
                                            <span className='fw-bold fs-1em font-family-quicksand'>Datos generales</span>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                            <span className='fw-bold fs-1em font-family-quicksand'>Seguimiento</span>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                            <span className='fw-semibold text-ad-grey-guay fs-1em font-family-quicksand'>Facturación</span>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center pt-3 pb-0'>
                                            <span className='fw-semibold text-ad-grey-guay fs-1em font-family-quicksand'>Contactos</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-11 mx-auto'>
                            <GeneralDataForm
                                generalData={generalData}
                                setGeneralData={setGeneralData}
                                contactList={contactList}
                                handleOnChangeContact={handleOnChangeContact}
                                handleDeleteContact={handleDeleteContact}
                                handleCleanForm={handleCleanForm}
                                idUser={userID}
                                totalContactAdmin={totalContactAdmin}
                                handleReloadData={handleReloadData}
                                unregisterContactGroup={unregisterContactGroup}
                                // Group func
                                groupContactAdmin={groupContactAdmin}
                                setGroupContactAdmin={setGroupContactAdmin}
                                setEnterprisesListAdd={setEnterprisesListAdd}
                                enterprisesListAdd={enterprisesListAdd}
                                groupContactsList={groupContactsListCop}
                                groupInfo={groupInfo}
                                indexTemporalEnterprise={indexTemporalEnterprise}
                                totalContactAdminGroup={totalContactAdminGroup}
                                groupServices={groupServices}
                                // benefits
                                planList={planList}
                                enterpriseBenefitPlans={enterpriseBenefitPlans}
                                handleOnChangeBenefitPlan={handleOnChangeBenefitPlan}
                                handleDeleteBenefitPlan={handleDeleteBenefitPlan}
                            />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default EnterpriseForm