import React, { Fragment, useEffect, useState } from 'react'
import * as toastr from 'toastr';
import $ from 'jquery';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import GroupDetail  from './GroupDetail';
import EnterpriseForm from '../enterprise/EnterpriseForm';
import Loader from '../../genericComponents/Loader';
// TODO: redux
import { connect } from 'react-redux'
import { doSetGroupQuizAction,doSetGroupInitialState } from '../../../redux/groups'
import { getGroups } from '../../../redux/getGroupList';
import GroupFilters from './GroupFilters';
import { deleteEnterprise } from '../../../models/organization_model';

const Groups = ({setHideHeader,auth,doSetGroupQuizAction,doSetGroupInitialState,groupList,getGroups}) => {
    const userId = auth.id
    // object
    const [enterpriseObj, setEnterpriseObj] = useState({})
    const [groupInfo, setGroupInfo] = useState({
        idgroup:0,
        groupLabel:'',
        isFromGroupForm: false,
    })
    // arr
    const [groupEnterprisesList, setGroupEnterprisesList] = useState([])
    const [groupEnterprisesBack, setGroupEnterprisesBack] = useState([])
    const [enterprisesListAdd, setEnterprisesListAdd] = useState([])
    const [groupContactsList, setGroupContactsList] = useState([])
    const [groupContactsListCop, setGroupContactsListCop] = useState([])
    const [services, setServices] = useState([])
    const [groupServices, setGroupServices] = useState([])
    // bool
    const [showMessage, setShowMessage] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const [showForm, setShowForm] = useState(false)
    // String
    const [entRegisterDate, setEntRegisterDate] = useState(new Date())
    // int
    const [selectedFilter, setSelectedFilter] = useState(10)

    useEffect(() => {
        doSetGroupInitialState()
    }, [doSetGroupInitialState])

    useEffect(() => {
        getGroups()
    }, [])
    
    useEffect(() => {
        if (groupList.fetching) {
            if(groupList.groupList.length >0){
                setGroupEnterprisesList(groupList.groupList)
                setGroupEnterprisesBack(groupList.groupList)
                setShowLoader(false)
                setShowMessage(false)
            }
        } else {
            setShowMessage(true)
            setShowLoader(false)
            toastr.info('Hubo un error al cargar los grupos.', '¡Ooops!')
        }
    }, [groupList])
    

    useEffect(() => {
    if(groupContactsList.length > 0){
        // const arr = groupContactsList.filter(contactData => contactData.contactType !== '')
        let arr = []
        groupContactsList.forEach(data => {
            if (data.contactType !== '') {
                arr.push({
                    idWelcomeEmail: data.idWelcomeEmail,
                    contactType: data.contactType,
                    contactName: data.contactName,
                    contactlastname: data.contactlastname,
                    contactEmail: data.contactEmail,
                    contactNumber: data.contactNumber,
                    contactRegisterDate: data.contactSendDate,
                    // contactRegisterDate: handleDateToyyyymmdd(new Date()),
                })
            }
        })
        setGroupContactsListCop(arr)
    }else{
        setGroupContactsListCop([])
    }

}, [groupContactsList])

useEffect(() => {
    if(services.length > 0){
        setGroupServices(services)
    }else{
        setGroupServices([])
    }
}, [services])

const handleClickEdit = (group) => {
    doSetGroupQuizAction(group)
}

const handleDeleteCard = (idEnterprise, enterpriseName, activeCollaborators) => {
    // console.log('eliminar empresa: ',idEnterprise, ' nombre: ',enterpriseName);
    Swal.fire({
        icon: 'warning',
        title: `¿Estás seguro de eliminar esta organización: ${enterpriseName}?`,
        //text: 'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
        html:
            `${activeCollaborators > 0 ? ('<span class="text-danger fs-8">* Esta organización ya cuenta con colaboradores activos.</span></br>') : ''}` +
            'Si eliminas esta organización no se podrá recuperar ¿Estás seguro?',
        showCancelButton: true,
        cancelButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar',
        confirmButtonColor: '#af2f72',
        cancelButtonColor: '#15253c',
        customClass: {
            actions: 'my-actions-alert',
            cancelButton: 'order-1 right-gap-alert',
            confirmButton: 'order-2-alert',
            denyButton: 'order-3-alert',
            icon: 'icon-size-modal',
            title: 'fs-5',
        },
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
            if(idEnterprise > 0){
                deleteEnterprise(idEnterprise).then(response => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                toastr.info('Se eliminó correctamente la organización.', '¡Éxito!')
                                    getGroups()
                                break
                            case 99:
                                toastr.error('Ocurrió un error, verifique su conexión a internet.')
                                break
                            case 1000:
                                toastr.error('La Organización que desea eliminar no se encontró o ya fue desactivada anteriormente.')
                                break
                            case 1001:
                                toastr.error('El grupo organizacional no se puede quedar sin organizaciones. Por lo tanto la organización seleccionada no puede ser eliminada.')
                                break
                            default:
                                toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                                break
                        }
                        
                    }else{
                        toastr.error(`Ocurrió un error desconocido, verifique su conexión a internet.`)
                    }
                })
            }
            
        }
    })
}

// Orden de importancia de los colores que se mostrarán en los grupos es: Magenta, azul y gris
const verifyGroupStatus = (flagActivationPending,flagRenewal,flagInactive,flagActive) => {
    if(flagRenewal){
        return 'text-pink opacity-20'
    }else if (flagActivationPending) {
        return 'text-sec-light-blue-guay'
    }else if(flagInactive){
        return 'text-sec-white-gray-guay'
    }else if(flagActive){
        return 'text-white opacity-20'
    }else{
        return ''
    }

}

const getActiveEnterprises = (listOrganization) => {
    if (listOrganization.length > 0) {
        // 1 - blanco(activa) 2 - 
        //color:  azul(act. pendiente) = 99, magenta(renovación) = 2, gris(inactivo) = 0, blanco(activa) = 1
        const activeEntGroup = listOrganization.filter( data => data.color === 1 || data.color === 2 || data.color === 99).length
        return activeEntGroup
    }else{
        return 0
    }

}

const handleClickDetailGroupEnt = (e,idGroup) => {
    e.preventDefault();
    if($(`#divDetalle-${idGroup}`).hasClass('d-none')){
        $(`#divDetalle-${idGroup}`).removeClass('d-none')
        $(`#divDetalle-${idGroup}`).addClass('detail-show')
        $(`#imgDetail-${idGroup}`).attr("src","../assets/img/icons/icono_agregar_menos_activo.png")

    }else{
        $(`#divDetalle-${idGroup}`).removeClass('detail-show')
        $(`#divDetalle-${idGroup}`).addClass('d-none')
        $(`#imgDetail-${idGroup}`).attr('src','../assets/img/icons/Icono_agregar_activo.png')
    }
}

const hideDetails = () => {
    if($('.detail-show').length > 0){
        $('.detail-show').each(function(){
            var idGroup = $(this).attr('id').replace("divDetalle-",""); 
            $(`#divDetalle-${idGroup}`).removeClass('detail-show')
            $(`#divDetalle-${idGroup}`).addClass('d-none')
            $(`#imgDetail-${idGroup}`).attr('src','../assets/img/icons/Icono_agregar_activo.png')
        });
    }
}

const handleFilters = filterID => {
        let filter;
        switch (filterID) {
            case 1:
                filter = groupEnterprisesBack.filter(group => group.flagActive === true )
                if (filter.length > 0) {
                    setGroupEnterprisesList(filter)
                    setShowMessage(false)
                }else{
                    setGroupEnterprisesList([])
                    setShowMessage(true)
                }
            break;
            case 2:
                filter = groupEnterprisesBack.filter(group => group.flagActivationPending === true )
                if (filter.length > 0) {
                    setGroupEnterprisesList(filter)
                    setShowMessage(false)
                }else{
                    setGroupEnterprisesList([])
                    setShowMessage(true)
                }
            break;
            case 3:
                filter = groupEnterprisesBack.filter(group => group.flagRenewal === true )
                if (filter.length > 0) {
                    setGroupEnterprisesList(filter)
                    setShowMessage(false)
                }else{
                    setGroupEnterprisesList([])
                    setShowMessage(true)
                }
            break;
            case 4:
                filter = groupEnterprisesBack.filter(group => group.flagInactive === true )
                if (filter.length > 0) {
                    setGroupEnterprisesList(filter)
                    setShowMessage(false)
                }else{
                    setGroupEnterprisesList([])
                    setShowMessage(true)
                }
            break;
            case 10:
                if(groupEnterprisesBack.length > 0){
                    setGroupEnterprisesList(groupEnterprisesBack)
                    setShowMessage(false)
                }else{
                    setGroupEnterprisesList([])
                    setShowMessage(true)
                }
            break;
            default:
                break;
        }

    }

    const sortArray = ({ target }) => {
        hideDetails()
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById("listGroupsEnterprises");
        li = ul.getElementsByTagName('li');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            if(a !== undefined){
                txtValue = a.textContent || a.innerText;
                if (txtValue.toLowerCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                    records = records = 1;
                } else {
                    li[i].style.display = "none";
                }
            }
            
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }

    const handleCleanForm = () => {
        setHideHeader(false)
        setShowForm(false)
        setEnterpriseObj({})
    }

    const handleReloadData = () => {
        setShowLoader(true)
    }
    
  return (
    <div className="col-12 mb-3">
        {showLoader ? (
            <Loader />
        ) : 
        (
            showForm ? (
                <EnterpriseForm
                    userID={userId}
                    enterpriseObj={enterpriseObj}
                    handleCleanForm={handleCleanForm}
                    handleReloadData={handleReloadData}
                    entRegisterDate={entRegisterDate}

                    setEnterprisesListAdd={setEnterprisesListAdd}
                    enterprisesListAdd={enterprisesListAdd}
                    groupInfo={groupInfo}
                    groupContactsListCop={groupContactsListCop}
                    groupServices={groupServices}

                />
            ) : (
                <div className='card border-0 shadow rounded-10px py-3 generic__show-main-container container-1'>
                    <div className='card-body'>
                        {/* <div className="col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2"> */}
                        <div className="col-12 mx-auto generic__show-container top container-2">
                            <GroupFilters 
                                sortArray={sortArray}
                                handleFilters={handleFilters}
                                selectedFilter={selectedFilter}
                                setSelectedFilter={setSelectedFilter}
                            />
                            <div className='row px-3'>
                                <div className='col-12 list-group generic__show-container container-1 d-flex'>
                                    <li 
                                        key='headerList'
                                        className="col-sm-12 col-lg-12 col-xxl-12 mx-auto py-3 presence__show-container container-1 list-group-item"
                                    >
                                        <Link
                                                to="/group-register"
                                                className='text-decoration-none'
                                            >
                                                <img src="../assets/img/icons/Icono_agregar_activo.png" className="mx-1 icon-size-20x20 pointer" alt="img-agregar-grupo"/>
                                                
                                                <span className='text-decoration-none font-family-quicksand fs-0875em fw-bold text-blue pointer'>  
                                                Nuevo Grupo
                                                </span>
                                        </Link>
                                    </li> 
                                </div>
                                <div className='col-12 list-group generic__show-container container-1' id="listGroupsEnterprises"> 
                                    {showMessage && <li className='col-sm-12 col-lg-12 col-xxl-12 mx-auto p-3 presence__show-container container-1 list-group-item text-center font-family-quicksand'>No hay resultados</li>}
                                    {
                                        groupEnterprisesList.length > 0 &&
                                        groupEnterprisesList.map(groupEnt => (
                                                <Fragment key={groupEnt.groupEnterpriseGroupId}>
                                                <li 
                                                    key={groupEnt.groupEnterpriseGroupId}
                                                    className="col-sm-12 col-lg-12 col-xxl-12 mx-auto p-3 presence__show-container container-1 list-group-item "
                                                >
                                                    <div className='col-sm-12 col-lg-12 col-xxl-12 mx-auto'>
                                                        <div className='row'>
                                                            <div className='col-sm-9 col-lg-9 col-xxl-9 justify-content-start'>
                                                                <i 
                                                                    className={`bi bi-circle-fill ${verifyGroupStatus(groupEnt.flagActivationPending,groupEnt.flagRenewal,groupEnt.flagInactive,groupEnt.flagActive)}`} 
                                                                    style={{marginLeft:'-23px', marginRight:'20px'}}>
                                                                </i>
                                                                <Link to={`/group-register`} className='text-decoration-none' onClick={()=>{handleClickEdit(groupEnt)}}>
                                                                    <span className='font-family-quicksand font-size-1em fw-bold text-blue pointer'>  
                                                                        {groupEnt.groupCommercialName}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            <div className='col-sm-2 col-lg-2 col-xxl-2 justify-content-end' style={{paddingLeft:'2em'}}>
                                                                <span className='fw-bold text-blue font-family-quicksand'>{getActiveEnterprises(groupEnt.groupOrganizations)}</span>
                                                                <span className='font-size-1em text-blue font-family-quicksand'>  
                                                                    /{groupEnt.groupOrganizations.length} activas
                                                                </span>
                                                            </div>
                                                            <div className='col-sm-1 col-lg-1 col-xxl-1 justify-content-end'>
                                                                <img 
                                                                    src="../assets/img/icons/Icono_agregar_activo.png" 
                                                                    id={`imgDetail-${groupEnt.groupEnterpriseGroupId}`}
                                                                    className="mx-3 icon-size-20x20 pointer imgDetails" alt="img-desplegar"
                                                                    onClick={(e) => handleClickDetailGroupEnt(e,groupEnt.groupEnterpriseGroupId)}
                                                                    />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li> 
                                                <div className='col-sm-12 col-lg-12 col-xxl-12 mx-auto border d-none details scrollmenuCards' id={`divDetalle-${groupEnt.groupEnterpriseGroupId}`} key={`divDetalle-${groupEnt.groupEnterpriseGroupId}`}>
                                                    <GroupDetail 
                                                        groupName={groupEnt.groupCommercialName}
                                                        idGroup={groupEnt.groupEnterpriseGroupId}
                                                        setHideHeader={setHideHeader} 
                                                        setShowForm={setShowForm}
                                                        setEnterpriseObj={setEnterpriseObj}
                                                        setGroupInfo={setGroupInfo}
                                                        setGroupContactsList={setGroupContactsList}
                                                        setServices={setServices}
                                                        services={services}
                                                        contactsList={groupEnt.groupWelcomeEmails}
                                                        listEnterprises={groupEnt.groupOrganizations}
                                                        setEntRegisterDate={setEntRegisterDate}
                                                        handleDeleteCard={handleDeleteCard}
                                                    />
                                                </div>
                                                </Fragment>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
        }   
    </div>
  )
}

// export default Groups;
const mapStateToProps = ({
    groupSpecific,
    groupList,
}) => {
    return {
        groupSpecific,
        groupList,
    }
}

export default connect(mapStateToProps, { doSetGroupQuizAction, doSetGroupInitialState,getGroups })(Groups)

