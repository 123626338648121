import React from 'react'
// TODO: Components
import NumberInput from '../../genericComponents/NumberInput'
// TODO: Generic func
import { handleDateToddmmyyyy } from '../../../helpers/genericFunc'

const EnterpriseContactForm = ({
    data,
    currentIndex,
    handleOnChangeFunction,
    handleDeleteFunction,
    totalRegister,
    idEnterprise,
    totalContactAdmin,
    onlyReadInputs,
    groupContactAdmin,
    totalContactAdminGroup,
    handleSendEmail,
}) => {
    const {
        idWelcomeEmail,
        contactType,
        contactName,
        contactlastname,
        contactEmail,
        contactNumber,
        contactRegisterDate,
        status,
    } = data

    return (
        <>
            <div className='col-11 mt-3'>
                <div className='row'>
                    <div className='col-lg-2 col-xl-2 col-xxl-2 px-1'>
                        <div className='form'>
                            <select
                                className="form__input fs-075em px-0"
                                id="contactType"
                                name="contactType"
                                value={contactType}
                                onChange={(e) => handleOnChangeFunction(e, currentIndex, false)}
                                disabled={onlyReadInputs}
                            >
                                <option value={''}> </option>
                                <option value={7}>Owner</option>
                                <option value={2}>Administrador</option>
                            </select>
                            <label className='form__label'>
                                Tipo contacto
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xl-2 col-xxl-2'>
                        <div className='form'>
                            {!onlyReadInputs && idEnterprise > 0 && (
                                (currentIndex + 1) < totalRegister && (
                                    idWelcomeEmail !== '' && (
                                        contactType === 2 && (
                                            <img
                                                src={
                                                    status === 1 || status === '' ? "../assets/img/icons/icono_warning_amarillo.png"
                                                        : status === 2 ? "../assets/img/icons/icono_aprobado_verde_activo.png"
                                                            : "../assets/img/icons/icono_error_activo.png"
                                                }
                                                className="mx-1 icon-size-16x15 icon-input"
                                                alt="img-date"
                                            />
                                        )
                                    )
                                )
                            )}
                            <input
                                type='text'
                                className={`form__input fs-075em ${onlyReadInputs ? 'px-0' : idEnterprise === 0 ? 'px-0' : idWelcomeEmail === '' ? 'px-0' : (currentIndex + 1) < totalRegister ? contactType === 2 ? 'ps-4' : 'px-0' : 'px-0'}`}
                                placeholder=' '
                                id={totalContactAdminGroup > 0 ? `contactName-group-${currentIndex}` : `contactName${currentIndex}`}
                                name="contactName"
                                value={contactName}
                                onChange={(e) => handleOnChangeFunction(e, currentIndex, false)}
                                readOnly={onlyReadInputs}
                            />
                            <label className='form__label'>
                                Nombre contacto
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xl-2 col-xxl-2'>
                        <div className='form'>
                            <input
                                type='text'
                                className='form__input fs-075em px-0'
                                placeholder=' '
                                id={totalContactAdminGroup === 0 ? `contactlastname-group-${currentIndex}` : `contactlastname${currentIndex}`}
                                name="contactlastname"
                                value={contactlastname}
                                onChange={(e) => handleOnChangeFunction(e, currentIndex, false)}
                                readOnly={onlyReadInputs}
                            />
                            <label className='form__label'>
                                Apellidos
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xl-2 col-xxl-2 px-0'>
                        <div className='form'>
                            <input
                                type='text'
                                className='form__input fs-075em px-0'
                                placeholder=' '
                                id={totalContactAdminGroup === 0 ? `contactEmail-group-${currentIndex}` : `contactEmail${currentIndex}`}
                                name="contactEmail"
                                value={contactEmail}
                                onChange={(e) => handleOnChangeFunction(e, currentIndex, false, status)}
                                readOnly={onlyReadInputs}
                            />
                            <label className='form__label'>
                                Correo electrónico
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xl-2 col-xxl-2'>
                        <NumberInput
                            label='Teléfono'
                            generalValue={{}}
                            value={contactNumber}
                            setValue={handleOnChangeFunction}
                            maxValue={10}
                            name='contactNumber'
                            id={`contactNumber${currentIndex}`}
                            index={currentIndex}
                            readOnly={onlyReadInputs}
                        />
                    </div>
                    <div className='col-lg-2 col-xl-2 col-xxl-2'>
                        {contactRegisterDate !== '' && (
                            <div className='form input-img'>
                                <input
                                    type='text'
                                    className='form__input fs-0875em border-0'
                                    placeholder=' '
                                    value={idEnterprise > 0 ? ((currentIndex + 1) < totalRegister ? contactRegisterDate === '' ? (handleDateToddmmyyyy(new Date())) : (handleDateToddmmyyyy(contactRegisterDate)) : '') : ('')}
                                    id="contactRegisterDate"
                                    name="contactRegisterDate"
                                    disabled={true}
                                />
                                <label className='form__label'>
                                    Fecha envio
                                </label>
                                {idEnterprise !== 0 && (currentIndex + 1) < totalRegister && (
                                    !onlyReadInputs && (
                                        contactType === 2 && (
                                            <img
                                                src={status === 2 || idWelcomeEmail === '' ? "../assets/img/icons/Icono_enviar_inactivo.png" : "../assets/img/icons/Icono_enviar.png"}
                                                className="mx-1 icon-size-16x15"
                                                alt="img-date"
                                                onClick={() => {
                                                    if (status === 2 || idWelcomeEmail === '') {
                                                        return
                                                    }
                                                    handleSendEmail(idWelcomeEmail)
                                                }}
                                            />
                                        )
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='col-1 d-flex justify-content-center flex-wrap align-content-center'>
                {/* {!onlyReadInputs && (
                    totalContactAdminGroup > 1 ? (
                        (currentIndex + 1) < totalRegister &&
                        <span className='pointer' onClick={() => handleDeleteFunction(currentIndex)}>
                            <img
                                src="../assets/img/icons/Icono_eliminar_activo.png"
                                className="mx-1 icon-size-16x15"
                                alt="img-trash"
                            />
                        </span>
                    ) : (
                        idEnterprise > 0 ? (
                            parseInt(contactType) === 2 ? (
                                totalContactAdmin > 1 && (
                                    <img
                                        src="../assets/img/icons/Icono_eliminar_activo.png"
                                        className="mx-1 icon-size-16x15 pointer"
                                        alt="img-trash"
                                        onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                                    />
                                )
                            ) : (
                                (currentIndex + 1) < totalRegister && (
                                    <img
                                        src="../assets/img/icons/Icono_eliminar_activo.png"
                                        className="mx-1 icon-size-16x15 pointer"
                                        alt="img-trash"
                                        onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                                    />
                                )
                            )
                        ) : (
                            (currentIndex + 1) < totalRegister && (
                                <img
                                    src="../assets/img/icons/Icono_eliminar_activo.png"
                                    className="mx-1 icon-size-16x15 pointer"
                                    alt="img-trash"
                                    onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                                />
                            )
                        )
                    )
                )} */}
                {!onlyReadInputs && (
                    idEnterprise > 0 ? (
                        parseInt(contactType) === 2 ? (
                            totalContactAdmin > 1 && (
                                <img
                                    src="../assets/img/icons/Icono_eliminar_activo.png"
                                    className="mx-1 icon-size-16x15 pointer"
                                    alt="img-trash"
                                    onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                                />
                            )
                        ) : (
                            (currentIndex + 1) < totalRegister && (
                                <img
                                    src="../assets/img/icons/Icono_eliminar_activo.png"
                                    className="mx-1 icon-size-16x15 pointer"
                                    alt="img-trash"
                                    onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                                />
                            )
                        )
                    ) : (
                        (currentIndex + 1) < totalRegister && (
                            <img
                                src="../assets/img/icons/Icono_eliminar_activo.png"
                                className="mx-1 icon-size-16x15 pointer"
                                alt="img-trash"
                                onClick={() => handleDeleteFunction(currentIndex, idEnterprise, idWelcomeEmail)}
                            />
                        )
                    )
                )}
            </div>
        </>
    )
}

export default EnterpriseContactForm