import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer, { restoreSessionAction } from "./authDuck";
import groupReducer from "./groups";
import groupListReducer, { restoreGroupAction } from "./getGroupList";
import organizationListReducer, { restoreOrganizationAction } from "./getOrganizationList";

let rootReducer = combineReducers({
    groupSpecific: groupReducer,
    auth: authReducer,
    groupList: groupListReducer,
    organizationList: organizationListReducer,
});

//TODO: Cambiar por productivo

//dev
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//prod
//const composeEnhancers = compose;

const generateStore = () => {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
    //restablecer session en refresh
    restoreSessionAction()(store.dispatch)
    restoreGroupAction()(store.dispatch)
    restoreOrganizationAction()(store.dispatch)
    return store;
}

export default generateStore;