import React, { useState } from 'react'
import { CampaignForm } from './CampaignForm';
import { CampaignList } from './CampaignList';

export const CampaignScreen = () => {

    const [campaign, setCampaign] = useState({
        inUse: false,
        idCampaign: 0,
        title: '',
        description: '',
        initialDate: '',
        finalDate: '',
        numCollabs: 0,
        numSponsors: 0,
        amount: 0,
        options: "0",
        active: 1,
        orgList: "",
        sprList: "",
    });

    const handleClickReturn = () => {
        setCampaign({
            inUse: false,
            idCampaign: 0,
            title: '',
            description: '',
            initialDate: '',
            finalDate: '',
            numCollabs: 0,
            numSponsors: 0,
            amount: 0,
            options: "0",
            active: 1,
            orgList: "",
            sprList: "",
        });
    }

    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className='row'>
                        <div className='col'>
                            <h3 className="text-center text-sm-start">Campaña</h3>
                        </div>
                        <div className='col text-end'>
                            {
                                campaign.inUse ?
                                    (
                                        ''
                                    )
                                    :
                                    (
                                        <span
                                            className='text-pink pointer fs-3'
                                            onClick={() => {
                                                setCampaign(prevState => ({
                                                    ...prevState,
                                                    inUse: true
                                                }))
                                            }}
                                        ><i className='bi bi-plus-circle-fill' /></span>
                                    )
                            }
                        </div>
                    </div>
                </div>
                {
                    !campaign.inUse ?
                        <div className="col-12">
                            <CampaignList setCampaign={setCampaign} />
                        </div>
                        :
                        <div className="col-12">
                            <CampaignForm campaign={campaign} setCampaign={setCampaign} handleClickReturn={handleClickReturn} />
                        </div>
                }
            </div>
        </div >
    )
}
