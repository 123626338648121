import axios from 'axios';
// TODO: Tools
import * as toastr from 'toastr'

export const getSectorCat = async () => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getSectorCat",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getSubSectorCat = async (sectorId) => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getSubSectorCat?idSector=" + sectorId,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getAddressByCP = async (cp) => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getZipInfo?idZip=" + cp,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getCountries = async () => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getCountryCat",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @returns 
 */
export const getPromotionCategory = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getPromotionCategory`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                default:
                    obj.data = result.data
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @returns 
 */
export const getPromotionType = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}catalogs/getPromotionType`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                default:
                    obj.data = result.data
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
