import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// TODO: Components
import Notifiacitions from './Notifiacitions'
// TODO: Redux
import { doLogOutAction } from '../../redux/authDuck'
import {
    getGroups,
    cleanGroup,
} from '../../redux/getGroupList'
import {
    getOrganizations,
    cleanOrganization,
} from '../../redux/getOrganizationList'

const Header = ({
    groupList,
    organizationList,
    doLogOutAction,
    getGroups,
    getOrganizations,
}) => {

    useEffect(() => {
        if (groupList.groupList.length === 0) {
            getGroups()
        }
        if (organizationList.organizationList.length === 0) {
            getOrganizations()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickLogout = () => {
        cleanGroup()
        cleanOrganization()
        doLogOutAction()
    }

    return (
        <div className='col-12 bg-blue-navy header__box-shadow sticky-top'>
            <div className='row'>
                <div className='col-sm-12 col-lg-11 col-xxl-10 d-flex justify-content-center flex-wrap align-content-center p-1'>
                    <img
                        src='./assets/img/guay-logo-blanco.png'
                        alt='logo guay'
                        className='img-h30px'
                    />
                </div>
                <div className='col-sm-12 col-lg-1 col-xxl-2 d-flex justify-content-end p-1'>
                    <Notifiacitions />
                    <img
                        src='./assets/img/icons/icono_cerrarsesion_blanco_activo.png'
                        className='icon-size-20x20 mx-1 mt-1 me-2'
                        alt='log-out'
                        onClick={handleClickLogout}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
    groupList,
    organizationList,
}) => {
    return {
        groupList,
        organizationList,
    }
}

export default connect(mapStateToProps,
    {
        doLogOutAction,
        getGroups,
        getOrganizations,
    }
)(Header)