import React from 'react'
// TODO: Tools
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table'
// import * as toastr from 'toastr'
import $ from 'jquery';

export const Table = ({enterprisesAwsActive,setdataForm}) => {
    // console.log('desde table: ',enterprisesAwsActive);
    // Table Props
    const options = {
        noDataText: 'No se encontraron empresas.',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    }
    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setIcons = (cell, row, rowIndex) => {
        return (
            <div className='col-12 d-flex justify-content-between'>
                <i
                    // className={row.awsActiveRel === 0 ? 'bi bi-recycle text-pink pointer' : 'bi bi-trash text-pink pointer'}
                    className={'bi bi-trash text-pink pointer'}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row.awsActiveRel === 0 ? "Activar" : "Desactivar"}
                    onClick={() =>{ 
                        setdataForm({ active:  0,enterpriseId: row.idEnterprise,enterpriseName:row.comercialName });
                        $("#closeModalActiveAWSRelation").show();   
                    }}
                />
            </div>
        )
    }
  return (
    <div className="col-12 generic__show-container container-1">
        <BootstrapTable
            data={enterprisesAwsActive}
            hover
            striped
            pagination={true}
            options={options}
            tableStyle={{ border: 'none', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
            trClassName='fs-7 border-0'
            trStyle={{ border: '.5px' }}
            search
            searchPlaceholder='Buscar'
        >
            <TableHeaderColumn
                dataField="idEnterprise"
                isKey
                dataAlign="center"
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                hidden
            ></TableHeaderColumn>
            <TableHeaderColumn
                dataField="comercialName"
                dataAlign="center"
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                dataSort={true}
            >Empresa</TableHeaderColumn>
            <TableHeaderColumn
                    dataField="idEnterprise"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataFormat={setIcons}
                    width="10%"
                ></TableHeaderColumn>
        </BootstrapTable>
    </div> 
  )
}
