import React from 'react';
// Tools
import PropTypes from 'prop-types';

const StatusNotes = ({ notes }) => {
    return (
        <div className="col-12 col-md-4 generic__show-container container-2 mt-2 mt-md-0">
            <div className="card shadow">
                <div className="card-body">
                    <div className="col-12 mb-1">
                        <span className="text-pink fw-bold">Notas de la Solicitud</span>
                    </div>
                    {
                        notes.map(data => (
                            <div
                                className="col-12 mb-1"
                                key={data.idOrderLog}
                            >
                                <span className="d-block">{data.descStatus}</span>
                                {
                                    data.comment ? (
                                        <span className="d-block font-small-2">{data.name}: {data.comment}</span>
                                    ) : (
                                        null
                                    )
                                }
                                <span className="d-block font-small-2">{data.register_date}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

StatusNotes.propTypes = {
    notes: PropTypes.array.isRequired
}

export default StatusNotes;
