import React from 'react'
// TODO: Generic func
import {
    handleDateToddmmmyyyy,
    handleidle,
    handleMargintColorCard,
    handleStatusLabel,
} from '../../../helpers/genericFunc'

const EnterpriseCard = ({
    enterpriseData,
    setEnterpriseObj,
    services = [],
    setShowForm,
    setHideHeader,
    setEntRegisterDate,
    setGroupInfo,
    handleDeleteCard,
    idgroup = '',
    groupName = '',
    index = '',
    setIndexTemporalEnterprise,
    totalEnterprisesGroup = 0,
}) => {

    const {
        activeCollaborators,
        color,
        idEnterprise,
        logo,
        commercialName,
        registerDate,
        status,
        renewalDate,
    } = enterpriseData
    // console.log('registerDate: ',registerDate, 'renewalDate:  ',renewalDate);
    const handleWidthCard = idgroup => {
        let w = idgroup === '' ? 'w-75' : ' w-100'
        return w
    }
    return (
        <div
            className={'col-2 mb-4'}
            key={`enterprise-${idEnterprise}`}
        >
            <div
                className={`card rounded-10px shadow-sm py-3 h-100 pointer ${handleWidthCard(idgroup)} ${handleMargintColorCard(color)}  ${handleidle(color, status)}`}
            >
                {
                    idgroup === '' ?
                        (
                            <div className='col-12'>
                                <i
                                    className='bi bi-trash text-danger'
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '5px',
                                        zIndex: '100',
                                    }}
                                    onClick={() => handleDeleteCard(idEnterprise, commercialName, activeCollaborators)}
                                />
                            </div>
                        ) :
                        (
                            idgroup === 0 ?
                                (
                                    <div className='col-12'>
                                        <i
                                            className='bi bi-trash text-danger'
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '5px',
                                                zIndex: '100',
                                            }}
                                            onClick={() => handleDeleteCard(idEnterprise, commercialName, index, activeCollaborators)}
                                        />
                                    </div>
                                )
                                :
                                idgroup > 0 && totalEnterprisesGroup > 1 &&
                                <div className='col-12'>
                                    <i
                                        className='bi bi-trash text-danger'
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '5px',
                                            zIndex: '100',
                                        }}
                                        onClick={() => handleDeleteCard(idEnterprise, commercialName, index, activeCollaborators)}
                                    />
                                </div>
                        )
                }
                <div className='card-body generic__show-container top container-2'
                    onClick={() => {
                        if (idgroup !== '') {
                            setEnterpriseObj({
                                ...enterpriseData,
                                registerDate: services.length > 0 ? new Date(services[0].onDate) : '',
                                productEnterprise: services
                            })
                        } else {
                            setEnterpriseObj({
                                ...enterpriseData,
                                registerDate: enterpriseData.productEnterprise.length > 0 ? new Date(enterpriseData.productEnterprise[0].onDate) : '',
                            })
                        }
                        setEntRegisterDate(enterpriseData.registerDate)
                        setShowForm(true)
                        setHideHeader(true)
                        setGroupInfo({
                            idgroup: idgroup,
                            groupLabel: groupName,
                            isFromGroupForm: true,
                        })
                        setIndexTemporalEnterprise(index)
                    }}
                >
                    <div className='row h-100'>

                        <div className='col-12 mb-0 d-flex justify-content-center h-1of3'>
                            <img
                                src={logo === '' || logo === null ? './assets/img/icons/icono_logo_empresa.png' : logo}
                                //className='img-h30px img-fluid'
                                style={{
                                    width: '85%',
                                    aspectRatio: '3/2',
                                    objectFit: 'contain',
                                }}
                                alt='logo'
                            />
                        </div>

                        <div className='col-12 h-1of3 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p
                                        className='fs-075em fw-bold text-blue font-family-quicksand text-center m-0 overflow-ellipsis'
                                        data-bs-toggle="tooltip"
                                        title={commercialName}
                                    >{commercialName}</p>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-6 d-flex justify-content-center flex-wrap align-content-center'>
                                            <img
                                                src='./assets/img/icons/Icono_colaboradores_Activo.png'
                                                className='icon-size-11x10'
                                                alt='icon'
                                            />
                                        </div>
                                        <div className='col-6 d-flex justify-content-center flex-wrap align-content-center'>
                                            <span className='fs-075em fw-bold text-blue font-family-quicksand'>{activeCollaborators}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 h-1of3'>
                            <div className='row'>

                                <div className='col-12 mb-1 d-flex justify-content-center'>
                                    <span className='fs-0625em text-ad-grey-white-guay font-family-quicksand'>{handleStatusLabel(status)}</span>
                                </div>
                                <div className='col-12 mb-1 d-flex justify-content-center text-blue font-family-quicksand fw-semibold'>
                                    <span>{renewalDate === '' ? handleDateToddmmmyyyy(registerDate) : handleDateToddmmmyyyy(renewalDate)}</span>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterpriseCard