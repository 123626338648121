import React, { useEffect, useState } from 'react';
import { getBenefits } from '../../models/benefits_model';
import { BenefitsList } from './BenefitsList';
import * as toastr from 'toastr';
import { BenefitForm } from './BenefitForm';
// TODO: API calls
import { getAllPlansPromotions } from '../../models/promotions_model';

const BenefitsScreen = () => {

    const [listBenefits, setListBenefits] = useState([]);
    const [benefitForm, setBenefitForm] = useState({
        inEdition: false,
        urlLogo: '',
        idBenefit: 0,
        urlEnterprise: '',
        title: '',
        nameEnterprise: '',
        description: '',
        active: 1,
        urlResource: '',
        phone: '',
        whatsapp: '',
        urlContact: '',
        email: '',
        urlManual: '',
        urlDigdesc:'',
        flgGral: 0,
        urlService: '',
    });

    const { inEdition } = benefitForm;

    const [promotionsPlan, setPromotionsPlan] = useState([]);
    const [columns, setColumns] = useState({});

    useEffect(() => {
        getBenefits().then(response => {
            switch (response?.code) {
                case 1:
                    setListBenefits(response.guayBenefits);
                    break;
                case 1001:
                    toastr.info("No se encontraron beneficios activos", "1001");
                    break;

                default:
                    toastr.error("Ocurrió un error inesperado");
                    break;
            }
        });
    }, [inEdition]);

    useEffect(() => {
        getAllPlansPromotions().then(response => {
            if (response.validation) {
                //console.log(response.data.plans);
                setPromotionsPlan(handleDragDropArr(response.data.plans));
            }
        });
    }, []);

    const handleDragDropArr = arr => {
        let newArr = [];
        for (let x in arr) {
            newArr.push({ idPlanPromotion: arr[x].idPlanPromotion.toString(), title: arr[x].title });
        }
        return newArr;
    }

    const handleSetBenefits = (id, arr) => {
        console.log(id, arr)
        if (id === 0) {
            const columnsFromBackend = {
                1: {
                    name: "Promociones",
                    items: promotionsPlan
                },
                2: {
                    name: "Promociones del beneficio",
                    items: []
                }
            };
            //console.log(columnsFromBackend);
            setColumns(columnsFromBackend);
        } else {
            const getSelected = handleDragDropArr(arr);
            //console.log(getSelected);

            const getSelectedId = getSelected.map(element => element.idPlanPromotion);
            //console.log(getSelectedId);
            const getUnselectedId = promotionsPlan.map(element => element.idPlanPromotion);
            //console.log(getUnselectedId);
            const difference = getUnselectedId.filter(x => getSelectedId.indexOf(x) === -1);
            //console.log(difference);
            const getUnselected = promotionsPlan.filter(x => difference.indexOf(x.idPlanPromotion) !== -1);
            //console.log(getUnselected);

            const getUnselected2 = promotionsPlan.filter(x => difference.indexOf(x.idPlanPromotion) === -1);
            //console.log(getUnselected2);

            const columnsFromBackend = {
                1: {
                    name: "Promociones",
                    items: getUnselected,
                },
                2: {
                    name: "Promociones del beneficio",
                    items: getUnselected2,
                }
            };
            //console.log(columnsFromBackend);
            setColumns(columnsFromBackend);
        }
    }

    const handleClickReturn = () => {
        setBenefitForm({
            inEdition: false
        });
    }

    const handleClickNewBenefit = () => {
        setBenefitForm({
            inEdition: true,
            urlLogo: '../assets/img/icons/sin_logo.png',
            idBenefit: 0,
            urlEnterprise: '',
            title: '',
            nameEnterprise: '',
            description: '',
            active: 1,
            urlResource: '../assets/img/icons/sin_logo.png',
            phone: '',
            whatsapp: '',
            urlContact: '',
            email: '',
            urlManual: '',
            urlDigdesc:'',
            flgGral: 0,
            urlService: '',
        });
        handleSetBenefits(0, []);
    }

    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className='row'>
                        <div className='col'>
                            <h3 className="text-center text-sm-start">Beneficios</h3>
                        </div>
                        <div className='col text-end'>
                            {
                                inEdition ?
                                    (
                                        ''
                                    )
                                    :
                                    (
                                        <span
                                            className='text-pink pointer fs-3'
                                            onClick={handleClickNewBenefit}
                                        ><i className='bi bi-plus-circle-fill' /></span>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    {inEdition ?
                        <BenefitForm
                            // TODO: Hooks
                            columns={columns}
                            setColumns={setColumns}
                            benefitForm={benefitForm}
                            setBenefitForm={setBenefitForm}
                            // TODO: Functions
                            handleClickReturn={handleClickReturn}
                        />
                        :
                        <BenefitsList
                            listBenefits={listBenefits}
                            setBenefitForm={setBenefitForm}
                            handleSetBenefits={handleSetBenefits}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default BenefitsScreen;
