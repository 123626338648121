import React, { useState } from 'react'
// TODO: Components
import Loader from '../genericComponents/Loader'
import ModalPreview from '../genericComponents/ModalPreview'
// TODO: Tools
import $ from 'jquery'
import * as toastr from 'toastr'

const Form = ({
    resourceObj,
    setResourceObj,
    setShowDetails,
    saveResource,
    resourceFile,
    setResourceFile,
    handleResetTable,
    resourceURL,
}) => {

    const [previewFile, setPreviewFile] = useState('')
    const [previewFileType, setPreviewFileType] = useState('')

    const {
        idResource,
        resourceType,
        title,
        description,
        active,
        serviceType,
        fileName,
    } = resourceObj

    const handleOnChange = e => {
        setResourceObj({
            ...resourceObj,
            [e.target.name]: e.target.value,
        })
        if (e.target.name === 'resourceType') {
            $('#resource').val('')
            setResourceFile({})
        }
    }
    const handleOnSubmit = e => {
        e.preventDefault()
        if ([
            resourceType,
            title,
            description,
            serviceType,
        ].includes('')) {
            toastr.warning("Todos los campos son obligatorios.", "¡Ooops!")
            return
        }
        if (idResource === '' && resourceFile.size === undefined) {
            toastr.warning("Debes subir algún recurso.", "¡Ooops!")
            return
        }
        const service = parseInt(serviceType) === 1 ? '1,1,1' : parseInt(serviceType) === 2 ? '1,1,2' : '1,2,0'
        const getIds = service.split(',')
        let newResourceObj = {
            ...resourceObj,
            idResource: idResource === '' ? 0 : parseInt(idResource),
            resourceType: parseInt(resourceType),
            active: parseInt(active),
            idProduct: getIds[0],
            idCategory: getIds[1],
            idSubcategory: getIds[2],
        }
        //console.log(newResourceObj)
        $('#myModal').show()
        saveResource(newResourceObj, resourceFile).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        if (idResource === '') {
                            toastr.success("El recurso se registró correctamente.", "¡Éxito!")
                        } else {
                            toastr.success("El recurso se guardó correctamente.", "¡Éxito!")
                        }
                        handleResetTable()
                        $('#myModal').hide()
                        break;
                    case 1001:
                    case 1002:
                        toastr.error("Debes subir un archivo para guardar el recurso.", "¡Ooops!")
                        $('#myModal').hide()
                        break;
                    case 1003:
                        toastr.error("No se pudo guardar el recurso. No se encontró el previo registro.", "¡Ooops!")
                        $('#myModal').hide()
                        break;
                    case 1004:
                        toastr.error("El recurso excede el tamaño permitido.", "¡Ooops!")
                        $('#myModal').hide()
                        break;
                    case 1005:
                        toastr.error("El tipo de recurso no existe.", "¡Ooops!")
                        $('#myModal').hide()
                        break;
                    case 1006:
                        toastr.error("El recurso debe ser audio / video / pdf.", "¡Ooops!")
                        $('#myModal').hide()
                        break;
                    default:
                        toastr.error(`Hubo un error al intentar guardar. Error: ${response.message}`, "¡Ooops!")
                        $('#myModal').hide()
                        break;
                }
            }
        })
    }
    const handleResetForm = () => {
        setResourceObj({
            ...resourceObj,
            idResource: '',
            resourceType: '',
            title: '',
            description: '',
            active: '1',
            serviceType: '',
            fileName: '',
        })
        setShowDetails(false)
        $('#resource').val('')
        setResourceFile({})
    }

    const handleCleanViwer = () => {
        setPreviewFileType(0)
    }

    const handlePreview = (srcFile) => {
        if (typeof srcFile === 'string') {
            if (srcFile.includes('pdf') || srcFile.includes('mp3') || srcFile.includes('mp4')) {
                setPreviewFile(srcFile)
                setPreviewFileType(1)
                $('#modalPreview').show()
                return
            }
            if (srcFile.includes('jpeg') || srcFile.includes('jpg') || srcFile.includes('png')) {
                setPreviewFile(srcFile)
                setPreviewFileType(2)
                $('#modalPreview').show()
                return
            }
            toastr.info('Lo sentimos, no se puede previsualizar ese tipo de documento.', '¡Ooops!')
            window.open(srcFile)
        } else {
            if (srcFile.name.split('.').pop() === 'pdf' || srcFile.name.split('.').pop() === 'mp3' || srcFile.name.split('.').pop() === 'mp4') {
                //Create a Blob from the PDF Stream
                const file = new Blob([srcFile], { type: `${srcFile.type}` });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                setPreviewFile(fileURL)
                setPreviewFileType(1)
                $('#modalPreview').show()
                return
            }
            if (srcFile.name.split('.').pop() === 'jpeg' || srcFile.name.split('.').pop() === 'jpg' || srcFile.name.split('.').pop() === 'png') {
                //Create a Blob from the PDF Stream
                const file = new Blob([srcFile], { type: `${srcFile.type}` });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                setPreviewFile(fileURL)
                setPreviewFileType(2)
                $('#modalPreview').show()
                return
            }
            toastr.info('Lo sentimos, no se puede previsualizar ese tipo de documento.', '¡Ooops!')
            //Create a Blob from the PDF Stream
            const file = new Blob([srcFile], { type: `${srcFile.type}` })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
        }
    }

    return (
        <div className='col-12'>
            <ModalPreview
                file={previewFile}
                fileType={previewFileType}
                cleanFunction={handleCleanViwer}
            />
            <form
                className='row'
                onSubmit={handleOnSubmit}
            >
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <div className="input-group input-group-guay mb-3">
                        <span className="input-group-text fw-bold w-25">Servicio</span>
                        <select
                            className="form-select"
                            id="serviceType"
                            name="serviceType"
                            value={serviceType}
                            onChange={handleOnChange}
                            disabled={idResource === '' ? false : true}
                        >
                            <option value=''>Seleccionar</option>
                            <option value='1'>NOM - Estado Emocional</option>
                            <option value='2'>NOM - Entorno Laboral</option>
                            <option value='3'>Clima Laboral</option>
                        </select>
                    </div>
                    <div className="input-group input-group-guay mb-3">
                        <span className="input-group-text fw-bold w-25">Tipo de recurso</span>
                        <select
                            className="form-select"
                            id="resourceType"
                            name="resourceType"
                            value={resourceType}
                            onChange={handleOnChange}
                            disabled={idResource === '' ? false : true}
                        >
                            <option value=''>Seleccionar</option>
                            <option value='1'>Ficha</option>
                            <option value='2'>Poster</option>
                            <option value='3'>Videos</option>
                            <option value='4'>Infografía</option>
                        </select>
                    </div>
                    <div className="input-group input-group-guay mb-3">
                        <span className="input-group-text fw-bold w-25">Nombre</span>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={title}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="input-group input-group-guay mb-3">
                        <span className="input-group-text fw-bold w-25 d-flex flex-wrap align-content-start">Descripción</span>
                        <textarea
                            type="text"
                            className="form-control textarea-unresize"
                            id="description"
                            name="description"
                            rows='3'
                            value={description}
                            onChange={handleOnChange}
                        ></textarea>
                    </div>
                    <div className="input-group d-flex justify-content-center">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="active"
                                checked={parseInt(active) === 1 ? true : false}
                                onChange={e => {
                                    setResourceObj({
                                        ...resourceObj,
                                        [e.target.name]: active === '1' ? '0' : '1',
                                    })
                                }}
                            />
                            <label
                                className="form-check-label"
                            >{'Activo'}</label>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center flex-wrap align-content-center'>
                    <div className='generic__show-container top container-2'>
                        <div className='text-center'>
                            <input
                                type="file"
                                className="visually-hidden"
                                id="resource"
                                name="resource"
                                accept={parseInt(resourceType) === 1 ? ".pdf" : parseInt(resourceType) === 3 ? "audio/mp3,video/mp4" : "image/png,image/jpeg,image/jpg,image/gif,.pdf"}
                                onChange={e => {
                                    if (e.target.files.length > 0) {
                                        let reader = new FileReader()
                                        let file = e.target.files[0]
                                        if (file.name.length > 250) {
                                            toastr.warning("El nombre del archivo a superado el máximo de caraceteres (250 max.).", "¡Ooops!")
                                            return
                                        }
                                        if (parseInt(resourceType) === 1 || parseInt(resourceType) === 4) {
                                            if (file.size < 50000000) {
                                                let ext = file.name.split('.').pop()
                                                ext = ext.toLowerCase()
                                                switch (ext) {
                                                    case 'pdf':
                                                        reader.onload = function (e) {
                                                            $('#resourceImg').attr('src', e.target.result)
                                                        }
                                                        reader.readAsDataURL(e.target.files[0])
                                                        setResourceObj({
                                                            ...resourceObj,
                                                            fileName: file.name,
                                                        })
                                                        setResourceFile(file)
                                                        break
                                                    default:
                                                        toastr.warning("El formato debe ser pdf.", "¡Ooops!")
                                                        break
                                                }
                                            } else {
                                                toastr.warning("El peso de la recurso debe ser menor a 50 MB.", "¡Ooops!")
                                            }
                                        }
                                        if (parseInt(resourceType) === 2) {
                                            if (file.size < 50000000) {
                                                let ext = file.name.split('.').pop()
                                                ext = ext.toLowerCase()
                                                switch (ext) {
                                                    case 'jpg':
                                                    case 'jpeg':
                                                    case 'png':
                                                    case 'gif':
                                                        reader.onload = function (e) {
                                                            $('#resourceImg').attr('src', e.target.result)
                                                        }
                                                        reader.readAsDataURL(e.target.files[0])
                                                        setResourceObj({
                                                            ...resourceObj,
                                                            fileName: file.name,
                                                        })
                                                        setResourceFile(file)
                                                        break
                                                    default:
                                                        toastr.warning("El formato debe ser jpg, jpeg, png o gif.", "¡Ooops!")
                                                        break
                                                }
                                            } else {
                                                toastr.warning("El peso de la recurso debe ser menor a 2 MB.", "¡Ooops!")
                                            }
                                        }
                                        if (parseInt(resourceType) === 3) {
                                            if (file.size < 250000000) {
                                                let ext = file.name.split('.').pop()
                                                ext = ext.toLowerCase()
                                                switch (ext) {
                                                    case 'mp4':
                                                    case 'mp3':
                                                        reader.onload = function (e) {
                                                            $('#resourceImg').attr('src', e.target.result)
                                                        }
                                                        reader.readAsDataURL(e.target.files[0])
                                                        setResourceObj({
                                                            ...resourceObj,
                                                            fileName: file.name,
                                                        })
                                                        setResourceFile(file)
                                                        break
                                                    default:
                                                        toastr.warning("El formato debe ser mp4 o mp3.", "¡Ooops!")
                                                        break
                                                }
                                            } else {
                                                toastr.warning("El peso de la recurso debe ser menor a 250 MB.", "¡Ooops!")
                                            }
                                        }
                                    }
                                }}
                            />
                            <div
                                className='text-center pointer'
                                onClick={() => {
                                    if (resourceType === '') {
                                        toastr.warning("Selecciona primero el tipo de recurso.", "¡Ooops!")
                                    } else {
                                        $('#resource').trigger('click')
                                    }
                                }}
                            >
                                <br /><i className={resourceFile.size === undefined ? idResource !== '' ? 'bi bi-cloud-check text-success fs-1' : 'bi bi-cloud-plus text-info fs-1' : 'bi bi-cloud-check text-success fs-1'} />
                                <br /><p className='fw-bold pointer m-0'>{resourceFile.size === undefined ? idResource !== '' ? 'Editar Recurso' : 'Subir Recurso' : 'Editar Recurso'}</p>
                            </div>

                            {(resourceFile.size !== undefined || idResource !== '') && (
                                <><br /><p
                                    className='fw-bold pointer m-0'
                                    onClick={() => {
                                        if (idResource !== '') {
                                            handlePreview(resourceURL)
                                        } else {
                                            handlePreview(resourceFile)
                                        }
                                    }}
                                >Visualizar recurso</p></>
                            )}

                            {idResource !== '' && (<><br /><p
                                className='fw-bold pointer m-0'
                                onClick={() => window.open(resourceURL)}
                            >Descargar recurso</p></>)}
                            <br />
                            <div className='col-10 mx-auto overflow-ellipsis'><span className='fw-bold pointer m-0'>{resourceFile.size === undefined ? idResource !== '' ? `Archivo: ${fileName}` : '' : `Archivo: ${resourceFile.name}`}</span></div>
                            {(parseInt(resourceType) === 1 || parseInt(resourceType) === 4) && (<div className='col-6 mx-auto'><p className='fs-7'><b>Tipos de archivo:</b> .pdf. <b>Peso máximo:</b> 50MB.</p></div>)}
                            {parseInt(resourceType) === 2 && (<div className='col-6 mx-auto'><p className='fs-7'><b>Tipos de archivo:</b> .png, .jpeg, .jpg y .gif. <b>Peso máximo:</b> 50MB.</p></div>)}
                            {parseInt(resourceType) === 3 && (<div className='col-6 mx-auto'><p className='fs-7'><b>Tipos de archivo:</b> .mp4, .mp3. <b>Peso máximo:</b> 250MB.</p></div>)}
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex justify-content-between'>
                    <button
                        type='button'
                        className='btn btn-sm bg-blue-navy text-white'
                        onClick={handleResetForm}
                    >Regresar</button>
                    <input
                        type="submit"
                        className="btn btn-sm btn-pink-guay text-white"
                        value="Guardar"
                    />
                </div>
            </form>
            <div
                className="modal"
                id="myModal"
                data-backdrop="false"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                        <div className="modal-body">
                            <Loader />
                            <div className='col-12 text-center mt-3'>
                                <h1>Subiendo recurso</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form
