import React from 'react'

const GenericNumberInput = ({
    generalValue,
    value,
    setValue,
    maxValue,
    name,
    id,
    index,
    readOnly,
    className,
    style,
}) => {
    const handleMaxNumber = e => {
        if (e.target.value.length < (maxValue + 1)) {
            if (Object.entries(generalValue).length > 0) {
                setValue({
                    ...generalValue,
                    [e.target.name]: e.target.value,
                })
            } else {
                if (index === '') {
                    setValue(e.target.value)
                } else {
                    setValue(e, index)
                }
            }
        }
    }
    return (
        <input
            type='number'
            className={className}
            placeholder=' '
            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
            onChange={handleMaxNumber}
            value={value}
            name={name}
            id={id}
            readOnly={readOnly}
            style={style}
        />
    )
}

export default GenericNumberInput