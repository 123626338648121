import axios from 'axios'

export const getResources = async (idProdduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url}Resources/getResources?idProduct=${idProdduct}&idCategory=${idCategory}&idSubcategory=${idSubcategory}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {object} obj 
 * @param {*} file 
 * @returns 
 */
export const saveResource = async (obj, file) => {
    try {
        const formData = new FormData()
        formData.append('resourceType', obj.resourceType)
        formData.append('title', obj.title)
        formData.append('description', obj.description)
        formData.append('idUser', obj.idUser)
        formData.append('active', obj.active)
        if (file.size > 0) {
            formData.append('file', file)
        }
        formData.append('idResource', obj.idResource)
        formData.append('idProduct', obj.idProduct)
        formData.append('idCategory', obj.idCategory)
        formData.append('idSubcategory', obj.idSubcategory)
        formData.append('fileName', obj.fileName)
        let result = await axios({
            method: 'POST',
            url: global.base_url + "Resources/saveResource",
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
