import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import Recaptcha from "react-google-invisible-recaptcha";
import $ from 'jquery';
import { doLoginAction } from '../../redux/authDuck';
import { connect } from 'react-redux';
import * as toastr from 'toastr';

const LoginScreen = ({ auth, doLoginAction }) => {

    const [humanDetected, setHumanDetected] = useState(false);

    useEffect(() => {
        $('body').removeClass('bg-image-general');
        $('body').addClass('bg-image-login');
        if (auth.error) {
            toastr.error(auth.error.message);
        }
    }, [auth.error])

    const recaptcha = useRef();

    const initialForm = {
        email: '',
        password: '',
    }

    const [formValues, handleInputChange, reset] = useForm(initialForm);

    const { email, password } = formValues;

    const handleClicLogin = () => {
        if (email !== '' && password !== '') {
            if (humanDetected) {
                recaptcha.current.execute();
                doLoginAction(email, password);
                reset();
                $('body').removeClass('bg-image-login');
                $('body').addClass('bg-image-general');
            } else {
                toastr.warning('Debes seleccionar el checkbox', '¡Ooops!');
            }
        }
        setHumanDetected(false);
        recaptcha.current.reset();
    }

    const handleSeePassword = () => {
        if ($("#floatingPassword").attr("type") === "password") {
            $("#floatingPassword").attr("type", "text");
            $("#icon_eye_1").attr("class", "bi bi-eye-slash");
        } else {
            $("#floatingPassword").attr("type", "password");
            $("#icon_eye_1").attr("class", "bi bi-eye");
        }
    }

    const handleHumanDetected = e => {
        if (e.target.checked) {
            toastr.success("Human detected." + recaptcha.current.getResponse())
            setHumanDetected(true);
        } else {
            setHumanDetected(false);
        }
    }

    return (

        <div
            className='col-sm-11 col-lg-10 col-xxl-12 mx-auto d-flex flex-wrap align-content-center'
            style={{ height: '100vh' }}
        >
            <div className='col-sm-12 col-lg-12 col-xxl-10 mx-auto'>
                <div
                    className='card shadow-lg border-0'
                    style={{ backgroundColor: 'rgba(255,255,255,0.25)', borderRadius: '25px' }}
                >
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-3 col-xxl-4 d-flex justify-content-center flex-wrap align-content-center'>
                                <img
                                    className='w-50'
                                    src="../assets/img/elementos_admin-54.png"
                                    alt="guay logo"
                                />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 col-xxl-4 d-flex flex-wrap align-content-center'>
                                <div className='col-12 d-flex justify-content-center mb-3 pt-5'>
                                    <img
                                        className="w-25"
                                        src="../assets/img/logo_guay_color.png"
                                        alt="logo guay"
                                    />
                                </div>
                                <div className='col-12 text-sec-blue-gray-guay'>
                                    <p className='text-center text-gray'>
                                        Lo que no se define no se puede medir,
                                        lo que no se mide no se puede mejorar,
                                        lo que no se mejora se degrada siempre.
                                        <br />
                                        <br />
                                        <b>-William Thomson-</b>
                                    </p>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-5 col-xxl-4 d-flex flex-wrap align-content-center'>
                                <div className='col-12 p-0'>
                                    <div
                                        className='card bg-light-blue border-0'
                                        style={{ borderRadius: '25px' }}
                                    >
                                        <div className='card-body'>
                                            <div className='col-12 text-center'>
                                                <h2 className='fw-bold'>Ingresa</h2>
                                            </div>
                                            <div className='col-12 text-center mb-5'>
                                                <h5 className='text-pink fw-bold'>Administradores</h5>
                                            </div>
                                            <div className='col-12 mb-3'>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleInputChange}
                                                    className="form-control py-2 border-0"
                                                    style={{ borderRadius: '15px' }}
                                                    id="floatingInput"
                                                    placeholder="Correo electronico"
                                                />
                                            </div>
                                            <div className='col-12 mb-5 input-group'>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={handleInputChange}
                                                    className="form-control py-2 border-0"
                                                    style={{ borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }}
                                                    id="floatingPassword"
                                                    placeholder="Contraseña"
                                                />
                                                <span
                                                    className="input-group-text border-0 bg-blue-navy text-white pointer"
                                                    onClick={handleSeePassword}
                                                    style={{ borderTopRightRadius: '15px', borderBottomRightRadius: '15px' }}
                                                ><i className="bi bi-eye" id="icon_eye_1" /></span>
                                            </div>
                                            <div className='col-12 form-check d-flex justify-content-center mb-5'>
                                                <Recaptcha
                                                    ref={recaptcha}
                                                    sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                                                    onResolved={() => console.log("Human detected." + recaptcha.current.getResponse())}
                                                />
                                                <input
                                                    className="form-check-input me-3"
                                                    type="checkbox"
                                                    onClick={handleHumanDetected}
                                                    checked={humanDetected}
                                                />
                                                <label className="form-check-label text-sec-blue-gray-guay">
                                                    No soy un robot
                                                </label>
                                            </div>
                                            <div className='col-12 mb-3 d-flex justify-content-center'>
                                                <button
                                                    type='button'
                                                    className='btn btn-sm bg-blue-navy text-white w-50'
                                                    onClick={handleClicLogin}
                                                >Ingresar</button>
                                            </div>
                                            <div className='col-12 text-center'>
                                                <span className='text-decoration-underline pointer text-sec-blue-gray-guay'>
                                                    Recuperar contraseña
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 text-center py-3'>
                                <b className='text-yellow'>
                                    Nuestras redes sociales {''}
                                    <a href="https://www.facebook.com/guay-107436667630740" target="_blank" rel="noopener noreferrer"><i className="text-green bi bi-facebook mx-2" /></a>
                                    <a href="https://www.linkedin.com/company/mesientoguay/" target="_blank" rel="noopener noreferrer"><i className="text-cia bi-linkedin mx-2" /></a>
                                    <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram mx-2 text-yellow" /></a>
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 text-center mt-5'>
                <span>Copyright © 2022 guay</span>
            </div>
        </div >
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        auth
    }
}

export default connect(mapStateToProps, { doLoginAction })(LoginScreen)
