import React from 'react'

const GroupFilters = ({
    sortArray,
    handleFilters,
    selectedFilter,
    setSelectedFilter,
}) => {
  return (
    <div className='row sticky-filters'>
        <div className='col-sm-12 col-lg-9 col-xxl-9 mb-5 d-flex flex-wrap align-content-center'>
            <ul className='nav justify-content-start' >
                <li
                    className='pointer'
                    style={{ padding: '0rem 1rem 0rem 0rem' }}
                    onClick={() => {
                        handleFilters(1)
                        setSelectedFilter(1)
                    }}
                >
                    <i className="bi bi-circle-fill text-white" style={{ boxShadow: '1px -2px 11px #ccc', borderRadius: '8px' }} />
                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> <span className={`font-family-quicksand ${selectedFilter === 1 && 'border-bottom-light-blue-guay'}`}>Activo</span></span>
                </li>
                <li
                    className='pointer'
                    style={{ padding: '0rem 0.5rem 0rem 1rem' }}
                    onClick={() => {
                        handleFilters(2)
                        setSelectedFilter(2)
                    }}
                >
                    <i className="bi bi-circle-fill text-sec-light-blue-guay" />
                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> <span className={`font-family-quicksand ${selectedFilter === 2 && 'border-bottom-light-blue-guay'}`}>Activación Pendiente</span></span>
                </li>
                <li
                    className='pointer'
                    style={{ padding: '0rem 0.5rem 0rem 1rem' }}
                    onClick={() => {
                        handleFilters(3)
                        setSelectedFilter(3)
                    }}
                >
                    <i className="bi bi-circle-fill text-pink" style={{ opacity: '20%' }} />
                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> <span className={`font-family-quicksand ${selectedFilter === 3 && 'border-bottom-light-blue-guay'}`}>Renovación</span></span>
                </li>
                <li
                    className='pointer'
                    style={{ padding: '0rem 0.5rem 0rem 1rem' }}
                    onClick={() => {
                        handleFilters(4)
                        setSelectedFilter(4)
                    }}
                >
                    <i className="bi bi-circle-fill text-sec-white-gray-guay" />
                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> <span className={`font-family-quicksand ${selectedFilter === 4 && 'border-bottom-light-blue-guay'}`}>Inactiva</span></span>
                </li>
                <li
                    className='pointer'
                    style={{ padding: '0rem 0.5rem 0rem 1rem' }}
                    onClick={() => {
                        handleFilters(10)
                        setSelectedFilter(10)
                    }}
                >
                    <span style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}> <span className={`font-family-quicksand ${selectedFilter === 10 && 'border-bottom-light-blue-guay'}`}>Todos</span></span>
                </li>
            </ul>
        </div>
        <div className='col-sm-12 col-lg-3 col-xxl-3 mb-5 input-guay-search input-search-img d-flex justify-content-end'>
            <input
                type="text"
                className="form-control font-family-quicksand w-75"
                placeholder="Buscar"
                onChange={sortArray}
            />
            <img
                src="../assets/img/icons/Icono_buscar.png"
                className="mx-1 icon-size-20x20"
                alt="img-search"
            />
        </div>
    
    </div>
  )
}

export default GroupFilters
