import React,{ useState,useEffect } from 'react'
import { getEnterpriseActive,getListEnterpriseAwsRelation } from '../../models/enterprise_model'
import { connect } from 'react-redux';
import * as toastr from 'toastr';
import $ from 'jquery';
import { Table } from './Table';
import { ModalConfirmActive } from './ModalConfirmActive';

const EmailConfig = ({auth}) => {
    // console.log(auth);
    const [enterprises, setEnterprises] = useState([])
    const [enterpriseSelected, setEnterpriseSelected] = useState(0)
    const [enterprisesAwsActive, setEnterprisesAwsActive] = useState([])
    const [enterprisesAwsNotActive, setEnterprisesAwsNotActive] = useState([])
    const [dataForm, setdataForm] = useState({
        active:'',
        enterpriseId: '',
        enterpriseName:''
    })
    const [userId, setUserId] = useState(0)

    useEffect(() => {
        setUserId(auth.id)
    }, [auth])
    

    useEffect(() => {    
        getEnterpriseActive().then(response => {
            if (response) {
                setEnterprises(response)
                setEnterprisesAwsNotActive(response)
                // console.log('response enterprises: ',response);
            }else{
                toastr.error('Hubo un error al cargar las empresas.', '¡Ooops!');
            }
        });
    
        
    }, [])

    useEffect(() => {
        if(enterprises.length > 0){
            getListEnterpriseAwsRelation().then(response => {
                if (response) {
                    switch (response.code) {
                        case 1 :
                            //Verificamos si existen registros en la tabla de relacion
                            if (response.taRelEnterpriseAwsSesList && response.taRelEnterpriseAwsSesList.length > 0) {
                                //Obtenemos solo las empresas activas que tienen ya la relacion asignada
                                const enterprisesAwsActive = response.taRelEnterpriseAwsSesList.filter((ent) => ent.enterpriseActive === 1 && parseInt(ent.awsActiveRel) === 1)
                                setEnterprisesAwsActive(enterprisesAwsActive)
                                if(enterprisesAwsActive.length > 0){
                                    // Si existen emoresas activas con relacion filtramos idEnterprise
                                    const arrRelacionados2 = enterprisesAwsActive.map((ent) => ent.idEnterprise)
                                    // Filtramos del arreglo de empresas activas solo las que no tienen ya una relacion y que existen en la tabla de relacion
                                    const arrRelacionados3 = enterprises.filter(ent => arrRelacionados2.indexOf(parseInt(ent.enterpriseId)) === -1)
                                    setEnterprisesAwsNotActive(arrRelacionados3)
                                }else{
                                    //Todas las empresas activas estan disponibles para asignarles la configuracion de aws
                                    setEnterprisesAwsNotActive( enterprises.map((ent) => ent))

                                }
                            }
                        break;
                        default:
                            //Todas las empresas activas estan disponibles para asignarles la configuracion de aws
                            setEnterprisesAwsNotActive( enterprises.map((ent) => ent))
                        break;
                    }
                    
                }
            })
        }
      
    }, [enterprises])

    const handleSubmit = e => {
        e.preventDefault();
        if(parseInt(enterpriseSelected === 0) || enterpriseSelected === '' || enterpriseSelected === undefined){
            toastr.error("Debe seleccionar una empresa.", "¡Ooops!");
            return false;
        }
        if(userId === 0 || userId=== "" || userId === undefined){
            toastr.error("No se pudo obtener el número de usuario.", "¡Ooops!");
            return false;
        }
        const enterSelected = enterprises.find(ent => parseInt(ent.enterpriseId) === parseInt(enterpriseSelected))
        const {comercial} = enterSelected
        // console.log('enterSelected: ', enterSelected , 'comercial: ',comercial);
        setdataForm({ active:  1,enterpriseId: enterpriseSelected,enterpriseName:comercial });
        $("#closeModalActiveAWSRelation").show();   
    }

  return (
    <div className="col-12 my-5">
        <div className="row">
            <ModalConfirmActive 
                dataForm={dataForm} setdataForm={setdataForm} userId={userId} setEnterprises={setEnterprises} 
                setEnterprisesAwsNotActive={setEnterprisesAwsNotActive} setEnterpriseSelected={setEnterpriseSelected}/>
            <div className="col-11 mx-auto mb-3">
                <h3>Configuración de correo por AWS</h3>
            </div>
            <div className="col-11 mx-auto">
                <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
                    <div className='card-body'>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container top container-1'>
                                <div className="row"> 
                                    <div className="col-sm-12 col-lg-12 col-xxl-12">
                                        <span>Configurar correo de empresa por medio de AWS</span>
                                    </div> 
                                </div>
                                <div className="row mt-3">
                                    <div className="col-2 text-center">
                                        <span className="fw-bold w-35">Empresa</span>
                                    </div>
                                    <div className="col-8">
                                        <select
                                            className="form-select bg-white selectBorder"
                                            id="enterprises"
                                            name="enterprises"
                                            value={enterpriseSelected}
                                            onChange={(e) => {
                                                if(e.target.value > 0){
                                                    $('#enterprises').addClass("selectBorder");
                                                    $('#enterprises').removeClass('border-danger');

                                                }else{
                                                    $('#enterprises').addClass('border-danger');
                                                    $('#enterprises').removeClass('selectBorder');
                                                }
                                                setEnterpriseSelected(e.target.value)
                                            }}
                                        >
                                            <option key={"enterprise-" + 0} value={0}>Seleccione...</option>
                                            {
                                                enterprisesAwsNotActive.map(data => (
                                                    <option key={"enterprise-" + data.enterpriseId} value={data.enterpriseId}>{data.comercial}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <button
                                            type="submit"
                                            className="btn btn-sm btn-primary btn-pink-guay"
                                        >Envíar por AWS</button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-lg-12 col-xxl-12">
                                        <span>Empresas que ya tienen configurado el correo por medio de AWS</span>
                                    </div> 
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <Table
                                            enterprisesAwsActive={enterprisesAwsActive}
                                            setdataForm={setdataForm}
                                            // dataForm={dataForm}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}
export default connect(mapStateToProps)(EmailConfig);