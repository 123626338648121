import React, { useState, useEffect } from 'react';
// API calls
import { getSectorCat, getSubSectorCat, getAddressByCP, getCountries } from "../../../../models/catalog_model";
// Components
import StatusForm from './StatusForm';
// Tools
import PropTypes from 'prop-types';

const StatusDetails = ({
    setShowDetails,
    details,
    catStatus,
    listOrders,
    orderID,
    reloadData
}) => {
    // Global variables
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [sector, setSector] = useState('');
    const [subSector, setSubSector] = useState('');
    const [numCollabs, setNumCollab] = useState(0);
    const [enterpriseName, setEnterpriseName] = useState('');
    // Extract
    const {
        clientName,
        email,
        order,
        paidDate,
        phone
    } = details;
    useEffect(() => {
        for (let x in listOrders) {
            if (listOrders[x].idOrder === orderID) {
                setNumCollab(listOrders[x].numCollabs);
                setEnterpriseName(listOrders[x].enterpriseName);
                getSectorCat().then(response => {
                    for (let y in response) {
                        if (response[y].sector_id === listOrders[x].sectorId) {
                            setSector(response[y].description);
                        }
                    }
                });
                getSubSectorCat(listOrders[x].sectorId).then(response => {
                    for (let y in response) {
                        if (response[y].sub_sector_id === listOrders[x].subSectorId) {
                            setSubSector(response[y].description);
                        }
                    }
                });
                getCountries().then(response => {
                    for (let y in response) {
                        if (response[y].id === listOrders[x].idCountries) {
                            setCountry(response[y].description);
                        }
                    }
                });
                getAddressByCP(listOrders[x].zipCode).then(response => {
                    setState(response[0].nameState);
                    setCity(response[0].nameTown);
                });
            }
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div className="col-12 col-md-7 generic__show-container container-1">
            <div className="card shadow">
                <div className="card-body">
                    <div className="col-12 mb-1">
                        <span
                            className="pointer"
                            onClick={() => setShowDetails(false)}
                        ><i className="bi bi-arrow-left-short"></i> Regresar</span>
                    </div>
                    <div className="col-12 mb-1">
                        <span className="text-pink fw-bold">Datos del comprador</span>
                    </div>
                    <div className="col-12 mb-1">
                        <div className="row">
                            <div className="col-sx-11 col-sm-11 col-md-6 col-lg-6 mx-auto">
                                <p>
                                    <span className="fw-bold">Nombre:</span> {clientName}
                                </p>
                                <p>
                                    <span className="fw-bold">Teléfono:</span> {phone}
                                </p>
                                <p>
                                    <span className="fw-bold">Sector:</span> {sector}
                                </p>
                                <p>
                                    <span className="fw-bold">País:</span> {country}
                                </p>
                                <p>
                                    <span className="fw-bold">Ciudad:</span> {city}
                                </p>
                            </div>
                            <div className="col-sx-11 col-sm-11 col-md-6 col-lg-6 mx-auto">
                                <p>
                                    <span className="fw-bold">Organización:</span> {enterpriseName}
                                </p>
                                <p>
                                    <span className="fw-bold">Correo:</span> {email}
                                </p>
                                <p>
                                    <span className="fw-bold">Núm. de colaboradores:</span> {numCollabs}
                                </p>
                                <p>
                                    <span className="fw-bold">Subsector:</span> {subSector}
                                </p>
                                <p>
                                    <span className="fw-bold">Estado:</span> {state}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-1">
                        <span className="text-pink fw-bold">Datos de la solicitud</span>
                    </div>
                    <div className="col-12 mb-1">
                        <p>
                            <span className="fw-bold">No. de solicitud:</span> {order}
                        </p>
                        <p>
                            <span className="fw-bold">Posible fecha de aplicación:</span> {paidDate}
                        </p>
                    </div>
                    <StatusForm
                        catStatus={catStatus}
                        details={details}
                        orderID={orderID}
                        reloadData={reloadData}
                        setShowDetails={setShowDetails}
                    />
                </div>
            </div>
        </div>
    );
}

StatusDetails.propTypes = {
    setShowDetails: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    catStatus: PropTypes.array.isRequired,
    listOrders: PropTypes.array.isRequired,
    orderID: PropTypes.number.isRequired,
    reloadData: PropTypes.func.isRequired
}

export default StatusDetails;
