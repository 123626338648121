import React, {
    useState,
    useEffect,
} from 'react'
// TODO: API calls
import {
    getResources,
    saveResource,
} from '../../models/resources_model'
// TODO: Components
import Menu from './Menu'
import Table from './Table'
import Form from './Form'
// TODO: redux
import { connect } from 'react-redux'

const RscController = ({ auth }) => {

    const [scoreboard, setScoreboard] = useState({
        fichasCounter: 0,
        postersCounter: 0,
        webinarsCounter: 0,
        infografiaCounter: 0,
    })

    const [showTable, setShowTable] = useState(0)
    const [listFichas, setListFichas] = useState([])
    const [listPosters, setListPosters] = useState([])
    const [listWebinars, setListWebinars] = useState([])
    const [listInfografias, setListInfografias] = useState([])

    const [showDetails, setShowDetails] = useState(false)
    const [resourceObj, setResourceObj] = useState({
        idResource: '',
        idUser: auth.id,
        resourceType: '',
        title: '',
        description: '',
        active: '1',
        serviceType: '',
        fileName: '',
    })
    const [resourceFile, setResourceFile] = useState({})
    const [resourceURL, setresourceURL] = useState('')

    useEffect(() => {
        let fichasColector = []
        let postersColector = []
        let webinarsColector = []
        let infografiasColector = []
        let services = [
            '1,1,1',
            '1,1,2',
            '1,2,0',
        ]
        let flag = 0
        services.forEach(data => {
            let service = data.split(',')
            getResources(service[0], service[1], service[2]).then(response => {
                if (response.code === 1) {
                    const getFichas = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 1) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getFichas.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getFichas.length > 0) {
                        fichasColector.push(...getFichas)
                    }
                    const getPosters = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 2) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getPosters.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getPosters.length > 0) {
                        postersColector.push(...getPosters)
                    }
                    const getWebinars = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 3) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getWebinars.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getWebinars.length > 0) {
                        webinarsColector.push(...getWebinars)
                    }
                    const getInfografias = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 4) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getInfografias.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getInfografias.length > 0) {
                        infografiasColector.push(...getInfografias)
                    }
                    flag = flag + 1
                    if (flag === 3) {
                        setListFichas(fichasColector)
                        setListPosters(postersColector)
                        setListWebinars(webinarsColector)
                        setListInfografias(infografiasColector)
                        setScoreboard({
                            fichasCounter: fichasColector.length,
                            postersCounter: postersColector.length,
                            webinarsCounter: webinarsColector.length,
                            infografiaCounter: infografiasColector.length,
                        })
                    }
                } else {
                    flag = flag + 1
                    if (flag === 3) {
                        setListFichas(fichasColector)
                        setListPosters(postersColector)
                        setListWebinars(webinarsColector)
                        setListInfografias(infografiasColector)
                        setScoreboard({
                            fichasCounter: fichasColector.length,
                            postersCounter: postersColector.length,
                            webinarsCounter: webinarsColector.length,
                            infografiaCounter: infografiasColector.length,
                        })
                    }
                }
            })
        })
    }, [])

    const handleGetResources = () => {
        let fichasColector = []
        let postersColector = []
        let webinarsColector = []
        let infografiasColector = []
        let services = [
            '1,1,1',
            '1,1,2',
            '1,2,0',
        ]
        let flag = 0
        services.forEach(data => {
            let service = data.split(',')
            getResources(service[0], service[1], service[2]).then(response => {
                if (response.code === 1) {
                    const getFichas = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 1) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getFichas.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getFichas.length > 0) {
                        fichasColector.push(...getFichas)
                    }
                    const getPosters = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 2) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getPosters.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getPosters.length > 0) {
                        postersColector.push(...getPosters)
                    }
                    const getWebinars = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 3) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getWebinars.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getWebinars.length > 0) {
                        webinarsColector.push(...getWebinars)
                    }
                    const getInfografias = []
                    response.taResourcesAdminList.forEach(data => {
                        if (data.resourceType === 4) {
                            let product = data.idProduct
                            let category = data.idCategory
                            let subcategory = data.idSubcategory
                            getInfografias.push({
                                ...data,
                                serviceName: `${product}-${category}-${subcategory}` === '1-1-1' ? (
                                    'NOM - Estado Emocional'
                                ) : (
                                    `${product}-${category}-${subcategory}` === '1-1-2' ? (
                                        'NOM - Entorno Laboral'
                                    ) : (
                                        'Clima Laboral'
                                    )
                                )
                            })
                        }
                    })
                    if (getInfografias.length > 0) {
                        infografiasColector.push(...getInfografias)
                    }
                    flag = flag + 1
                    if (flag === 3) {
                        setListFichas(fichasColector)
                        setListPosters(postersColector)
                        setListWebinars(webinarsColector)
                        setListInfografias(infografiasColector)
                        setScoreboard({
                            fichasCounter: fichasColector.length,
                            postersCounter: postersColector.length,
                            webinarsCounter: webinarsColector.length,
                            infografiaCounter: infografiasColector.length,
                        })
                    }
                } else {
                    flag = flag + 1
                    if (flag === 3) {
                        setListFichas(fichasColector)
                        setListPosters(postersColector)
                        setListWebinars(webinarsColector)
                        setListInfografias(infografiasColector)
                        setScoreboard({
                            fichasCounter: fichasColector.length,
                            postersCounter: postersColector.length,
                            webinarsCounter: webinarsColector.length,
                            infografiaCounter: infografiasColector.length,
                        })
                    }
                }
            })
        })
    }

    const handleResetTable = () => {
        handleGetResources()
        setResourceObj({
            ...resourceObj,
            idResource: '',
            resourceType: '',
            title: '',
            description: '',
            active: '1',
            serviceType: '',
            fileName: '',
        })
        setShowDetails(false)
        setresourceURL('')
        setResourceFile({})
    }

    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className='row'>
                        <div className='col'>
                            <h3 className="text-center text-sm-start">Recursos</h3>
                        </div>
                        <div className='col text-end'>
                            {!showDetails && (
                                <span
                                    className='text-pink pointer fs-3'
                                    onClick={() => setShowDetails(true)}
                                ><i className='bi bi-plus-circle-fill' /></span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='card rounded-25-px shadow border-0 generic__show-main-container container-1'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className="col-12 mt-3 mb-5">
                                    <Menu
                                        scoreboard={scoreboard}
                                        showDetails={showDetails}
                                        showTable={showTable}
                                        setShowTable={setShowTable}
                                    />
                                </div>
                                <div className="col-12">
                                    {
                                        showDetails ? (
                                            <Form
                                                resourceObj={resourceObj}
                                                setResourceObj={setResourceObj}
                                                setShowDetails={setShowDetails}
                                                saveResource={saveResource}
                                                resourceFile={resourceFile}
                                                setResourceFile={setResourceFile}
                                                handleResetTable={handleResetTable}
                                                resourceURL={resourceURL}
                                            />
                                        ) : (
                                            <Table
                                                listFichas={listFichas}
                                                listWebinars={listWebinars}
                                                listPosters={listPosters}
                                                listInfografias={listInfografias}
                                                showTable={showTable}
                                                resourceObj={resourceObj}
                                                setResourceObj={setResourceObj}
                                                setShowDetails={setShowDetails}
                                                saveResource={saveResource}
                                                handleResetTable={handleResetTable}
                                                setresourceURL={setresourceURL}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    return {
        auth
    }
}

export default connect(mapStateToProps)(RscController)
