import React from 'react';
import AppRouter from './routers/AppRouter';

const App = () => {

  return (
    <AppRouter />
  )
}

export default App;
