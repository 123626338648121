import axios from 'axios';
/**
 * Get list of organizational group with key of active, pending to be active / active, renovation, list of their organizations and organization info
 * @returns 
 */

export const getListOrgGroupInfo = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url_admin + "orgGroupController/getListOrgGroupInfo",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const saveOrgGroupInfo = async (data) => {
    try {
        let formData = new FormData()
        formData.append('groupEnterpriseGroupId', data.groupEnterpriseGroupId)
        formData.append('groupCommercialName', data.groupCommercialName)
        formData.append('administrationType ', data.administrationType)
        formData.append('listOrganization', JSON.stringify(data.listOrganization))
        formData.append('welcomeEmails', JSON.stringify(data.welcomeEmails))
        formData.append('groupPlans', JSON.stringify(data.groupPlans))
        formData.append('idUser', data.idUser)
        // for (const value of formData.values()) {
        //     console.log(value);
        //   }
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}orgGroupController/saveOrgGroupInfo`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const deleteGroupWelcomeEmail = async (enterpriseGroupId, idWelcomeEmail) => {
    try {
        let formData = new FormData()
        formData.append('enterpriseGroupId', enterpriseGroupId)
        formData.append('idWelcomeEmail', idWelcomeEmail)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}orgGroupController/deleteGroupWelcomeEmail`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const manualSendWelcomeEmail = async (idWelcomeEmail) => {
    try {
        let formData = new FormData()
        formData.append('idWelcomeEmail', idWelcomeEmail)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}orgGroupController/manualSendWelcomeEmail`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}