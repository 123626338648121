import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DualListBox from 'react-dual-listbox';

export const DualList = ({ options, optionsSelected, setReturnSelected }) => {

    //options format
    // const options = [
    //     { value: 'one', label: 'Option One' },
    //     { value: 'two', label: 'Option Two' },
    // ];

    //optionsSelected format
    // const [state, setState] = useState({
    //     selected: ['one']
    // });

    useEffect(() => {
        setState({selected: [...optionsSelected]})
    }, [optionsSelected])

    const [state, setState] = useState({
        selected: []
    });

    const { selected } = state;

    const handleChange = (selected) => {
        setState({selected});
        setReturnSelected(selected);
    }

    return (
        <DualListBox
            options={options}
            selected={selected}
            onChange={ handleChange }
        />
    )
}


DualList.propTypes = {
    options: PropTypes.array.isRequired,
    optionsSelected: PropTypes.array.isRequired
}

