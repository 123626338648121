import React, {
    useState,
    useEffect
} from 'react'
import { useNavigate } from 'react-router-dom'
// TODO: API calls
import {
    getNotifications,
    markNotificationRead,
} from '../../models/notification_model'
// TODO: Generic func
import { handleDateToddmm } from '../../helpers/genericFunc'
// TODO: Tools
import addNotification from 'react-push-notification'

const Notifiacitions = () => {

    const navigate = useNavigate()

    // array
    const [notificationList, setNotificationList] = useState([])
    const [notificationDone, setNotificationDone] = useState(false)

    useEffect(() => {
        if (!notificationDone) {
            getNotifications().then(response => {
                setNotificationList(response.notifications)
                setNotificationDone(true)
                if (sessionStorage.getItem('pushNotification') === null) {
                    sessionStorage.setItem('pushNotification', 0)
                    addNotification({
                        title: 'guay',
                        subtitle: 'Aviso',
                        message: 'Tienes notifiaciones pendientes',
                        theme: 'darkblue',
                        native: true, // when using native, your OS will handle theming.
                        duration: 10000,
                    })
                }
            })
        } else {
            setTimeout(() => {
                getNotifications().then(response => {
                    setNotificationList(response.notifications)
                })
            }, 30000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationList])

    const handleClickNotification = (enterpriseId, notificationId) => {
        markNotificationRead(notificationId).then(response => {
            if (response.code === 1) {
                getNotifications().then(response_2 => {
                    setNotificationList(response_2.notifications)
                    sessionStorage.setItem('showEnterpriseNotification', enterpriseId)
                    if (window.location.pathname === '/groups-enterprises') {
                        window.location.reload()
                    } else {
                        navigate('/groups-enterprises')
                    }
                })
            }
        })
    }

    const handleTextNotifications = (
        typeNotification,
        commercialName,
        dateRecord,
    ) => {
        let text
        switch (typeNotification) {
            case 1:
                text = <span>Se dió de alta la organización: <span className='fw-bold'>{commercialName}</span> desde el {handleDateToddmm(dateRecord)}</span>
                break;
            case 2:
                text = <span>En la organización <span className='fw-bold'>{commercialName}</span> existen usuarios sin abrir correo desde {handleDateToddmm(dateRecord)}</span>
                break;
            default:
                break;
        }
        return text
    }

    return (
        <div className="dropdown me-3">
            <img
                src={`./assets/img/icons/${notificationList.length === 0 ? 'icono_notificacion_blanco_inactivo.png' : 'Icono_notificación_blanco_activo.png'}`}
                className={`icon-size-20x18 pointer ${notificationList.length > 0 && 'jumping-2'}`}
                alt='notifications'
                data-bs-toggle="dropdown"
            />
            <div className="dropdown-menu div-notifications rounded-3">
                <div className='col-11 mx-auto'>
                    <div className='row'>
                        <div className='col-10 mx-auto border-bottom mb-3 border-dark pb-2'>
                            <span className='fw-bold fs-1em'>Notificaciones</span>
                        </div>
                        {notificationList.map(data => (
                            <div
                                key={`notification-${data.idAdmNotification}-${data.idEnterpriseRecord}`}
                                className='col-12 mb-1 pointer notification-tab px-4'
                                onClick={() => handleClickNotification(data.idEnterpriseRecord, data.idAdmNotification)}
                            >
                                {handleTextNotifications(data.idTypeNotification, data.enterpriseCommercialName, data.dateRecord)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifiacitions