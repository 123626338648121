import React, { useState } from 'react';

export const BenefitsList = ({
    listBenefits,
    setBenefitForm,
    handleSetBenefits,
}) => {
    const [showMessage, setShowMessage] = useState(false)
    const handleClickEditBenefit = (benefit) => {
        let arr = []
        if (benefit.listPlanPromotion !== undefined) {
            for (let x in benefit.listPlanPromotion) {
                arr.push({ idPlanPromotion: benefit.listPlanPromotion[x].relBenefitPlanPromotionPK.idPlanPromotion })
            }
            handleSetBenefits(benefit.idBenefit, arr);
        } else {
            handleSetBenefits(benefit.idBenefit, []);
        }
        setBenefitForm({
            inEdition: true,
            idBenefit: benefit.idBenefit,
            title: benefit.title,
            nameEnterprise: benefit.nameEnterprise,
            description: benefit.description,
            active: benefit.active,
            urlEnterprise: benefit.urlEnterprise,
            urlLogo: benefit.urlLogo || '../assets/img/sin_logo.png',
            urlResource: benefit.urlResource || '../assets/img/sin_logo.png',
            phone: benefit.phone,
            whatsapp: benefit.whatsapp,
            urlContact: benefit.urlContact,
            email: benefit.email,
            urlManual: benefit.digitalManual || '',
            urlDigdesc: benefit.digitalDescription || '',
            flgGral: benefit.flgGral,
            urlService: benefit.urlService,
        });
    }
    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById("listBenefits");
        li = ul.getElementsByClassName('benefit-card');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toLowerCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                records = records = 1;
            } else {
                li[i].style.display = "none";
            }
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }
    return (
        <div className="row">
            <div className='col-12'>
                <div className='card shadow border-0 rounded-25-px generic__show-main-container container-1'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2">
                                <div className='row d-flex justify-content-end'>
                                    <div className="col-sm-12 col-lg-5 col-xxl-4">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Buscar"
                                                onChange={sortArray}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 generic__show-container left container-1'>
                                <div className='row' id='listBenefits'>
                                    {showMessage && <h2 className='text-center p-3'>No hay resultados</h2>}
                                    {
                                        listBenefits.map(data => (
                                            <div
                                                key={data.idBenefit}
                                                className='col-sm-11 col-lg-6 col-xxl-4 mb-3 benefit-card'
                                            >
                                                <div
                                                    className='card m-0 h-100 border-0 shadow'
                                                    onClick={() => handleClickEditBenefit(data)}
                                                >
                                                    <div className='card-body pe-0 pb-0' id=''>
                                                        <div className='row h-100'>
                                                            <div className='col-sm-12 col-md-6 col-lg-6 d-flex flex-wrap align-content-between pb-3'>
                                                                <span className='invisible' style={{ position: 'absolute' }}>{data.nameEnterprise}</span>
                                                                <div className='col-12 mb-3 d-flex justify-content-center'>
                                                                    {
                                                                        data.urlLogo === '' || data.urlLogo === undefined ? (
                                                                            <h2>{data.nameEnterprise}</h2>
                                                                        ) : (
                                                                            <img
                                                                                src={data.urlLogo}
                                                                                alt='benefit'
                                                                                style={{
                                                                                    height: '50px',
                                                                                    maxWidth: '95%',
                                                                                    /* width: 'auto', */
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className='col-12'>
                                                                    <p className='text-justify fs-7'>{data.description}</p>
                                                                </div>
                                                                <div className='col-12 d-flex justify-content-center'>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm bg-blue-navy text-white w-75"
                                                                    >Editar</button>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end flex-wrap align-content-end'>
                                                                {
                                                                    data.urlResource === "" || data.urlResource === undefined ? (
                                                                        <img
                                                                            className='img-responsive'
                                                                            src="./../img/altaEmpresas/iconos_shopy_img-31.png"
                                                                            alt='benefit'
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            style={{ width: '90%' }}
                                                                            src={data.urlResource}
                                                                            alt='benefit'
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
