import React, { useState } from 'react';
// API calls
import { updStatusOrderFree } from '../../../../models/shop_model';
// Tools
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const StatusForm = ({
    catStatus,
    details,
    orderID,
    reloadData,
    setShowDetails,
    auth
}) => {
    const [applicationStatus, setApplicationStatus] = useState({
        idOrder: orderID,
        idStatus: details.status,
        comments: details.comments ? (details.comments) : (''),
        idUser: auth.id
    });
    // Extract
    const {
        comments,
        idStatus
    } = applicationStatus;
    return (
        <div className="col-12">
            <form
                onSubmit={e => {
                    e.preventDefault();
                    if (idStatus !== details.status) {
                        updStatusOrderFree(applicationStatus).then(response => {
                            if (response) {
                                switch (response.code) {
                                    case 1:
                                        toastr.success("La solicitud se envió correctamente", '¡Éxito!');
                                        reloadData();
                                        setShowDetails(false);
                                        break;
                                    default:
                                        toastr.error("Problemas en el envío. Solicitar ayuda técnica.", '¡Ooops!');
                                        break;
                                }
                            } else {
                                toastr.error("Problemas al comunicarse con el servidor. Intentar de nuevo más tarde.", '¡Ooops!');
                            }
                        });
                    }
                }}
            >
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Estatus</label>
                            <select
                                className="form-control form-control-sm"
                                id="idStatus"
                                name="idStatus"
                                value={idStatus}
                                onChange={e => {
                                    setApplicationStatus({
                                        ...applicationStatus,
                                        [e.target.name]: parseInt(e.target.value)
                                    });
                                }}
                                disabled={details.status === 6 || details.status === 7 ? (true) : (false)}
                            >
                                {
                                    catStatus.map(data => (
                                        <option
                                            key={data.idStatus}
                                            value={data.idStatus}
                                        >{data.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Comentarios</label>
                            <textarea
                                className="form-control textarea"
                                rows="3"
                                id="comments"
                                name="comments"
                                value={comments}
                                onChange={e => {
                                    setApplicationStatus({
                                        ...applicationStatus,
                                        [e.target.name]: e.target.value
                                    });
                                }}
                                disabled={details.status === 6 || details.status === 7 ? (true) : (false)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                {
                    details.status === 6 || details.status === 7 ? (
                        null
                    ) :
                        (
                            <button
                                type="submit"
                                className="btn btn-sm btn-pink-guay text-white float-end mt-3"
                            >Enviar</button>
                        )
                }
            </form>
        </div>
    );
}

StatusForm.propTypes = {
    catStatus: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    orderID: PropTypes.number.isRequired,
    reloadData: PropTypes.func.isRequired
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}

export default connect(mapStateToProps)(StatusForm);
