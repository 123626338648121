import axios from 'axios'
/**
 * 
 * @returns 
 */
export const getListOrganizationInfo = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}OrganizationController/getListOrganizationInfo`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const saveOrganizationInfo = async (data) => {
    try {
        let formData = new FormData()
        formData.append('idEnterprise', data.idEnterprise)
        formData.append('commercialName', data.commercialName)
        formData.append('numCollabs', data.collaborators === '' ? 0 : data.collaborators)
        formData.append('productEnterprise', JSON.stringify(data.productEnterprise))
        formData.append('benefitEnterprise', JSON.stringify(data.benefitEnterprise))
        formData.append('planEnterprise', JSON.stringify(data.planEnterprise))
        formData.append('welcomeEmails', JSON.stringify(data.welcomeEmails))
        formData.append('idUser', data.idUser)
        formData.append('idGroup', data.idGroup)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}OrganizationController/saveOrganizationInfo`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {number} idEnterprise 
 * @param {number} idWelcomeEmail 
 * @returns 
 */
export const deleteEnterpriseWelcomeEmail = async (idEnterprise, idWelcomeEmail) => {
    try {
        let formData = new FormData()
        formData.append('idEnterprise', idEnterprise)
        formData.append('idWelcomeEmail', idWelcomeEmail)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}OrganizationController/deleteEnterpriseWelcomeEmail`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {number} idWelcomeEmail 
 * @returns 
 */
export const manualSendWelcomeEmail = async (idWelcomeEmail) => {
    try {
        let formData = new FormData()
        formData.append('idWelcomeEmail', idWelcomeEmail)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}OrganizationController/manualSendWelcomeEmail`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {number} idEnterprise 
 * @returns 
 */
export const deleteEnterprise = async (idEnterprise) => {
    try {
        let formData = new FormData()
        formData.append('idEnterprise', idEnterprise)
        let result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}OrganizationController/deleteEnterprise`,
            headers: {
                Authorization: global.tokenAuth,
            },
            data: formData,
        })
        return (result.data ? result.data : false)
    } catch (error) {
        console.log(error)
    }
}
