import React from 'react'
import { handleStatusLabel,handleMargintColorCard,handleidle,handleDateToddmmmyyyy } from '../../../helpers/genericFunc'

export const EnterpriseCard = ({
    enterpriseData,
    setEnterpriseObj,
    handleGroupServices,
    setShowForm,
    setHideHeader,
    idGroup,
    groupName,
    setGroupInfo,
    setGroupContactsList,
    contactsList,
    setEntRegisterDate,
    totalEnterprisesGroup,
    handleDeleteCard
    }) => {

        const {
            activeCollaborators,
            color,
            idEnterprise,
            logo,
            commercialName,
            registerDate,
            productEnterprise,
            status,
        } = enterpriseData

  return (
    <div
        className='col-2 mx-3'
        key={`group-${idGroup}-enterprise-${idEnterprise}`}
    >
         <div className={`card rounded-10px shadow-sm py-3 h-100 pointer w-75 ${handleMargintColorCard(color)} ${handleidle(color, status)}`} 
         >
            {totalEnterprisesGroup > 1 && 
            <div className='col-12'>
                <i
                    className='bi bi-trash text-danger'
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '5px',
                        zIndex: '100',
                    }}
                    onClick={() => handleDeleteCard(idEnterprise,commercialName,activeCollaborators)}
                />
            </div>
            }
            <div className='card-body generic__show-container top container-2'
                onClick={() => {
                    setEnterpriseObj({
                        ...enterpriseData,
                        registerDate: new Date(registerDate),
                        productEnterprise:productEnterprise.length > 0 ? handleGroupServices(productEnterprise) : productEnterprise
                    })
                    setShowForm(true)
                    setHideHeader(true)
                    setGroupInfo({
                        idgroup:idGroup,
                        groupLabel:groupName,
                        isFromGroupForm: true,
                    })
                    setGroupContactsList(contactsList)
                    setEntRegisterDate(new Date(registerDate))
                }}
            >
                <div className='row h-100'>
                    <div className='col-12 mb-1 d-flex justify-content-center h-1of3'>
                        <img
                            src={logo === '' || logo === null ? './assets/img/icons/icono_logo_empresa.png' : logo}
                            // className='img-h30px img-fluid'
                            style={{
                                width: '85%',
                                aspectRatio: '3/2',
                                objectFit: 'contain',
                            }}
                            alt='logo'
                        />
                    </div>
                    <div className='col-12 h-1of3 mb-2'>
                        <div className='row d-flex'>
                            <div className='col-12 mb-1'>
                                <p
                                    className='fs-075em fw-bold text-blue font-family-quicksand text-center m-0 overflow-ellipsis'
                                    data-bs-toggle="tooltip"
                                    title={commercialName}
                                >{commercialName}</p>
                            </div>
                            <div className='col-12'>
                                <div className='row d-flex'>
                                    <div className='col-6 d-flex justify-content-center flex-wrap align-content-center'>
                                        <img
                                            src='./assets/img/icons/Icono_colaboradores_Activo.png'
                                            className='icon-size-11x10'
                                            alt='icon'
                                        />
                                    </div>
                                    <div className='col-6 d-flex justify-content-center flex-wrap align-content-center'>
                                        <span className='fs-075em fw-bold text-blue font-family-quicksand'>{activeCollaborators}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-12 h-1of3'>
                        <div className='row d-flex'>
                            <div className='col-12 mb-1 d-flex justify-content-center'>
                                <span className='fs-0625em text-ad-grey-white-guay font-family-quicksand'>{handleStatusLabel(status)}</span>
                            </div>
                            <div className='col-12 mb-1 d-flex justify-content-center text-blue font-family-quicksand fw-semibold'>
                                <span>{handleDateToddmmmyyyy(registerDate)}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
