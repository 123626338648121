import React from 'react'

export const Footer = () => {
    const handleYear = () => {
        const date = new Date()
        return date.getFullYear()
    }
    return (
        <div className='col-12 bg-white py-2 text-blue-sec footer__footer-style footer__box-shadow fs-7'>
            <div className='row'>
                <div className='col-sm-12 col-lg-11 col-xxl-11 d-flex justify-content-center flex-wrap align-content-center'>
                    <span className='fw-lighter $ad-blue-dark-guay font-family-quicksand fs-05625'>Copyright © {handleYear()} guay</span>
                </div>
                <div className='col-sm-12 col-lg-1 col-xxl-1 d-flex justify-content-end'>
                    <b className='text-yellow'>
                        <span className='font-family-quicksand text-blue fs-05625 fw-bold'>Siguenos:</span><br />
                        {/* <span className="mx-3">&nbsp;&nbsp;</span> */}
                        <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer" style={{ visibility: 'hidden' }}>
                            <img
                                src='./assets/img/icons/icono_instagram_activo.png'
                                className='icon-size-18x18 mx-1'
                                alt='ig'
                            />
                        </a>
                        <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                            <img
                                src='./assets/img/icons/icono_instagram_activo.png'
                                className='icon-size-18x18 mx-1'
                                alt='ig'
                            />
                        </a>
                        <a href="https://www.facebook.com/guay-107436667630740" target="_blank" rel="noopener noreferrer">
                            <img
                                src='./assets/img/icons/icono_facebook_activo.png'
                                className='icon-size-18x18 mx-1'
                                alt='ig'
                            />
                        </a>
                    </b>
                </div>
            </div>
        </div>
    )
}