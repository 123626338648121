import React, { useState } from 'react';
// API calls
import { getSubSectorCat } from '../../models/catalog_model';
import { getEnterpriseByID } from '../../models/enterprise_model';
// Tools
import PropTypes from 'prop-types';

const OrgList = ({
    organizations,
    setEntProfile,
    setSection,
    setSubSectors,
}) => {
    const [showMessage, setShowMessage] = useState(false)
    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById("organizationList");
        li = ul.getElementsByTagName('li');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toLowerCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                records = records = 1;
            } else {
                li[i].style.display = "none";
            }
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }
    return (
        <div className='card border-0 shadow rounded-25-px py-3 generic__show-main-container container-1'>
            <div className='card-body'>
                <div className="col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2">
                    <div className='row d-flex justify-content-end'>
                        <div className="col-sm-12 col-lg-5 col-xxl-4">
                            <div className="input-group input-group-guay mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar"
                                    onChange={sortArray}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 generic__show-container container-1' id="organizationList">
                    {showMessage && <h2 className='text-center p-3'>No hay resultados</h2>}
                    {
                        organizations.map(data => (
                            <li
                                key={data.id}
                                className="col-sm-11 col-lg-11 col-xxl-12 mx-auto shadow p-3 rounded presence__show-container container-1 pull-up pointer list-group-item"
                                onClick={e => {
                                    e.preventDefault();
                                    getEnterpriseByID(data.id).then(response => {
                                        if (response) {
                                            getSubSectorCat(response.sector).then(response => {
                                                if (response) {
                                                    setSubSectors(response);
                                                }
                                            });
                                            setEntProfile({
                                                comercial: response.comercial,
                                                short: response.short,
                                                email: response.email,
                                                sector: response.sector,
                                                subSector: response.subSector,
                                                country: response.country,
                                                anio: response.anio,
                                                collaborators: response.collaborators,
                                                zip: response.listInfoZip[0].zip,
                                                idState: response.listInfoZip[0].idState,
                                                idTown: response.listInfoZip[0].idTown,
                                                idNeighborhood: response.listInfoZip[0].idNeighborhood,
                                                street: response.listInfoZip[0].street,
                                                numExt: response.listInfoZip[0].numExt,
                                                numInt: response.listInfoZip[0].numInt,
                                            });
                                            setSection(2);
                                        }
                                    });
                                }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span className="fw-bold">{data.description}</span>
                                    </div>
                                    <i className="bi bi-eye float-end" />
                                </div>
                            </li>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

OrgList.propTypes = {
    organizations: PropTypes.array.isRequired,
    setEntProfile: PropTypes.func.isRequired,
    setSection: PropTypes.func.isRequired,
    setSubSectors: PropTypes.func.isRequired,
}

export default OrgList;
