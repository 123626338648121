
/**
 * Format text day de month de year. Eg. 1 de enero de 2023
 * @param {string} date 
 * @returns 
 */
export const handleDateTextFormat = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()
    const year = today.getFullYear()
    const textMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    const getTextMonth = textMonths[month]
    return `${day} de ${getTextMonth} de ${year}`
}

/**
 * Format text day/month/year. Eg. 1/ene/2023
 * @param {string} date 
 * @returns 
 */
export const handleDateToddmmmyyyy = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()
    const year = today.getFullYear()
    const textMonths = ['ene', 'feb', 'mar', 'abr', 'mayo', 'jun', 'jul', 'agos', 'sept', 'oct', 'nov', 'dic']
    const getTextMonth = textMonths[month]
    return `${day}/${getTextMonth}/${year}`
}
/**
 * Format text year-month-day. Eg. 2023/01/01
 * @param {string} date 
 * @returns 
 */
export const handleDateToyyyymmdd = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}
/**
 * Format text year-month-day. Eg. 01/01/2023
 * @param {string} date 
 * @returns 
 */
export const handleDateToddmmyyyy = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`
}
/**
 * Format text dat de month. Eg. 01 de febrero
 * @param {string} date 
 * @returns 
 */
export const handleDateToddmm = date => {
    const today = new Date(date)
    const day = today.getDate()
    const month = today.getMonth()
    const textMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    const getTextMonth = textMonths[month]
    return `${day} de ${getTextMonth}`
}

/**
 * status text Eg. 2 - en progreso
 * @param {int} status 
 * @returns 
 */

export const handleStatusLabel = status => {
    //estatus: se manejaran 3 estados: activo = 1, en proceso = 2 y inactivo = 0
    let statusLabel = ''
    switch (status) {
        case 2:
            statusLabel = 'En Progreso'
            break;
        case 1:
            statusLabel = 'Activa'
            break;
        case 0:
            statusLabel = 'Inactivo'
            break;
        default:
            break;
    }
    return statusLabel
}

/**
 * border card class  Eg. 99 - card-border-sec-light-blue-guay
 * @param {int} color 
 * @returns 
 */

export const handleMargintColorCard = color => {
    //color:  azul = 99, magenta = 2, gris = 0, blanco = 1
    let statusClass = ''
    switch (color) {
        case 99:
            statusClass = 'card-border-sec-light-blue-guay'
            break;
        case 2:
            statusClass = 'card-border-magenta'
            break;
        case 0:
            statusClass = 'card-border-sec-white-gray-guay'
            break;
        case 1:
            statusClass = 'card-border-white'
            break;
        default:
            break;
    }
    return statusClass

}

/**
 * idle card class  Eg. status and color === 0
 * @param {int} color 
 * @returns 
 */

export const handleidle = (status, color) => {
    let bgStatus = ''
    if (status === 0 && color === 0) {
        bgStatus = 'card-bg-idle'
    }
    return bgStatus
}

export const handleScroll = (selector) => {
    // element which needs to be scrolled to
    const element = document.getElementById(selector);
    if (document.getElementById(selector) !== null) {
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }
}