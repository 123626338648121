import React, { forwardRef, useRef } from 'react'
// TODO: Generic func
import { handleDateToddmmyyyy } from '../../../helpers/genericFunc'
// TODO: Tools
import Datepicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es'

registerLocale('es', es)
const GroupBenefitsForm = ({data,currentIndex,planList,handleOnChangeBenefitPlan,handleDeleteBenefitPlan, totalRegister}) => {
    const inputRef = useRef(null);
    const { idPlanEnterprise, idPlan, numPolicy, onDate } = data

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form input-img'>
            <input
                type='text'
                className='form__input w-auto fs-0875em'
                autoComplete='off'
                placeholder=' '
                onClick={onClick}
                ref={ref}
                value={value === '' ? '' : handleDateToddmmyyyy(value)}
                id={`group-onDate${currentIndex}`}
                name="onDate"
            />
            <label className='form__label'>
                Fecha
            </label>
        </div>
    ))
  return (
    <>
        <div className='col-11 mt-3 justify-content-center'>
            <div className='row'>
                <div className='col-lg-8 col-xl-8 col-xxl-8 px-1'>
                    <div className='form'>
                        <select
                            className="form__input fs-075em px-0"
                            id={`group-idPlan${currentIndex}`}
                            name="idPlan"
                            value={idPlan}
                            onChange={(e) => handleOnChangeBenefitPlan(e, currentIndex,false)}
                        > 
                            <option value={''}> </option>
                            {
                                planList.length > 0 && (
                                    planList.map(data => (
                                        <option value={data.idPlan} data-bs-toggle="tooltip" 
                                        title={data.description}>{data.codePlan} - {data.title}</option>
                                    ))
                                )
                            }
                           
                        </select>
                        <label className='form__label'>
                            Plan
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2'>
                    <div className='form'>
                        <input
                            type='number'
                            className='form__input fs-075em px-0'
                            placeholder=' '
                            autoComplete='off'
                            id={`group-numPolicy${currentIndex}`}
                            name="numPolicy"
                            maxLength="200"
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                            value={numPolicy}
                            data-bs-toggle="tooltip" 
                            title={numPolicy}
                            onChange={(e) => handleOnChangeBenefitPlan(e, currentIndex,false)}
                        />
                        <label className='form__label'>
                            No. de Licencias
                        </label>
                    </div>
                </div>
                <div className='col-lg-2 col-xl-2 col-xxl-2 px-0'>
                    <Datepicker
                        onChange={date => {
                            handleOnChangeBenefitPlan(date, currentIndex, true)
                        }}
                        selected={onDate !== '' ? new Date(onDate) : ''}
                        customInput={<ExampleCustomInput inputRef={inputRef} />}
                        minDate={new Date()}
                        locale="es"
                        id={`group-onDate${currentIndex}`}
                        name="onDate"
                        className="form-control"
                        />
                </div>
            </div>
        </div>
        <div className='col-1 d-flex justify-content-start flex-wrap align-content-center px-0 mx-0'>
            {
                (currentIndex + 1) < totalRegister && idPlanEnterprise === '' &&(
                    <img
                        src="../assets/img/icons/Icono_eliminar_activo.png"
                        className="mx-0 icon-size-16x15 pointer"
                        alt="img-trash"
                        onClick={() => handleDeleteBenefitPlan(currentIndex,idPlanEnterprise)}
                    />
                )
            }
        </div>
    </> 
    )
}

export default GroupBenefitsForm
