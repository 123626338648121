import React from 'react';
// Tools
import PropTypes from 'prop-types';

const SponsorProfile = ({
    sponsorProfile,
    sectors,
    subSectors,
    setSection,
}) => {
    const {
        logo,
        register_date,
        dateOfRenovation,
        organization,
        sector,
        subSector,
        webPage,
        facebook,
        instagram,
        twitter,
        linkedin,
    } = sponsorProfile;
    return (
        <div className="col-12">
            <div className="card shadow border-0 rounded-25-px generic__show-main-container container-1">
                <div className="card-body">
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                    >
                        <div className='row mb-3'>
                            <div className='col-sm-12 col-lg-6 col-xxl-6 d-flex justify-content-center generic__show-container container-2'>
                                {
                                    logo === undefined || logo === "undefined" ? (
                                        null
                                    ) : (
                                        <img
                                            className='w-50'
                                            src={logo}
                                            alt='logo'
                                        />
                                    )
                                }
                            </div>
                            <div className='col-sm-12 col-lg-6 col-xxl-6 d-flex flex-wrap align-content-center generic__show-container top container-2'>
                                <div className='col-12'>
                                    <h6><span className='fw-bold'>Miembro desde:</span> {register_date}</h6>
                                </div>
                                <div className='col-12'>
                                    <h6><span className='fw-bold'>Fecha de renovación:</span> {dateOfRenovation}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container left container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text w-35 fw-bold">Empresa / Marca</span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        maxLength="150"
                                        id="organization"
                                        name="organization"
                                        value={organization}
                                    />
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text w-35 fw-bold">Sector</span>
                                    <select
                                        className="form-select bg-white"
                                        disabled
                                        id="sector"
                                        name="sector"
                                        value={sector}
                                    >
                                        {
                                            sectors.map(data => (
                                                <option key={"sector-" + data.sector_id}>{data.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text w-35 fw-bold">Subsector</span>
                                    <select
                                        className="form-select bg-white"
                                        disabled
                                        id="subSector"
                                        name="subSector"
                                        value={subSector}
                                    >
                                        {
                                            subSectors.map(data => (
                                                <option key={"subSector-" + data.sub_sector_id}>{data.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text w-35 fw-bold">Página web</span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        id="webPage"
                                        name="webPage"
                                        value={webPage}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container rigth container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold"><i className='bi bi-facebook' /></span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        maxLength="150"
                                        id="facebook"
                                        name="facebook"
                                        value={facebook}
                                    />
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold"><i className='bi bi-instagram' /></span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        maxLength="150"
                                        id="instagram"
                                        name="instagram"
                                        value={instagram}
                                    />
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold"><i className='bi bi-twitter' /></span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        maxLength="150"
                                        id="twitter"
                                        name="twitter"
                                        value={twitter}
                                    />
                                </div>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold"><i className='bi bi-linkedin' /></span>
                                    <input
                                        type="text"
                                        className="form-control bg-white"
                                        disabled
                                        maxLength="150"
                                        id="linkedin"
                                        name="linkedin"
                                        value={linkedin}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 generic__show-container container-1'>
                            <button
                                type='button'
                                className='btn btn-sm bg-blue-navy text-white'
                                onClick={() => setSection(1)}
                            >Regresar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

SponsorProfile.propTypes = {
    sponsorProfile: PropTypes.array.isRequired,
    sectors: PropTypes.array.isRequired,
    subSectors: PropTypes.array.isRequired,
    setSection: PropTypes.func.isRequired,
}

export default SponsorProfile;
