import React, {
    useState,
    useEffect,
} from 'react'
// TODO: API calls
import { getBenefits } from '../../../models/benefits_model'
import { getAllPlans } from '../../../models/plan_model'
// TODO: Components
import Form from './Form'
import Panel from './Panel'
// TODO: Generic Functions
import { handleScroll } from '../../../helpers/genericFunc'
// TODO: Tools
import * as toastr from 'toastr'

const Controller = () => {

    const initialPlan = {
        id: 0,
        code: '',
        title: '',
        description: '',
        active: 1,
    }

    const [showForm, setShowForm] = useState(false)
    const [columns, setColumns] = useState({})
    const [plan, setPlan] = useState(initialPlan)
    const [plans, setPlans] = useState([])
    const [benefits, setBenefits] = useState([])

    useEffect(() => {
        getAllPlans().then(response => {
            if (response.validation) {
                //console.log(response.data)
                setPlans(response.data.plans)
            }
        })
    }, [showForm])

    useEffect(() => {
        getBenefits().then(response => {
            switch (response?.code) {
                case 1:
                    setBenefits(handleDragDropArr(response.guayBenefits))
                    break
                case 1001:
                    toastr.info("No se encontraron beneficios activos", "1001")
                    break
                default:
                    toastr.error("Ocurrió un error inesperado")
                    break
            }
        })
    }, [])

    const handleDragDropArr = arr => {
        let newArr = []
        for (let x in arr) {
            newArr.push({ idBenefit: arr[x].idBenefit.toString(), title: arr[x].title })
        }
        return newArr
    }

    const resetForm = () => {
        setPlan(initialPlan)
        setShowForm(false)
        setColumns({})
        handleScroll('top-page')
    }

    const handleSetBenefits = (id, arr) => {
        if (id === 0) {
            const columnsFromBackend = {
                1: {
                    name: "Beneficios",
                    items: benefits
                },
                2: {
                    name: "Beneficios del plan",
                    items: []
                }
            }
            setColumns(columnsFromBackend)
        } else {
            const getSelected = handleDragDropArr(arr)
            //console.log(getSelected)

            const getSelectedId = getSelected.map(element => element.idBenefit)
            //console.log(getSelectedId)
            const getUnselectedId = benefits.map(element => element.idBenefit)
            //console.log(getUnselectedId)
            const difference = getUnselectedId.filter(x => getSelectedId.indexOf(x) === -1)
            //console.log(difference)
            const getUnselected = benefits.filter(x => difference.indexOf(x.idBenefit) !== -1)
            //console.log(getUnselected)
            const columnsFromBackend = {
                1: {
                    name: "Beneficios",
                    items: getUnselected,
                },
                2: {
                    name: "Beneficios del plan",
                    items: getSelected,
                }
            }
            setColumns(columnsFromBackend)
        }
    }

    const handleOnClick = obj => {
        //console.log(obj)
        handleSetBenefits(obj.idPlan, obj.relPlanBenefitList)
        setPlan({
            id: obj.idPlan,
            code: obj.codePlan,
            title: obj.title,
            description: obj.description,
            active: 1,
        })
        setShowForm(true)
    }

    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className='row'>
                        <div className='col'>
                            <h3 className="text-center text-sm-start">Planes - Beneficios</h3>
                        </div>
                        <div className='col text-end'>
                            <span
                                className='text-pink pointer fs-3'
                                onClick={() => {
                                    handleSetBenefits(0, [])
                                    setShowForm(true)
                                }}
                            ><i className='bi bi-plus-circle-fill' /></span>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    {showForm ? (
                        <Form
                            // TODO: Hooks
                            plan={plan}
                            setPlan={setPlan}
                            columns={columns}
                            setColumns={setColumns}
                            // TODO: Functions
                            resetForm={resetForm}
                            handleScroll={handleScroll}
                        />
                    ) : (
                        <Panel
                            // TODO: Hooks
                            plans={plans}
                            setPlan={setPlan}
                            setShowForm={setShowForm}
                            // TODO: Functions
                            handleSetBenefits={handleSetBenefits}
                            handleOnClick={handleOnClick}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Controller