import { login } from "../models/sprUser_model";

//const
const initalState = {
    logged: false,
    fetching: false
};

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

const LOGOUT = "LOGOUT";

//reducer
const authReducer = (state = initalState, action) => {

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                fetching: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                logged: true,
                error: false,
                ...action.payload,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }

        case LOGOUT:
            return {
                ...initalState
            }

        default:
            return state;
    }
}

export default authReducer;

//auxiliar
const saveStorage = function (auth) {
    sessionStorage.setItem('auth', btoa(JSON.stringify(auth)));
}

const removeStorage = function () {
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem("organizations");
    sessionStorage.removeItem("pushNotification");
}

//actions
export const restoreSessionAction = () => (dispatch) => {
    if (sessionStorage.getItem('auth') !== null) {
        const auth = JSON.parse(atob(sessionStorage.getItem('auth')));
        if (auth && auth.id) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: auth
            })
        }
    }
}

export const doLoginAction = (email, password) => (dispatch, getState) => {
    dispatch({
        type: LOGIN
    })
    login(email, password).then((response) => {
        switch (response?.code) {
            case 1:
                if (response.isAdmin) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            ...response
                        }
                    })
                    saveStorage(getState().auth)
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            message: "El usuario no es administrador"
                        }
                    })
                }
                break;
            case 1000:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: response.message
                    }
                })
                break;
            case 1001:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: response.message
                    }
                })
                break;
            default:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: "Ocurrió un error al conectar al servidor"
                    }
                })
                break;
        }
    });
}

export const doLogOutAction = () => (dispatch, _getState) => {
    dispatch({
        type: LOGOUT
    });
    removeStorage();
}