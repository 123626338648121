import React from 'react'

const GroupHeader = ({generalData,groupNameLabel}) => {
    const {
        registerDate,
    } = generalData
  return (
    <div className='card rounded-10px border-0 mb-3'>
        <div className='card-body py-1'>
            <div className='row'>
                <div className='col-4'>
                    <div className='row'>
                        <div className='col-2'>
                            <img
                                src={'./assets/img/icons/icono_logo_empresa.png'}
                                alt='logo guay'
                                className='img-h30px img-fluid mx-3 my-1'
                            />  
                        </div>
                        <div className='col-10'>
                            <span className='fs-1-5em fw-bold text-blue font-family-quicksand'>
                                <p  
                                    className='overflow-ellipsis my-0' 
                                    data-bs-toggle="tooltip" 
                                    title={groupNameLabel}>
                                    {groupNameLabel}
                                </p>
                            </span>
                        </div>

                    </div>
                </div>
                <div className='col-4 d-flex justify-content-center flex-wrap align-content-center'>
                    <span className='fs-0875em text-blue font-family-quicksand'><span className='fw-bold text-blue fs-0875em font-family-quicksand'>Desde:</span> {registerDate}</span>
                </div>
                <div className='col-4 d-flex justify-content-end flex-wrap align-content-center'>
                    <img
                        src='./assets/img/icons/iconos_nuevaorganizacion_azul_activo.png'
                        alt='org-guay'
                        className='icon-size-27x27 img-fluid mx-3 my-1'
                    />  
                    <span className='fs-075em fw-semibold text-blue font-family-quicksand'>Nuevo <br/> Grupo</span>
                </div>
            </div>
        </div>
    </div>
  )
}
export default GroupHeader
