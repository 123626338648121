import axios from 'axios';
// TODO: Tools
import * as toastr from 'toastr'

/**
 * Metodo para obtener el listado de beneficios
 * @returns JSON
 */
export const getBenefits = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "benefits/getBenefits",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Metodo para agregar o actualizar informacion de los beneficios
 * @param {request object} data 
 * @returns 
 */
export const setUpdateBenefits = async (data, logo, archivo, manual, digdesc, plans) => {
    try {
        let formData = new FormData();
        formData.append("request", `{
            "idBenefit": ${data.idBenefit},
            "urlEnterprise": ${JSON.stringify(data.urlEnterprise)},
            "title": ${JSON.stringify(data.title)},
            "nameEnterprise": ${JSON.stringify(data.nameEnterprise)},
            "description": ${JSON.stringify(data.description)},
            "active": ${JSON.stringify(data.active)},
            "phone": ${JSON.stringify(data.phone)},
            "whatsapp": ${JSON.stringify(data.whatsapp)},
            "urlcontact": ${JSON.stringify(data.urlContact)},
            "email": ${JSON.stringify(data.email)},
            "flgGral": ${JSON.stringify(data.flgGral)},
            "urlService": ${JSON.stringify(data.urlService)}
        }`);
        formData.append("logo", logo);
        formData.append("file", archivo);
        formData.append("manual", manual);
        formData.append("digdesc", digdesc);
        formData.append("planPromotion", JSON.stringify(plans));
        let result = await axios({
            method: 'POST',
            url: global.base_url + "benefits/setUpdateBenefits",
            data: formData,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @returns 
 */
export const getPromotions = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url}benefits/getPromotions`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    toastr.info('No hay promociones activas.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {object} data 
 * @param {file} logo 
 * @param {file} archivo 
 * @param {file} manual 
 * @param {file} digdesc 
 * @returns 
 */
export const setUpdatePromotions = async (
    data,
    logo,
    archivo,
    manual,
    digdesc,
) => {
    try {
        let formData = new FormData()
        formData.append("request", `{
            "idPromotion": ${data.idPromotion},
            "urlEnterprise": ${JSON.stringify(data.urlEnterprise)},
            "title": ${JSON.stringify(data.title)},
            "nameEnterprise": ${JSON.stringify(data.nameEnterprise)},
            "description": ${JSON.stringify(data.description)},
            "active": ${JSON.stringify(data.active)},
            "phone": ${JSON.stringify(data.phone)},
            "whatsapp": ${JSON.stringify(data.whatsapp)},
            "urlcontact": ${JSON.stringify(data.urlContact)},
            "email": ${JSON.stringify(data.email)},
            "flgGral": ${JSON.stringify(data.flgGral)},
            "urlService": ${JSON.stringify(data.urlService)},
            "idPromotionCategory": ${JSON.stringify(data.idPromotionCategory)},
            "idPromotionType": ${JSON.stringify(data.idPromotionType)}
        }`)
        formData.append("logo", logo)
        formData.append("file", archivo)
        formData.append("manual", manual)
        formData.append("digdesc", digdesc)
        const result = await axios({
            method: 'POST',
            url: `${global.base_url}benefits/setUpdatePromotions`,
            data: formData,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @returns 
 */
export const getAllPlans = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}planController/getAllPlans`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                // case 404:
                //     toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                //     obj.validation = false
                //     break;
                case 1001:
                    toastr.info('No hay planes activos.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
