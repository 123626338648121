import React from 'react'

const NumberInput = ({
    label,
    generalValue,
    value,
    setValue,
    maxValue,
    name,
    id,
    index,
    readOnly,
}) => {
    const handleMaxNumber = e => {
        if (e.target.value.length < (maxValue + 1)) {
            if (Object.entries(generalValue).length > 0) {
                setValue({
                    ...generalValue,
                    [e.target.name]: e.target.value,
                })
            } else {
                if (index === '') {
                    setValue(e.target.value)
                } else {
                    setValue(e, index)
                }
            }
        }
    }
    return (
        <div className='form'>
            <input
                type='number'
                className='form__input fs-075em px-0'
                placeholder=' '
                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                onChange={handleMaxNumber}
                value={value}
                name={name}
                id={id}
                readOnly={readOnly}
            />
            <label className='form__label'>
                {label}
            </label>
        </div>
    )
}

export default NumberInput