import React, {
  useState,
  useEffect,
} from 'react'
// TODO: Components
import Menu from './Menu'
import Groups from './group/Groups'
import EnterprisePanel from './enterprise/EnterprisePanel'
// TODO: Redux
import { connect } from 'react-redux'
import { doSetGroupQuizAction } from '../../redux/groups'
// TODO: Tools
import { useNavigate } from 'react-router-dom'

const GroupsEnterprises = ({
  auth,
  organizationList,
  groupList,
  doSetGroupQuizAction,
}) => {
  const navigate = useNavigate()

  const [section, setSection] = useState(0);
  const [hideHeader, setHideHeader] = useState(false)
  const [showEnterpriseNotification, setShowEnterpriseNotification] = useState(false)

  useEffect(() => {
    if (organizationList.fetching) {
      if (sessionStorage.getItem('showEnterpriseNotification') !== null) {
        let organization = false
        organizationList.organizationList.forEach(data => {
          if (data.idEnterprise === parseInt(sessionStorage.getItem('showEnterpriseNotification'))) {
            organization = true
          }
        })
        if (organization) {
          setShowEnterpriseNotification(true)
          setSection(1)
        } else {
          let getOrg = false
          let orgData = {}
          groupList.groupList.forEach(data => {
            data.groupOrganizations.forEach(subdata => {
              if (subdata.idEnterprise === parseInt(sessionStorage.getItem('showEnterpriseNotification'))) {
                getOrg = true
                orgData = data
              }
            })
          })
          if (getOrg) {
            doSetGroupQuizAction(orgData)
            navigate('/group-register')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="col-12 mx-auto my-5">
      <div className="row">
        {!hideHeader && <Menu section={section} setSection={setSection} />}
        {section === 0 ? (
          <Groups
            setHideHeader={setHideHeader}
            auth={auth}
          />
        ) : (
          <EnterprisePanel
            auth={auth}
            showEnterpriseNotification={showEnterpriseNotification}
            setHideHeader={setHideHeader}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  auth,
  organizationList,
  groupList,
}) => {
  return {
    auth,
    organizationList,
    groupList,
  }
}

export default connect(mapStateToProps, { doSetGroupQuizAction })(GroupsEnterprises)