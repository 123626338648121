import axios from 'axios';
import '../config/config';

/**
 * Metodo para hacer login utilizando raw
 * @returns JSON response
 */
export const login = async (email = "", key = "", phone = "") => {
    const raw = {
        email,
        key,
        phone
    }
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "sprUser/login",
            headers: {
                Authorization: global.tokenAuth
            },
            data: raw
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Send email to password recovery
 * @param {string} email 
 * @returns 
 */
 export const recoveryPassword = async (email) => {
    let request = {
        email: email
    }
    let formData = new FormData();
    formData.append("request", JSON.stringify(request));
    try {
        let result = await axios({
            url: global.base_url + "sprUser/recoveryPassword",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * 
 * @param {string} token 
 * @param {string} userID 
 * @returns 
 */
export const validateToken = async (token, userID) => {
    try {
        let result = await axios({
            url: global.base_url + "sprUser/validate-token?db=" + token + "&gc=" + userID + "",
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * Set new passwoord
 * @param {string} userID 
 * @param {string} key 
 * @returns 
 */
 export const credentials = async (userID, key) => {
    try {
        let result = await axios({
            url: global.base_url + "sprUser/credentials?user=" + userID + "&key=" + key + "",
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}

/**
 * 
 * @param {*} formData 
 * @returns 
 */
 export const registerUser = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sprUser/registerUser",
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            },
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}