import React from 'react'
// TODO: Dependencies
import {
    DragDropContext,
    Droppable,
    Draggable,
} from 'react-beautiful-dnd'

const DragDrop = ({
    columns,
    setColumns,
    idKey,
}) => {

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return
        const { source, destination } = result

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId]
            const destColumn = columns[destination.droppableId]
            const sourceItems = [...sourceColumn.items]
            const destItems = [...destColumn.items]
            const [removed] = sourceItems.splice(source.index, 1)
            destItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            })
        } else {
            const column = columns[source.droppableId]
            const copiedItems = [...column.items]
            const [removed] = copiedItems.splice(source.index, 1)
            copiedItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            })
        }
    }
    return (
        <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container top container-2 mb-3' id='dragdropPlans'>
            <div className='row d-flex justify-content-between'>
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                    {Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div
                                className='col-5'
                                key={columnId}
                                style={{
                                    height: '450px',
                                }}
                            >
                                <div className='col-12 mb-3 text-center'>
                                    <span className='fw-bold'>{column.name}</span>
                                </div>
                                <div className='card card-drag-drop-plans h-100'>
                                    <Droppable
                                        droppableId={columnId}
                                        key={columnId}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className='card-body'
                                                >
                                                    {column.items.map((item, index) => {
                                                        return (
                                                            <Draggable
                                                                key={item[idKey]}
                                                                draggableId={item[idKey]}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className='card px-2 py-2 item text-center item-borde-blue'
                                                                        >
                                                                            <span>{item.title}</span>
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )
                                        }}
                                    </Droppable>
                                </div>
                            </div>
                        )
                    })}
                </DragDropContext>
            </div>
        </div>
    )
}

export default DragDrop