import React from 'react';
// Tools
import PropTypes from 'prop-types';

const Menu = ({ scoreboard, setShowTable, showDetails }) => {
    const {
        closed,
        pending,
        progressing
    } = scoreboard;
    return (
        <div className="row d-flex justify-content-around">
            <div
                className='col-sm-12 col-lg-2 col-xxl-2 bg-yellow-guay text-white rounded-3 pointer generic__show-container container-2 mb-1'
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(0)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Pendiente</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{pending}</h1>
                    </div>
                </div>
            </div>
            <div
                className='col-sm-12 col-lg-2 col-xxl-2 bg-blue-guay text-white rounded-3 pointer generic__show-container container-2 mb-1'
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(1)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Procesado</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{progressing}</h1>
                    </div>
                </div>
            </div>
            <div
                className='col-sm-12 col-lg-2 col-xxl-2 bg-green-guay text-white rounded-3 pointer generic__show-container container-2 mb-1'
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(2)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Cerradas</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{closed}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

Menu.propTypes = {
    scoreboard: PropTypes.object.isRequired,
    setShowTable: PropTypes.func.isRequired,
    showDetails: PropTypes.bool.isRequired,
}

export default Menu;
