import React, { useState } from 'react'
// TODO: Tools
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table'
import * as toastr from 'toastr'
import ModalPreview from '../genericComponents/ModalPreview'
import $ from 'jquery'

const Table = ({
    listFichas,
    listWebinars,
    listPosters,
    listInfografias,
    showTable,
    resourceObj,
    setResourceObj,
    setShowDetails,
    saveResource,
    handleResetTable,
    setresourceURL,
}) => {
    const [previewFile, setPreviewFile] = useState('')
    const [previewFileType, setPreviewFileType] = useState('')

    // Table Props
    const options = {
        noDataText: 'No se encontraron recursos',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    }
    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setActive = (cell, row, rowIndex) => {
        if (cell === 1) {
            return 'Activo'
        } else {
            return 'Desactivado'
        }
    }
    /**
     * 
     * @param {any} cell 
     * @param {object} row 
     * @param {number} rowIndex 
     * @returns 
     */
    const setIcons = (cell, row, rowIndex) => {
        return (
            <div className='col-12 d-flex justify-content-between'>
                <i
                    className={row.active === 0 ? 'bi bi-recycle text-pink pointer' : 'bi bi-trash text-pink pointer'}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row.active === 0 ? "Activar" : "Desactivar"}
                    onClick={() => {
                        let obj = {
                            ...resourceObj,
                            idResource: row.idResource,
                            resourceType: row.resourceType,
                            title: row.title,
                            description: row.description,
                            active: row.active === 1 ? 0 : 1,
                            idProduct: row.idProduct,
                            idCategory: row.idCategory,
                            idSubcategory: row.idSubcategory,
                        }
                        saveResource(obj, {}).then(response => {
                            if (response) {
                                switch (response.code) {
                                    case 1:
                                        if (row.active === 1) {
                                            toastr.success("El recurso se desactivó correctamente.", "¡Éxito!")
                                        } else {
                                            toastr.success("El recurso se activó correctamente.", "¡Éxito!")
                                        }
                                        handleResetTable()
                                        break;
                                    default:
                                        toastr.error(`No se cambiar el estatus del recurso. Intenta más tarde.`, "¡Ooops!")
                                        break;
                                }
                            }
                        })
                    }}
                />
                <i
                    className='bi bi-pencil text-pink pointer'
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Editar"
                    onClick={() => {
                        let serviceType
                        if (row.idProduct === 1 && row.idCategory === 1 && row.idSubcategory === 1) {
                            serviceType = 1
                        }
                        if (row.idProduct === 1 && row.idCategory === 1 && row.idSubcategory === 2) {
                            serviceType = 2
                        }
                        if (row.idProduct === 1 && row.idCategory === 2 && row.idSubcategory === 0) {
                            serviceType = 3
                        }
                        setResourceObj({
                            ...resourceObj,
                            idResource: row.idResource,
                            resourceType: row.resourceType,
                            title: row.title,
                            description: row.description,
                            active: row.active,
                            serviceType,
                            fileName: row.fileName,
                        })
                        setresourceURL(row.url)
                        setShowDetails(true)
                    }}
                />
                {row.url.includes('pdf') || row.url.includes('mp3') || row.url.includes('mp4') ? (
                    <i
                        className='bi bi-eye text-pink pointer'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Previsualizar"
                        onClick={() => {
                            setPreviewFile(row.url)
                            setPreviewFileType(1)
                            $('#modalPreview').show()
                        }}
                    />
                ) : (
                    row.url.includes('jpeg') || row.url.includes('jpg') || row.url.includes('png') ? (
                        <i
                            className='bi bi-eye text-pink pointer'
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Previsualizar"
                            onClick={() => {
                                setPreviewFile(row.url)
                                setPreviewFileType(2)
                                $('#modalPreview').show()
                            }}
                        />
                    ) : (
                        <i
                            className='bi bi-cloud-download text-pink pointer'
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Descargar"
                            onClick={() => window.open(row.url)}
                        />
                    )
                )}
            </div>
        )
    }
    const handleCleanViwer = () => {
        setPreviewFileType(0)
    }
    return (
        <div className="col-12 generic__show-container container-1">
            <ModalPreview
                file={previewFile}
                fileType={previewFileType}
                cleanFunction={handleCleanViwer}
            />
            <BootstrapTable
                data={showTable === 0 ? (listFichas) : (showTable === 1 ? (listPosters) : (showTable === 2 ? listWebinars : (listInfografias)))}
                hover
                striped
                pagination={true}
                options={options}
                tableStyle={{ border: 'none', padding: '0px', fontSize: '.8rem', color: '#798a94' }}
                trClassName='fs-7 border-0'
                trStyle={{ border: '.5px' }}
                search
                searchPlaceholder='Buscar'
            >
                <TableHeaderColumn
                    dataField="idResource"
                    isKey
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    hidden
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="title"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Nombre</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="description"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    width="45%"
                    dataSort={true}
                >Descripción</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="serviceName"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Servicio</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="active"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataFormat={setActive}
                    dataSort={true}
                    width="10%"
                >Estatus</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="idResource"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataFormat={setIcons}
                    width="10%"
                ></TableHeaderColumn>
            </BootstrapTable>
        </div>
    )
}

export default Table
