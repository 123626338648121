import React, {
    useEffect,
    useState,
} from 'react'
// TODO: API calls
import { getPromotions } from '../../models/benefits_model'
import {
    getPromotionCategory,
    getPromotionType,
} from '../../models/catalog_model'
// TODO: Components
import PromotionsList from './PromotionsList'
import PromotionsForm from './PromotionsForm'

const PromotionsIndex = () => {

    const initialState = {
        promotion: {
            urlLogo: '../assets/img/icons/sin_logo.png',
            idPromotion: 0,
            urlEnterprise: '',
            title: '',
            nameEnterprise: '',
            description: '',
            active: 1,
            urlResource: '../assets/img/icons/sin_logo.png',
            phone: '',
            whatsapp: '',
            urlContact: '',
            email: '',
            urlManual: '',
            urlDigdesc: '',
            flgGral: 0,
            urlService: '',
            idPromotionCategory: 0,
            idPromotionType: 0,
        },
    }

    const [showForm, setShowForm] = useState(false)
    const [promotions, setPromotions] = useState([])
    const [promotion, setPromotion] = useState(initialState.promotion)
    const [categories, setCategories] = useState([])
    const [types, setTypes] = useState([])

    useEffect(() => {
        getPromotions().then(response => {
            if (response.validation) {
                setPromotions(response.data.guayPromotions)
            }
        })
    }, [promotion])

    useEffect(() => {
        getPromotionCategory().then(response => {
            if (response.validation) {
                setCategories(response.data)
            }
        })
        getPromotionType().then(response => {
            if (response.validation) {
                setTypes(response.data)
            }
        })
    }, [])

    return (
        <div className='col-11 mx-auto my-5'>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <div className='row'>
                        <div className='col text-start'>
                            <h3>Promociones</h3>
                        </div>
                        <div className='col text-end'>
                            {!showForm && (
                                <span
                                    className='text-pink pointer fs-3'
                                    onClick={() => setShowForm(true)}
                                ><i className='bi bi-plus-circle-fill' /></span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    {showForm ? (
                        <PromotionsForm
                            initialState={initialState}
                            promotion={promotion}
                            categories={categories}
                            types={types}
                            setPromotion={setPromotion}
                            setShowForm={setShowForm}
                        />
                    ) : (
                        <PromotionsList
                            promotions={promotions}
                            setPromotion={setPromotion}
                            setShowForm={setShowForm}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PromotionsIndex