import React, {
    //forwardRef,
    useState,
    useEffect,
} from 'react'
// TODO: API calls
import {
    manualSendWelcomeEmail,
    saveOrganizationInfo,
} from '../../../models/organization_model'
// TODO: Components
import NumberInput from '../../genericComponents/NumberInput'
import EnterpriseContactForm from './EnterpriseContactForm'
// TODO: Generic tools
import {
    //handleDateTextFormat,
    handleScroll,
    handleDateToyyyymmdd,
} from '../../../helpers/genericFunc'
import {
    handleDuplicateContactInputs,
    handleDuplicateGroupContactInputs,
} from '../helperValidations'
// TODO: Tools
//import Datepicker, { registerLocale } from 'react-datepicker'
//import es from 'date-fns/locale/es'
import * as toastr from 'toastr'
import $ from 'jquery'
// TODO: redux
import { connect } from 'react-redux'
import { doSetGroupQuizAction } from '../../../redux/groups'
import { getListOrgGroupInfo } from '../../../models/groups_model'
import { getGroups } from '../../../redux/getGroupList'
import EnterpriseBenefitsForm from './EnterpriseBenefitsForm'

//registerLocale('es', es)

const GeneralDataForm = ({
    // Organization
    generalData,
    setGeneralData,
    contactList,
    handleOnChangeContact,
    handleDeleteContact,
    handleCleanForm,
    idUser,
    totalContactAdmin,
    handleReloadData,
    unregisterContactGroup,
    // Group funct
    groupContactAdmin,
    setGroupContactAdmin,
    setEnterprisesListAdd,
    enterprisesListAdd,
    groupContactsList,
    groupInfo,
    indexTemporalEnterprise,
    totalContactAdminGroup,
    doSetGroupQuizAction,
    groupList,
    getGroups,
    groupServices,
    // benefits
    planList,
    enterpriseBenefitPlans,
    handleOnChangeBenefitPlan,
    handleDeleteBenefitPlan,
}) => {
    // console.log('idUser gen: ', idUser);

    const [services, setServices] = useState([])
    const [temporalIdenterprise, setTemporalIdenterprise] = useState()

    const {
        idEnterprise,
        commercialName,
        registerDate,
        collaborators,
        productEnterprise,
        //benefitEnterprise,
    } = generalData

    // console.log('registerDate: ',registerDate, ' generalData: ',generalData);
    //console.log(generalData)

    useEffect(() => {
        if (groupInfo.idgroup === '') {
            const arrServices = [
                {
                    productID: 1,
                    categoryID: 1,
                    subcategoryID: 1,
                    description: 'Estado Emocional',
                    checked: true,
                    status: 1,
                    onDate: '',
                },
                {
                    productID: 1,
                    categoryID: 1,
                    subcategoryID: 2,
                    description: 'Entorno Laboral',
                    checked: true,
                    status: 1,
                    onDate: '',
                },
                {
                    productID: 1,
                    categoryID: 2,
                    subcategoryID: 0,
                    description: 'Clima Laboral',
                    checked: true,
                    status: 1,
                    onDate: '',
                },
                {
                    productID: 1,
                    categoryID: 4,
                    subcategoryID: 0,
                    description: 'Cultura Organizacional',
                    checked: true,
                    status: 1,
                    onDate: '',
                },
            ]
            setTemporalIdenterprise(idEnterprise)
            if (temporalIdenterprise !== idEnterprise) {
                if (idEnterprise > 0) {
                    let newArr = []
                    let record = false
                    arrServices.forEach(subData => {
                        let recorded = false
                        productEnterprise.forEach(data => {
                            if (data.idProduct === subData.productID && data.idCategory === subData.categoryID && data.idSubCategory === subData.subcategoryID) {
                                newArr.push({
                                    ...subData,
                                    checked: data.active === 1 ? true : false,
                                    onDate: handleDateToyyyymmdd(data.onDate),
                                    status: data.active === 1 ? 1 : 0,
                                })
                                recorded = true
                            }
                        })
                        if (!recorded) {
                            newArr.push({
                                ...subData,
                                checked: false,
                            })
                        }
                    })
                    console.log(newArr)
                    setServices(newArr)
                } else {
                    if (productEnterprise.length > 0) {
                        let newArr = []
                        arrServices.forEach(subData => {
                            let recorded = false
                            productEnterprise.forEach(data => {
                                if (data.idProduct === subData.productID && data.idCategory === subData.categoryID && data.idSubCategory === subData.subcategoryID) {
                                    newArr.push({
                                        ...subData,
                                        checked: true,
                                        onDate: handleDateToyyyymmdd(data.onDate),
                                    })
                                    recorded = true
                                }
                            })
                            if (!recorded) {
                                newArr.push(subData)
                            }
                        })
                        setServices(newArr)
                    } else {
                        setServices(arrServices)
                    }
                }
            }
        } else {
            if (groupServices.length === 0) {
                const getChecked = generalData.productEnterprise.filter(data => data.checked)
                setServices(getChecked)
            } else {
                const getChecked = groupServices.filter(data => data.checked)
                setServices(getChecked)
            }
        }
    }, [idEnterprise, productEnterprise, groupServices])

    useEffect(() => {
        if (groupContactsList) {
            if (groupContactsList.length > 0) {
                let contactAdmin = false
                groupContactsList.forEach(data => {
                    if (parseInt(data.contactType) === 2) {
                        contactAdmin = true
                    }
                })
                if (contactAdmin) {
                    setGroupContactAdmin(true)
                }
            }
        }
    }, [groupContactsList, setGroupContactAdmin])

    /* const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form input-img'>
            <input
                type='text'
                className='form__input fs-0875em border-0'
                placeholder=' '
                onClick={onClick}
                ref={ref}
                value={value === '' ? '' : handleDateTextFormat(value)}
                readOnly={true}
            />
            <label className='form__label'>
                Fecha de inicio de servicio:
            </label>
            <img
                src="../assets/img/icons/Icono_calendario.png"
                className="mx-1 icon-size-16x15 " alt="img-date"
            />
        </div>
    )) */

    const handleGenDataOnChange = e => {
        $(`input`).removeClass('border-danger')
        setGeneralData({
            ...generalData,
            [e.target.name]: e.target.value,
        })
    }

    const handleServices = (productID, categoryID, subcategoryID) => {
        let newArr = []
        services.forEach(data => {
            if (data.productID === productID && data.categoryID === categoryID && data.subcategoryID === subcategoryID) {
                newArr.push({
                    ...data,
                    checked: !data.checked,
                    onDate: data.onDate === '' ? handleDateToyyyymmdd(new Date()) : data.onDate,
                })
            } else {
                newArr.push(data)
            }
        })
        setServices(newArr)
    }

    const handleRefreshGroupInfo = (groupId, isFromGroupForm) => {
        getGroups()
        if (!isFromGroupForm) {
            setTimeout(() => {
                window.location = '/groups-enterprises'
            }, 2000);
        } else {
            getListOrgGroupInfo().then(response => {
                if (response) {
                    if (response.code === 1 && response.code) {
                        if (response.getListOrgGroupInfo.length > 0) {
                            const groupSelected = response.getListOrgGroupInfo.filter(data => data.groupEnterpriseGroupId === groupId)
                            doSetGroupQuizAction(groupSelected[0])
                        } else {

                            toastr.info('Hubo un error al cargar la información del grupo', '¡Ooops!')
                        }
                    } else {
                        toastr.info('Hubo un error al cargar la información del grupo', '¡Ooops!')
                    }

                } else {
                    toastr.info('Hubo un error al cargar la información del grupo', '¡Ooops!')
                }
            });
        }

    }

    const handleSendEmail = id => {
        manualSendWelcomeEmail(id).then(response => {
            if (response.code === 1) {
                toastr.success('Se ha enviado el correo correctamente.', '¡Éxito!')
            }
        })
    }

    const handleSubmitForm = () => {

        if (unregisterContactGroup) {
            toastr.warning('Tiene contactos de grupo pendientes de guardar. Primero debes guardar la información de grupo.', '¡Ooops!')
            return
        }

        let incomplete = false

        // Validations
        if (commercialName === '') {
            toastr.warning('El campo Nombre organización es obligatorio', '¡Ooops!')
            $(`#commercialName`).addClass('border-danger')
            handleScroll('commercialName')
            incomplete = true
            return
        }
        let groupAdmin = false
        groupContactsList.forEach((data) => {
            if (parseInt(data.contactType) === 2) {
                groupAdmin = true
            }
        })
        if (!groupAdmin) {
            if (contactList.length === 0) {
                toastr.warning('Debes tener por lo menos un contacto administrador', '¡Ooops!')
                handleScroll('contactDiv')
                incomplete = true
                return
            } else {
                const getAdmin = contactList.filter(data => parseInt(data.contactType) === 2)
                if (getAdmin.length === 0) {
                    toastr.warning('Debes tener por lo menos un contacto administrador', '¡Ooops!')
                    handleScroll('contactDiv')
                    incomplete = true
                    return
                }
            }
        }

        const handleRequiredInputs = () => {
            let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
            let incompleteFlag = false
            contactList.forEach((data, index) => {
                if (index + 1 < contactList.length) {
                    if (data.contactType === '') {
                        toastr.warning('Todos los campos de contacto son obligatorios.', '¡Ooops!')
                        $(`#contactType${index}`).addClass('border-danger')
                        handleScroll(`contactType${index}`)
                        incompleteFlag = true
                    }
                    if (data.contactName === '') {
                        toastr.warning('Todos los campos de contacto son obligatorios.', '¡Ooops!')
                        $(`#contactName${index}`).addClass('border-danger')
                        handleScroll(`contactName${index}`)
                        incompleteFlag = true
                    }
                    if (data.contactlastname === '') {
                        toastr.warning('Todos los campos de contacto son obligatorios.', '¡Ooops!')
                        $(`#contactlastname${index}`).addClass('border-danger')
                        handleScroll(`contactlastname${index}`)
                        incompleteFlag = true
                    }
                    if (data.contactEmail === '') {
                        toastr.warning('Todos los campos de contacto son obligatorios.', '¡Ooops!')
                        $(`#contactEmail${index}`).addClass('border-danger')
                        handleScroll(`contactEmail${index}`)
                        incompleteFlag = true
                    } else {
                        if (!regex.test(data.contactEmail)) {
                            toastr.warning('El formato del Corro electrónico de contacto no es correcto.', '¡Ooops!')
                            $(`#contactEmail${index}`).addClass('border-danger')
                            handleScroll(`contactEmail${index}`)
                            incompleteFlag = true
                        }
                    }
                    if (data.contactNumber !== '' && data.contactNumber.length < 10) {
                        toastr.warning('El Teléfono debe contener 10 dígitos.', '¡Ooops!')
                        $(`#contactNumber${index}`).addClass('border-danger')
                        handleScroll(`contactNumber${index}`)
                        incompleteFlag = true
                    }
                }
            })
            enterpriseBenefitPlans.forEach((data, index) => {
                if (index + 1 < enterpriseBenefitPlans.length) {
                    if (data.idPlan !== '') {
                        if( data.numPolicy === '' ||  parseInt(data.numPolicy) === 0 ){
                            toastr.warning('Falta completar el numero de licencias en el plan seleccionado', '¡Ooops!');
                            $(`#ent-numPolicy${index}`).addClass('border-danger')
                            handleScroll(`ent-numPolicy${index}`)
                            incompleteFlag = true
                        }
                        if(data.onDate === '' || data.onDate === undefined){
                            toastr.warning('Falta completar la fecha de inicio para el plan seleccionado', '¡Ooops!');
                            $(`#ent-onDate${index}`).addClass('border-danger')
                            handleScroll(`ent-onDate${index}`)
                            incompleteFlag = true
                        }
                    }
                }
            })
            return incompleteFlag
        }

        let emailArr = []
        let nombresArr = []

        contactList.forEach((data, index) => {
            if (index + 1 < contactList.length) {
                emailArr.push(data.contactEmail)
                nombresArr.push(`${data.contactName} ${data.contactlastname}`)
            }
        })

        const duplicateGroupName = handleDuplicateGroupContactInputs(
            groupContactsList,
            nombresArr,
            'contactName',
            'contactlastname',
            'contactName',
            'contactlastname',
            'Existen nombre y apellido duplicados. Cada nombre debe ser distinto.'
        )

        const duplicateGroupEmail = handleDuplicateGroupContactInputs(
            groupContactsList,
            emailArr,
            'contactEmail',
            '',
            'contactEmail',
            '',
            'Existen correos duplicados. Cada correo debe ser distinto.'
        )

        const duplicateOrgName = handleDuplicateContactInputs(
            nombresArr,
            'contactName',
            'contactlastname',
            'Existen nombre y apellido duplicados. Cada nombre debe ser distinto.'
        )

        const duplicateOrgEmail = handleDuplicateContactInputs(
            emailArr,
            'contactEmail',
            '',
            'Existen correos duplicados. Cada correo debe ser distinto.'
        )

        // Get selected product arr
        const getSelectedProduct = services.filter(data => data.checked)
        console.log(getSelectedProduct)
        let productEnterpriseArr = []
        console.log(groupInfo.idgroup)
        if (groupInfo.idgroup === '') {
            getSelectedProduct.forEach(data => {
                productEnterpriseArr.push({
                    idProduct: data.productID,
                    idCategory: data.categoryID,
                    idSubCategory: data.subcategoryID,
                    onDate: data.onDate === '' ? handleDateToyyyymmdd(new Date()) : data.onDate,
                })
            })
        } else {
            getSelectedProduct.forEach(data => {
                productEnterpriseArr.push({
                    idProduct: data.idProduct,
                    idCategory: data.idCategory,
                    idSubCategory: data.idSubCategory,
                    onDate: data.onDate === '' ? handleDateToyyyymmdd(new Date()) : data.onDate,
                })
                console.log(data.onDate)
            })
        }
        console.log(productEnterpriseArr)

        // Get contacts
        let getContacts = []
        contactList.forEach(data => {
            if (data.contactType !== '') {
                //console.log('contactos empresa');
                getContacts.push({
                    idWelcomeEmail: data.idWelcomeEmail === '' ? 0 : data.idWelcomeEmail,
                    idRol: data.contactType,
                    name: data.contactName,
                    lastName: data.contactlastname,
                    email: data.contactEmail,
                    phone: data.contactNumber,
                    sendDate: handleDateToyyyymmdd(new Date()),
                })
            }
        })

        groupContactsList.forEach(data => {
            if (data.contactType !== '') {
                getContacts.push({
                    idWelcomeEmail: data.idWelcomeEmail,
                    idRol: data.contactType,
                    name: data.contactName,
                    lastName: data.contactlastname,
                    email: data.contactEmail,
                    phone: data.contactNumber,
                    sendDate: handleDateToyyyymmdd(new Date()),
                })
            }
        })

        // get plans
        let getEnterprisePlans = []
        if(enterpriseBenefitPlans.length > 0){
            enterpriseBenefitPlans.forEach(data => {
                if (data.idPlan !== '') {
                    if(data.idPlanEnterprise !== '' ){
                        getEnterprisePlans.push({
                            idPlanEnterprise: parseInt(data.idPlanEnterprise),
                            idPlan: parseInt(data.idPlan),
                            numPolicy: parseInt(data.numPolicy),
                            onDate: handleDateToyyyymmdd(data.onDate),
                        })
                    }else{
                        getEnterprisePlans.push({
                            idPlan: parseInt(data.idPlan),
                            numPolicy: parseInt(data.numPolicy),
                            onDate: handleDateToyyyymmdd(data.onDate),
                        })
                    }
                }
            })
        }

        //console.log('contactos empresa: ', getContacts);

        let obj = {
            idGroup: groupInfo.idgroup === '' ? 0 : parseInt(groupInfo.idgroup),
            idEnterprise,
            commercialName,
            collaborators: collaborators === '' ? 0 : parseInt(collaborators),
            productEnterprise: productEnterpriseArr,
            benefitEnterprise: [],
            planEnterprise: getEnterprisePlans,
            welcomeEmails: getContacts,
            idUser: idUser,
        }
        console.log(obj)
        //console.log(duplicateGroupName)
        //console.log(duplicateGroupEmail)
        //console.log(duplicateOrgName)
        //console.log(duplicateOrgEmail)
        if (
            !incomplete &&
            !handleRequiredInputs() &&
            !duplicateGroupName &&
            !duplicateGroupEmail &&
            !duplicateOrgName &&
            !duplicateOrgEmail
        ) {
            if (groupInfo.idgroup === 0) {
                // if (groupInfo.idgroup === 0 || groupInfo.idgroup > 0) {
                obj.activeCollaborators = collaborators === '' ? 0 : parseInt(collaborators)
                obj.active = 1
                obj.status = 1
                obj.color = 99
                obj.registerDate = registerDate !== '' ? registerDate : new Date()
                obj.renewalDate = ''
                obj.logo = ''
                console.log(obj)

                if (indexTemporalEnterprise >= 0 && enterprisesListAdd.length > 0 && indexTemporalEnterprise !== '') {
                    const empresasTemActualizadas = enterprisesListAdd.map((ent, index) => index === indexTemporalEnterprise ? obj : ent)
                    // console.log('empresasTemActualizadas: ',empresasTemActualizadas);
                    setEnterprisesListAdd(empresasTemActualizadas)
                } else {
                    setEnterprisesListAdd([
                        ...enterprisesListAdd,
                        obj,
                    ])
                }

                handleCleanForm()
            } else {
                console.log('obj2: ',obj);
                saveOrganizationInfo(obj).then(response => {
                    if (response) {
                        if (response.code === 1) {
                            toastr.success(`Se ${idEnterprise === 0 ? 'creó' : 'guardó'} correctamente la organización.`, '¡Éxito!')
                            if (groupInfo.idgroup > 0) {
                                handleRefreshGroupInfo(groupInfo.idgroup, groupInfo.isFromGroupForm)
                                handleCleanForm()
                                handleReloadData()
                            } else {
                                handleCleanForm()
                                handleReloadData()
                            }

                        } else {
                            toastr.error('No se guardó correctamente la empresa. Intenta nuevamente más tarde.', '¡Ooops!')
                        }
                    }
                })
            }
        }
    }

    return (
        <div className='col-12 mt-5'>
            <div className='row'>
                <div className='col-11 mx-auto'>

                    <div className='row d-flex justify-content-between mb-5'>
                        <div className='col-lg-6 col-xl-6 col-xxl-6'>
                            <div className='form'>
                                <input
                                    type='text'
                                    className='form__input fs-0875em'
                                    placeholder=' '
                                    name='commercialName'
                                    id='commercialName'
                                    value={commercialName}
                                    onChange={handleGenDataOnChange}
                                />
                                <label className='form__label'>
                                    Nombre organización:
                                </label>
                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-xl-4 col-xxl-3'>
                            {groupInfo.idgroup === '' && (
                                <Datepicker
                                    onChange={date => {
                                        setGeneralData({
                                            ...generalData,
                                            registerDate: date,
                                        })
                                    }}
                                    //selected={registerDate}
                                    selected={registerDate === '' ? new Date() : registerDate}
                                    customInput={<ExampleCustomInput />}
                                    locale="es"
                                    minDate={new Date()}
                                    //disabled={idEnterprise > 0 && registerDate !== '' ? true : false}
                                    disabled={true}
                                />
                            )}
                        </div> */}
                        <div className='col-lg-4 col-xl-4 col-xxl-4'>
                            <NumberInput
                                label='Número de colaboradores:'
                                generalValue={generalData}
                                value={collaborators}
                                setValue={setGeneralData}
                                maxValue={10}
                                name='collaborators'
                                id='collaborators'
                            />
                        </div>
                    </div>

                    {groupInfo.idgroup === '' && (
                        <div className='row mb-5'>
                            <div className='col-12 mb-3 border-bottom pb-3'>
                                <span className='fs-1em fw-bold font-family-quicksand'>Servicios</span>
                            </div>
                            {services.map(data => (
                                <div
                                    className='col-3'
                                    key={`servicio-${data.productID}${data.categoryID}${data.subcategoryID}`}
                                >
                                    <div
                                        className="form-check form-check-guay pointer"
                                        onClick={() => data.status === 1 && handleServices(data.productID, data.categoryID, data.subcategoryID)}
                                    >
                                        <input
                                            className="form-check-input icon-size-15x15 border-sec-blue-guay pointer"
                                            type="checkbox"
                                            id={`${data.productID}-${data.categoryID}-${data.subcategoryID}`}
                                            name={`${data.productID}-${data.categoryID}-${data.subcategoryID}`}
                                            value={`${data.productID}-${data.categoryID}-${data.subcategoryID}`}
                                            checked={data.checked}
                                            disabled={data.status === 0 ? true : false}
                                            readOnly={true}
                                        />
                                        <label className="form-check-label font-family-quicksand fs-0875em pointer">{data.description}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {groupInfo.idgroup === '' && (
                        <div className='row mb-5'>
                            <div className='col-12 mb-3 border-bottom pb-3'>
                                <span className='fs-1em fw-bold font-family-quicksand'>Beneficios</span>
                            </div>
                            {enterpriseBenefitPlans.map((data,index) => (
                                <EnterpriseBenefitsForm
                                    data={data}
                                    currentIndex={index}
                                    planList={planList}
                                    handleOnChangeBenefitPlan={handleOnChangeBenefitPlan}
                                    handleDeleteBenefitPlan={handleDeleteBenefitPlan}
                                    totalRegister={enterpriseBenefitPlans.length}
                                    key={`ent-benefit-plan-${index + 1}`}
                                />
                            ))
        
                            }
                        </div>
                    )}

                    <div className='row mb-5'>
                        <div
                            className='col-12 mb-3 border-bottom pb-3'
                            id='contactDiv'
                        >
                            <span className='fs-1em fw-bold font-family-quicksand'>Envío de correo de bienvenida</span>
                        </div>

                        {/* {groupContactsList.map((data, index) => (
                                <EnterpriseContactForm
                                    data={data}
                                    currentIndex={index}
                                    handleOnChangeFunction={handleOnChangeContact}
                                    handleDeleteFunction={handleDeleteContact}
                                    totalRegister={groupContactsList.length + contactList.length}
                                    idEnterprise={idEnterprise}
                                    totalContactAdmin={totalContactAdmin}
                                    onlyReadInputs={true}
                                    groupContactAdmin={groupContactAdmin}
                                    totalContactAdminGroup={0}
                                    key={`contact-group-${index + 1}`}
                                />
                            ))} */}

                        {contactList.map((data, index) => (
                            <EnterpriseContactForm
                                data={data}
                                currentIndex={index}
                                handleOnChangeFunction={handleOnChangeContact}
                                handleDeleteFunction={handleDeleteContact}
                                totalRegister={contactList.length}
                                idEnterprise={idEnterprise}
                                totalContactAdmin={totalContactAdmin}
                                onlyReadInputs={false}
                                groupContactAdmin={groupContactAdmin}
                                totalContactAdminGroup={totalContactAdminGroup}
                                handleSendEmail={handleSendEmail}
                                key={`contact-${index + 1}`}
                            />
                        ))}

                    </div>

                    <div className='row mb-3'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6 d-flex flex-wrap align-content-center'>
                                    <i
                                        className='bi bi-chevron-left fs-1 fw-bold pointer text-decoration-none text-blue'
                                        onClick={handleCleanForm}
                                    />
                                    <span
                                        className='d-flex flex-wrap font-family-quicksand align-content-center pointer text-decoration-none text-blue'
                                        onClick={handleCleanForm}
                                    >Atrás</span>
                                </div>
                                <div className='col-6 d-flex justify-content-end flex-wrap align-content-center'>
                                    <button
                                        className='btn btn-sm bg-blue-navy text-white font-family-quicksand rounded-pill px-4'
                                        onClick={handleSubmitForm}
                                    >Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

// export default GeneralDataForm
const mapStateToProps = ({ groupSpecific, groupList }) => {
    return {
        groupSpecific,
        groupList
    }
}

export default connect(mapStateToProps, { doSetGroupQuizAction, getGroups })(GeneralDataForm)