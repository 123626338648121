import React from 'react'
// TODO: redux
import { connect } from 'react-redux'
import { getOrganizations } from '../../redux/getOrganizationList'
import { cleanGroupList } from '../../redux/getGroupList'

const Menu = ({
    getOrganizations,
    cleanGroupList,
    section,
    setSection,
}) => {
    const liMenu = ['Grupo', 'Organización']

    return (
        <div className="col-12 mb-3 sticky-panel">
            <div className='card border-0 shadow rounded-10-px py-3 generic__show-main-container container-1'>
                <ul className="nav justify-content-center">
                    {
                        liMenu.map((data, index) => {
                            return (
                                <li className={section === index ?
                                    "nav-item border-bottom-light-blue-guay" :
                                    "nav-item"}
                                    key={index}>
                                    <span
                                        className='nav-link-guay pointer'
                                        onClick={() => {
                                            if (index === 0) {
                                                cleanGroupList()
                                            } else {
                                                getOrganizations()
                                            }
                                            setSection(index)
                                        }}
                                    >{data}</span>
                                </li>
                            )
                        })
                    }
                </ul>

            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

export default connect(
    mapStateToProps,
    {
        getOrganizations,
        cleanGroupList,
    }
)(Menu)