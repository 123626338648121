import React, { useState, useEffect } from 'react';
import { getCampaigns } from '../../../models/sponsor_model';
import * as toastr from 'toastr';

export const CampaignList = ({ setCampaign }) => {

    const [campaignList, setCampaignList] = useState([]);
    const [showMessage, setShowMessage] = useState(false)
    useEffect(() => {

        getCampaigns().then(response => {
            switch (response?.code) {
                case 1:
                    if (response.listCampaing.length === 0) {
                        toastr.info("Sin campañas aún");
                    } else {
                        setCampaignList(response.listCampaing);
                    }
                    break;

                default:
                    toastr.error("No hay campañas que mostrar");
                    break;
            }
        });

        return () => {
        }

    }, [])

    const handleClickCampaign = (campaign) => {
        setCampaign({
            inUse: true,
            ...campaign,
        });
    }

    const returnStatus = (active, status) => {
        if (active === 0) {
            return <i className="bi bi-x-circle me-2 text-danger"></i>
        } else { // active === 1
            if (status === 1) {
                return <i className="bi bi-check-circle me-2 text-green"></i>
            } else { // status === 2
                return <i className="bi bi-exclamation-triangle me-2 text-yellow"></i>
            }
        }
    }

    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById("listCampaigns");
        li = ul.getElementsByTagName('li');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toLowerCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                records = records = 1;
            } else {
                li[i].style.display = "none";
            }
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }

    return (
        <div className='card border-0 shadow rounded-25-px py-3 generic__show-main-container container-1'>
            <div className='card-body'>
                <div className='col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2'>
                    <div className='row d-flex justify-content-end'>
                        <div className="col-sm-12 col-lg-5 col-xxl-4">
                            <div className="input-group input-group-guay mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar"
                                    onChange={sortArray}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 list-group generic__show-container container-1' id="listCampaigns">
                    {showMessage && <h2 className='text-center p-3'>No hay resultados</h2>}
                    {
                        campaignList.map(campaign => (
                            <li
                                key={campaign.idCampaign}
                                className="col-sm-11 col-lg-11 col-xxl-12 mx-auto shadow p-3 rounded presence__show-container container-1 h-50 pull-up pointer list-group-item"
                                onClick={() => handleClickCampaign(campaign)}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {
                                            returnStatus(campaign.active, campaign.status)
                                        }
                                        <span className="fw-bold">
                                            {campaign.title} | Audiencia - {campaign.collabsAssigned} | Patrocinios - {campaign.sponsorAssigned} | Monto - {campaign.amount}
                                        </span>
                                    </div>
                                    <i className="bi bi-pencil"></i>
                                </div>
                            </li>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
