import * as toastr from 'toastr'
import $ from 'jquery'

/**
 * 
 * @param {array} array 
 * @param {boolean} incompleteFlag 
 * @param {string} inputID 
 * @param {string} message 
 */
export const handleDuplicateContactInputs = (array, inputID_1, inputID_2, message) => {

    let duplicados = []
    let duplicado = false
    let arrDuplicado = []

    const tempArray = [...array].sort()

    for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i + 1] === tempArray[i]) {
            duplicados.push(tempArray[i])
        }
    }

    array.forEach((data, index) => {
        if (data === duplicados[0]) {
            arrDuplicado.push(index)
            duplicado = true
        }
    })

    if (duplicado) {
        toastr.warning(message, '¡Ooops!')
        arrDuplicado.forEach(data => {
            $(`#${inputID_1}${data}`).addClass('border-danger')
            if (inputID_2 !== '') {
                $(`#${inputID_2}${data}`).addClass('border-danger')
            }
        })
    }

    return duplicado
}

export const handleDuplicateGroupContactInputs = (arrayGroup, arrayOrg, key_1, key_2, inputID_1, inputID_2, message) => {
    let duplicado = false
    let groupIndexDuplicado = []
    let orgIndexDuplicado = []
    arrayGroup.forEach((data, groupIndex) => {
        arrayOrg.forEach((subdata, orgIndex) => {
            if (key_2 === '') {
                if (data[key_1] === subdata) {
                    duplicado = true
                    groupIndexDuplicado.push(groupIndex)
                    orgIndexDuplicado.push(orgIndex)
                }
            } else {
                if (`${data[key_1]} ${data[key_2]}` === subdata) {
                    duplicado = true
                    groupIndexDuplicado.push(groupIndex)
                    orgIndexDuplicado.push(orgIndex)
                }
            }
        })
    })
    if (duplicado) {
        toastr.warning(message, '¡Ooops!')
        groupIndexDuplicado.forEach(data => {
            $(`#${inputID_1}-group-${data}`).addClass('border-danger')
            if (inputID_2 !== '') {
                $(`#${inputID_2}-group-${data}`).addClass('border-danger')
            }
        })
        orgIndexDuplicado.forEach(data => {
            $(`#${inputID_1}${data}`).addClass('border-danger')
            if (inputID_2 !== '') {
                $(`#${inputID_2}${data}`).addClass('border-danger')
            }
        })
    }
    return duplicado
}