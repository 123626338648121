import React, { Fragment, useState, useEffect } from 'react';
// API calls
import { validateToken, credentials } from '../../models/sprUser_model';
// Tools
import toastr from 'toastr';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const SetNewPassword = () => {
    // Variables
    const [userID, setUserID] = useState('');
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const { newPassword, confirmPassword } = password;
    const navigate = useNavigate();
    useEffect(() => {
        let path = window.location.pathname.split("/");
        let values = [];
        if (path.length === 4) {
            values = path[3].split("=");
        }
        validateToken(values[1], values[2]).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        setUserID(values[2]);
                        break;
                    case 1001:
                        Swal.fire({
                            icon: 'error',
                            text: 'El tiempo para recuperar la contraseña ha expirado. ¿Deseas volver a mandar el correo de recuperación de contraseña?',
                            howDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Ir',
                            denyButtonText: `Login`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('sponsor-access/email-password-recovery');
                            } else if (result.isDenied) {
                                navigate('sponsor-access/login');
                            } else {
                                navigate('sponsor-access/login');
                            }
                        });
                        break;
                    default:
                        toastr.error('Problemas al cargar la información. Contacta a soporte. Codigo: ' + response.code + ' Mensaje: ' + response.message, '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
            }
        });
    }, [navigate]);
    return (
        <Fragment>
            <div className="col-12 text-center my-5">
                <h1>Recuperación de contraseña</h1>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-4 col-lg-4 mx-auto rounded mb-5">
                <div className="card shadow">
                    <div className="card-body">
                        <form
                            autoComplete="new-password"
                            onSubmit={e => {
                                e.preventDefault();
                                let regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{6,}$/;
                                if (!regexPassword.test(newPassword)) {
                                    $('#newPassword').addClass('border-danger');
                                    $('#span-1').addClass('border-danger');
                                    toastr.warning('La nueva contraseña no cumple con los requerimientos de seguridad.', '¡Ooops!');
                                    return;
                                }
                                if (!regexPassword.test(confirmPassword)) {
                                    $('#confirmPassword').addClass('border-danger');
                                    $('#span-2').addClass('border-danger');
                                    toastr.warning('La nueva contraseña no cumple con los requerimientos de seguridad.', '¡Ooops!');
                                    return;
                                }
                                if (newPassword !== confirmPassword) {
                                    $('#newPassword').addClass('border-danger');
                                    $('#confirmPassword').addClass('border-danger');
                                    $('#span-1').addClass('border-danger');
                                    $('#span-2').addClass('border-danger');
                                    toastr.warning('Las contraseñas no coinciden.', '¡Ooops!');
                                    return;
                                }
                                credentials(userID, newPassword).then(response => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                            case 200:
                                                Swal.fire({
                                                    icon: 'success',
                                                    text: 'Se asignó correctamente la nueva contraseña.',
                                                    confirmButtonText: 'Login',
                                                }).then(() => {
                                                    navigate('sponsor-access/login');
                                                });
                                                break;
                                            default:
                                                toastr.error('Problemas con la asignación de nueva contraseña. Contacta a soporte. Codigo: ' + response.code + ' Mensaje: ' + response.message, '¡Ooops!');
                                                break;
                                        }
                                    } else {
                                        toastr.error('Problemas con el servidor. Intenta nuevamente más tarde.', '¡Ooops!');
                                    }
                                });
                            }}
                        >
                            <div className="col-12 py-3">
                                <p className="text-center">Ingresa la nueva contraseña</p>
                                <div className="form-group mb-3">
                                    <label htmlFor="newPassword">Nueva contraseña</label>
                                    <div className="input-group input-group-sm input-icon-right">
                                        <input
                                            type="password"
                                            className="form-control form-control-sm"
                                            required
                                            name="newPassword"
                                            id="newPassword"
                                            autoComplete="new-password"
                                            onChange={e => {
                                                $('#newPassword').removeClass('border-danger');
                                                $('#confirmPassword').removeClass('border-danger');
                                                $('#span-1').removeClass('border-danger');
                                                $('#span-2').removeClass('border-danger');
                                                setPassword({
                                                    ...password,
                                                    [e.target.name]: e.target.value
                                                });
                                            }}
                                        />
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text pointer"
                                                id="span-1"
                                                onClick={() => {
                                                    if ($("#newPassword").attr("type") === "password") {
                                                        $("#newPassword").attr("type", "text");
                                                        $("#icon_eye_1").attr("class", "bi bi-eye-slash");
                                                    } else {
                                                        $("#newPassword").attr("type", "password");
                                                        $("#icon_eye_1").attr("class", "bi bi-eye");
                                                    }
                                                }}
                                            >
                                                <i
                                                    className="bi bi-eye"
                                                    id="icon_eye_1"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="confirmPassword">Confirma contraseña</label>
                                    <div className="input-group input-group-sm input-icon-right">
                                        <input
                                            type="password"
                                            className="form-control form-control-sm"
                                            required
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            onChange={e => {
                                                $('#newPassword').removeClass('border-danger');
                                                $('#confirmPassword').removeClass('border-danger');
                                                $('#span-1').removeClass('border-danger');
                                                $('#span-2').removeClass('border-danger');
                                                setPassword({
                                                    ...password,
                                                    [e.target.name]: e.target.value
                                                });
                                            }}
                                        />
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text pointer"
                                                id="span-2"
                                                onClick={() => {
                                                    if ($("#confirmPassword").attr("type") === "password") {
                                                        $("#confirmPassword").attr("type", "text");
                                                        $("#icon_eye_2").attr("class", "bi bi-eye-slash");
                                                    } else {
                                                        $("#confirmPassword").attr("type", "password");
                                                        $("#icon_eye_2").attr("class", "bi bi-eye");
                                                    }
                                                }}
                                            >
                                                <i
                                                    className="bi bi-eye"
                                                    id="icon_eye_2"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <p className="fs-7 text-muted text-center">Utiliza más de 6 carácteres, combinando letras, números y simbolos (!@#$%^&*_-.)</p>
                            </div>
                            <div className="col-12 py-3 d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                >Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SetNewPassword;
