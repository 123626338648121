import React, { useEffect, useState } from 'react';
// API calls
import { getAllOrdersOfBusiness } from '../../../../models/shop_model';
// Components
import ApplicationMenu from './Menu';
import StatusTable from './StatusTable';
import StatusDetails from './StatusDetails';
import StatusNotes from './StatusNotes';

const StatusController = () => {
    // Global variables
    const [listOrders, setListOrders] = useState([]);
    const [listClosed, setListClosed] = useState([]);
    const [listPending, setListPending] = useState([]);
    const [listProgressing, setListProgressing] = useState([]);
    const [notes, setNotes] = useState([]);
    const [catStatus, setCatStatus] = useState([]);
    const [details, setDetails] = useState({});
    const [scoreboard, setScoreboard] = useState({
        closed: 0,
        pending: 0,
        progressing: 0
    });
    const [showDetails, setShowDetails] = useState(false);
    const [showTable, setShowTable] = useState(0);
    const [orderID, setOrderID] = useState(0);
    useEffect(() => {
        getAllOrdersOfBusiness().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setListOrders(response.listOrders);
                        setListClosed(response.listClosed);
                        setListPending(response.listPending);
                        setListProgressing(response.listProgressing);
                        setCatStatus(response.catStatus);
                        setScoreboard({
                            closed: response.closed,
                            pending: response.pending,
                            progressing: response.progressing
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }, []);
    const reloadData = () => {
        getAllOrdersOfBusiness().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        setListOrders(response.listOrders);
                        setListClosed(response.listClosed);
                        setListPending(response.listPending);
                        setListProgressing(response.listProgressing);
                        setCatStatus(response.catStatus);
                        setScoreboard({
                            closed: response.closed,
                            pending: response.pending,
                            progressing: response.progressing
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }
    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <h3 className="text-center text-sm-start">Solicitudes servicios gratuitos</h3>
                </div>
                <div className='col-12'>
                    <div className='card rounded-25-px shadow border-0 generic__show-main-container container-1'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className="col-12 mt-3 mb-5">
                                    <ApplicationMenu
                                        scoreboard={scoreboard}
                                        setShowTable={setShowTable}
                                        showDetails={showDetails}
                                        showTable={showTable}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        {
                                            showDetails === true ? (
                                                <div className='d-md-flex justify-content-around p-3'>
                                                    <StatusDetails
                                                        setShowDetails={setShowDetails}
                                                        details={details}
                                                        catStatus={catStatus}
                                                        listOrders={listOrders}
                                                        orderID={orderID}
                                                        reloadData={reloadData}
                                                    />
                                                    <StatusNotes
                                                        notes={notes}
                                                    />
                                                </div>
                                            ) : (
                                                <StatusTable
                                                    setShowDetails={setShowDetails}
                                                    setDetails={setDetails}
                                                    setNotes={setNotes}
                                                    showTable={showTable}
                                                    listClosed={listClosed}
                                                    listPending={listPending}
                                                    listProgressing={listProgressing}
                                                    setOrderID={setOrderID}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default StatusController;
