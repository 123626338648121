import React, { useState } from 'react'
import {
    sortArray,
    handleDropdown,
} from '../helpers/helpers'

const Panel = ({
    // TODO: Hooks
    plans,
    // TODO: Functions
    handleOnClick,
}) => {
    const [showMessage, setShowMessage] = useState(false)
    return (
        <div className='col-12'>
            <div className='card border-0 shadow rounded-25px generic__show-main-container container-1'>
                <div className='card-body'>
                    <div className='col-12 generic__show-container top container-2'>
                        <div className='row d-flex justify-content-end'>
                            <div className='col-sm-12 col-lg-5 col-xxl-4'>
                                <div className='input-group mb-3'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Buscar'
                                        onChange={e => setShowMessage(sortArray(e, 'listPlan', 'plan-card', 'span'))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-11 mx-auto generic__show-container left container-1'>
                        {showMessage && <div className='text-center fw-bold'>No hay resultados</div>}
                        <div
                            className='row'
                            id='listPlan'
                        >
                            {plans.map(plan => (
                                <div
                                    className='col-12'
                                    key={`plan-${plan.idPlanPromotion}`}
                                >
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div
                                                className='card plan-card'

                                            >
                                                <div className='card-body'>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col-10'>
                                                                <span
                                                                    className='fw-bold pointer text-cian-hover'
                                                                    onClick={() => handleOnClick(plan)}
                                                                >{plan.title}</span>
                                                            </div>
                                                            <div className='col-2'>
                                                                <img
                                                                    src='../assets/img/icons/Icono_agregar_activo.png'
                                                                    id={`imgDetail-${plan.idPlanPromotion}`}
                                                                    className='mx-3 icon-size-20x20 pointer imgDetails float-end'
                                                                    alt='img-desplegar'
                                                                    onClick={() => handleDropdown(plan.idPlanPromotion)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='col-12 d-none'
                                            id={`divDetalle-${plan.idPlanPromotion}`}
                                        >
                                            <div className='col-12 px-3 py-1 bg-light-blue'>
                                                <div className='row generic__show-container left container-2'>
                                                    {plan.relPlanPromotionList.map(promotion => (
                                                        <div
                                                            className='col-3 py-2'
                                                            key={`promotion-${promotion.idPromotion}`}
                                                        >
                                                            <div className='col-12 py-3 pill-span bg-white text-center'>
                                                                <span>{promotion.title}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Panel