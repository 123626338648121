// TODO: API calls
import { getListOrganizationInfo } from "../models/organization_model"

// const
const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST'
const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS'
const GET_ORGANIZATION_LIST_ERROR = 'GET_ORGANIZATION_LIST_ERROR'
const CLEAN_ORGANIZATION_LIST = 'CLEAN_ORGANIZATION_LIST'

const initialState = {
    fetching: false,
    error: false,
    organizationList: [],
}

// Redux
const organizationListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZATION_LIST:
            return {
                ...state,
                fetching: true,
                organizationList: [],
            }

        case GET_ORGANIZATION_LIST_SUCCESS:
            return {
                ...action.payload
            }

        case GET_ORGANIZATION_LIST_ERROR:
            return {
                fetching: false,
                error: {
                    message: action.payload,
                },
                organizationList: [],
            }

        case CLEAN_ORGANIZATION_LIST:
            return {
                ...initialState,
            }

        default:
            return state
    }
}

export default organizationListReducer

const saveStorage = function (organizations) {
    sessionStorage.setItem('organizations', btoa(JSON.stringify(organizations)))
}

export const getOrganizations = () => (dispatch, getState) => {
    dispatch({
        type: GET_ORGANIZATION_LIST
    })
    getListOrganizationInfo().then((response) => {
        if (response.code === 1) {
            dispatch({
                type: GET_ORGANIZATION_LIST_SUCCESS,
                payload: {
                    fetching: true,
                    error: false,
                    organizationList: response.getListOrganizationInfo,
                },
            })
            saveStorage(getState().organizationList)
        } else {
            dispatch({
                type: GET_ORGANIZATION_LIST_ERROR,
                payload: {
                    message: "Hubo un problema para obtener las organizaciones. Contacte al equipo de soporte o intente nuevamente más tarde.",
                },
            })
        }
    })
}

export const restoreOrganizationAction = () => (dispatch) => {
    if (sessionStorage.getItem('organizations') !== null) {
        const organizations = JSON.parse(atob(sessionStorage.getItem('organizations')))
        dispatch({
            type: GET_ORGANIZATION_LIST_SUCCESS,
            payload: organizations,
        })
    }
}

export const cleanOrganization = () => (dispatch, _getState) => {
    dispatch({
        type: CLEAN_ORGANIZATION_LIST,
    })
}