import React from 'react'

const Menu = ({
    scoreboard,
    showDetails,
    showTable,
    setShowTable,
}) => {
    const {
        fichasCounter,
        postersCounter,
        webinarsCounter,
        infografiaCounter,
    } = scoreboard;
    return (
        <div className="row d-flex justify-content-around">
            <div
                className={`col-sm-12 col-lg-2 col-xxl-2 ${showTable === 0 ? 'bg-green-guay' : 'bg-secondary'} text-white rounded-3 pointer generic__show-container container-2 mb-1`}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(0)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Fichas</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{fichasCounter}</h1>
                    </div>
                </div>
            </div>
            <div
                className={`col-sm-12 col-lg-2 col-xxl-2 ${showTable === 1 ? 'bg-pink-guay' : 'bg-secondary'} text-white rounded-3 pointer generic__show-container container-2 mb-1`}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(1)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Posters</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{postersCounter}</h1>
                    </div>
                </div>
            </div>
            <div
                className={`col-sm-12 col-lg-2 col-xxl-2 ${showTable === 2 ? 'bg-blue-guay' : 'bg-secondary'} text-white rounded-3 pointer generic__show-container container-2 mb-1`}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(2)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Videos</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{webinarsCounter}</h1>
                    </div>
                </div>
            </div>
            <div
                className={`col-sm-12 col-lg-2 col-xxl-2 ${showTable === 3 ? 'bg-yellow-guay' : 'bg-secondary'} text-white rounded-3 pointer generic__show-container container-2 mb-1`}
                onClick={() => {
                    if (showDetails !== true) {
                        setShowTable(3)
                    }
                }}
            >
                <div className='row generic__show-container container-3'>
                    <div className='col-12 text-center'>
                        <h5 className='m-0'>Infografía</h5>
                    </div>
                    <div className='col-12 text-center'>
                        <h1 className='fw-bold m-0'>{infografiaCounter}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu
