import React from 'react'
import $ from "jquery"

const ModalPreview = ({
    file,
    fileType,
    cleanFunction,
}) => {
    return (
        <div
            className="modal"
            id="modalPreview"
            style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                cleanFunction()
                                $('#modalPreview').hide()
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {fileType === 1 && (
                            <iframe
                                src={file}
                                width="100%"
                                height="780"
                                title='pdf'
                            />
                        )}
                        {fileType === 2 && (
                            <img
                                className='img-fluid'
                                src={file}
                                alt='icon'
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPreview