import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import generateStore from './redux/store';
import './styles/styles.scss';

const store = generateStore();

const WithApp = () => <App />;

const WithStore = () => <Provider store={store}><WithApp /></Provider>;


ReactDOM.render(
  <WithStore />,
  document.getElementById('root')
);