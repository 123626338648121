import React, { useState } from 'react';
// API calls
import { registerUser } from '../../models/sprUser_model';
// Tools
import $ from 'jquery';
import * as toastr from 'toastr';

const SponsorRegister = () => {
    const [registerData, setRegisterData] = useState({
        name: '',
        lastname: '',
        email: '',
        phone: '',
        brand: '',
    });
    const {
        name,
        lastname,
        email,
        phone,
        brand,
    } = registerData;
    const [showSubmit, setShowSubmit] = useState(true);
    const handleSubmit = e => {
        e.preventDefault();
        // Validations
        // eslint-disable-next-line
        let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        /* let regex = /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/; */
        if (name.length === 0) {
            $('#name').addClass("border-danger");
            toastr.warning('Nombre es obligatorio.', '¡Ooops!');
            return;
        }
        if (lastname.length === 0) {
            $('#lastname').addClass("border-danger");
            toastr.warning('Apellido es obligatorio.', '¡Ooops!');
            return;
        }
        if (email.length === 0) {
            $('#email').addClass("border-danger");
            toastr.warning('Correo electrónico es obligatorio.', '¡Ooops!');
            return;
        }
        if (!regex.test(email)) {
            $('#email').addClass("border-danger");
            toastr.warning('El formato del correo electrónico no es valido.', '¡Ooops!');
            return;
        }
        if (phone.length > 0) {
            if (phone.length !== 10) {
                $('#phone').addClass("border-danger");
                toastr.warning('Teléfono debe ser a 10 dígitos.', '¡Ooops!');
                return;
            }
        } else {
            $('#phone').addClass("border-danger");
            toastr.warning('Teléfono es obligatorio.', '¡Ooops!');
            return;
        }
        if (brand.length === 0) {
            $('#brand').addClass("border-danger");
            toastr.warning('Empresa / Marca es obligatorio.', '¡Ooops!');
            return;
        }
        // Send request
        setShowSubmit(false);
        let formData = new FormData();
        let data = {
            email: email,
            name: name,
            lastName: lastname,
            phone: phone,
            brand: brand,
        };
        formData.append("request", JSON.stringify(data));
        registerUser(formData).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        toastr.success('Se registró correctamente.', '¡Éxito!');
                        setRegisterData({
                            name: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            brand: '',
                        });
                        setShowSubmit(true);
                        break;
                    case 1001:
                        toastr.error("El usuario ya fue registrado.", "¡Ooops!");
                        setShowSubmit(true);
                        break;
                    default:
                        toastr.error("Error interno. Comunicarse con el equipo de soporte. " + response.message, "¡Ooops!");
                        setShowSubmit(true);
                        break;
                }
            } else {
                toastr.error("Problemas con el servidor. Intenta nuevamente más tarde.", "¡Ooops!");
                setShowSubmit(true);
            }
        });
    }
    return (
        <div className="col-12 my-5">
            <div className="row">
                <div className="col-11 mx-auto mb-3">
                    <h3>Registro</h3>
                </div>
                <div className="col-11 mx-auto">
                    <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
                        <div className='card-body'>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container top container-1'>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 col-xxl-6">
                                            <div className="input-group input-group-guay mb-3">
                                                <span className="input-group-text fw-bold w-35">Nombre</span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nombre"
                                                    id="name"
                                                    name="name"
                                                    value={name}
                                                    onChange={e => {
                                                        $('#name').removeClass('border-danger');
                                                        setRegisterData({
                                                            ...registerData,
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 col-xxl-6">
                                            <div className="input-group input-group-guay mb-3">
                                                <span className="input-group-text fw-bold w-35">Apellidos</span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Apellidos"
                                                    id="lastname"
                                                    name="lastname"
                                                    value={lastname}
                                                    onChange={e => {
                                                        $('#lastname').removeClass('border-danger');
                                                        setRegisterData({
                                                            ...registerData,
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container top container-1'>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 col-xxl-6">
                                            <div className="input-group input-group-guay mb-3">
                                                <span className="input-group-text fw-bold w-35">Correo electrónico</span>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Ej. correo@correo.com"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={e => {
                                                        $('#email').removeClass('border-danger');
                                                        setRegisterData({
                                                            ...registerData,
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 col-xxl-6">
                                            <div className="input-group input-group-guay mb-3">
                                                <span className="input-group-text fw-bold w-35">Teléfono</span>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Teléfono (10 dígitos)"
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                    id="phone"
                                                    name="phone"
                                                    value={phone}
                                                    onChange={e => {
                                                        $('#phone').removeClass('border-danger');
                                                        if (e.target.value.length < 11) {
                                                            setRegisterData({
                                                                ...registerData,
                                                                [e.target.name]: e.target.value
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container container-1'>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 col-xxl-6">
                                            <div className="input-group input-group-guay mb-3">
                                                <span className="input-group-text fw-bold w-35">Empresa / Marca</span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Empresa / Marca"
                                                    id="brand"
                                                    name="brand"
                                                    value={brand}
                                                    onChange={e => {
                                                        $('#brand').removeClass('border-danger');
                                                        setRegisterData({
                                                            ...registerData,
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 col-xxl-6"></div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end generic__show-container container-1">
                                    {
                                        showSubmit === true ? (
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-primary btn-pink-guay"
                                            >Guardar</button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-primary btn-pink-guay"
                                                disabled
                                            >
                                                <span className="spinner-grow spinner-grow-sm"></span>
                                                Cargando
                                            </button>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SponsorRegister
