import React, { useState } from 'react';
import { setUpdateBenefits } from '../../models/benefits_model';
import $ from 'jquery';
import * as toastr from 'toastr';
import ModalPreview from '../genericComponents/ModalPreview';
import GenericNumberInput from '../genericComponents/GenericNumberInput';
import { handleScroll } from '../../helpers/genericFunc';

import DragDrop from '../plans/generic/DragDrop';

export const BenefitForm = ({
    // TODO: Hooks
    columns,
    setColumns,
    benefitForm,
    setBenefitForm,
    // TODO: Functions
    handleClickReturn,
}) => {
    const [previewFile, setPreviewFile] = useState('')
    const [previewFileType, setPreviewFileType] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault();
        /* if (benefitForm.title === "" || benefitForm.nameEnterprise === "" || benefitForm.description === "" || benefitForm.urlEnterprise === "") {
            toastr.info("Debes llenar todos los campos");
        } else { */
        if (handleRequiredInputs()) {
            console.log('Pasa')
            setUpdateBenefits(benefitForm, benefitForm.urlLogo, benefitForm.urlResource, benefitForm.urlManual, benefitForm.urlDigdesc, columns[2].items).then(response => {
                switch (response?.code) {
                    case 1:
                        toastr.success("Beneficio guardado correctamente.");
                        setBenefitForm({
                            inEdition: false,
                            urlLogo: '',
                            idBenefit: 0,
                            urlEnterprise: '',
                            title: '',
                            nameEnterprise: '',
                            description: '',
                            active: 1,
                            urlResource: '',
                            phone: '',
                            whatsapp: '',
                            urlContact: '',
                            email: '',
                            urlManual: '',
                            urlDigdesc: '',
                            flgGral: 0,
                            urlService: '',
                        });
                        break;

                    default:
                        toastr.error("Ocurrió un error desconocido");
                        break;
                }
            })
        }
    }

    const handleRequiredInputs = () => {
        let validated = true
        if (benefitForm.title.trim() === "") {
            $('#title').addClass('border-danger')
            toastr.info("El campo Titulo es obligatorio")
            handleScroll('title')
            return false
        }
        if (benefitForm.nameEnterprise.trim() === "") {
            $('#nameEnterprise').addClass('border-danger')
            toastr.info("El campo Nombre es obligatorio")
            handleScroll('nameEnterprise')
            return false
        }
        if (benefitForm.description.trim() === "") {
            $('#description').addClass('border-danger')
            toastr.info("El campo Descripción es obligatorio")
            handleScroll('description')
            return false
        }
        if (benefitForm.phone.trim().length > 0) {
            if (benefitForm.phone.trim().length !== 10) {
                $('#phone').addClass('border-danger')
                toastr.info('El campo Teléfono es obligatorio')
                handleScroll('phone')
                return false
            }
        }
        if (benefitForm.whatsapp.trim().length > 0) {
            if (benefitForm.whatsapp.trim().length !== 10) {
                $('#whatsapp').addClass('border-danger')
                toastr.info('El campo Whatsapp es obligatorio')
                handleScroll('whatsapp')
                return false
            }
        }
        return validated
    }

    const {
        urlLogo,
        urlEnterprise,
        title,
        nameEnterprise,
        description,
        active,
        urlResource,
        phone,
        whatsapp,
        urlContact,
        email,
        urlManual,
        urlDigdesc,
        flgGral,
        urlService,
    } = benefitForm;

    const handleInputChange = ({ target }) => {
        $('input').removeClass('border-danger')
        if (target.name === 'active' || target.name === 'flgGral') {
            if (target.checked === true) {
                setBenefitForm(prevState => ({
                    ...prevState,
                    [target.name]: 1
                }));
            } else {
                setBenefitForm(prevState => ({
                    ...prevState,
                    [target.name]: 0
                }));
            }
        } else {
            setBenefitForm(prevState => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    }

    const handleCleanViwer = () => {
        setPreviewFile('')
        setPreviewFileType(0)
    }

    const handlePreview = (srcFile) => {
        if (typeof srcFile === 'string') {
            if (srcFile.includes('pdf')) {
                $('#modalPreview').show()
                setPreviewFile(srcFile)
                setPreviewFileType(1)
                return
            }
            if (srcFile.includes('jpeg') || srcFile.includes('jpg') || srcFile.includes('png')) {
                $('#modalPreview').show()
                setPreviewFile(srcFile)
                setPreviewFileType(2)
                return
            }
            toastr.info('Lo sentimos, no se puede previsualizar ese tipo de documento.', '¡Ooops!')
            window.open(srcFile)
        } else {
            if (srcFile.name.split('.').pop() === 'pdf') {
                //Create a Blob from the PDF Stream
                const file = new Blob([srcFile], { type: `${srcFile.type}` });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on modal
                //window.open(fileURL);
                $('#modalPreview').show()
                setPreviewFile(fileURL)
                setPreviewFileType(1)
                return
            }
            if (srcFile.name.split('.').pop() === 'jpeg' || srcFile.name.split('.').pop() === 'jpg' || srcFile.name.split('.').pop() === 'png') {
                //Create a Blob from the PDF Stream
                const file = new Blob([srcFile], { type: `${srcFile.type}` });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                setPreviewFile(fileURL)
                setPreviewFileType(2)
                $('#modalPreview').show()
                return
            }
            toastr.info('Lo sentimos, no se puede previsualizar ese tipo de documento.', '¡Ooops!')
            //Create a Blob from the PDF Stream
            const file = new Blob([srcFile], { type: `${srcFile.type}` })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
        }
    }

    return (
        <div className="row">
            <ModalPreview
                file={previewFile}
                fileType={previewFileType}
                cleanFunction={handleCleanViwer}
            />
            <div className="col-12">
                <div className='card border-0 shadow rounded-25px generic__show-main-container container-1'>
                    <div className='card-body'>
                        <form className='row' onSubmit={handleSubmit}>
                            <div className='col-12 mb-5'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container top container-2'>
                                        <div className='text-center'>
                                            <input type="file"
                                                className="visually-hidden"
                                                id="uploadLogo" name="urlLogo"
                                                accept="image/png, image/jpeg"
                                                onChange={e => {
                                                    if (e.target.files.length > 0) {
                                                        let reader = new FileReader();
                                                        let file = e.target.files[0];
                                                        if (file.size < 2000000) {
                                                            let ext = file.name.split('.').pop();
                                                            ext = ext.toLowerCase();
                                                            switch (ext) {
                                                                case 'jpg':
                                                                case 'jpeg':
                                                                case 'png':
                                                                    reader.onload = function (e) {
                                                                        $('#enterpriseLogo').attr('src', e.target.result);
                                                                    };
                                                                    reader.readAsDataURL(e.target.files[0]);
                                                                    setBenefitForm(prevSatate => {
                                                                        return {
                                                                            ...prevSatate,
                                                                            urlLogo: file
                                                                        }
                                                                    });
                                                                    break;
                                                                default:
                                                                    toastr.warning("El formato debe ser jpg, jpeg o png.", "¡Ooops!");
                                                                    break;
                                                            }
                                                        } else {
                                                            toastr.warning("El peso de la imagen debe ser menor a 2 MB.", "¡Ooops!");
                                                        }
                                                    }
                                                }}
                                            />
                                            <img
                                                src={urlLogo}
                                                id="enterpriseLogo"
                                                alt="logo empresa"
                                                className="img-thumbnail pointer pull-up"
                                                style={{
                                                    width: '30%',
                                                    aspectRatio: '3/2',
                                                    objectFit: 'contain',
                                                }}
                                                onClick={() => { $('#uploadLogo').trigger('click') }}
                                            />
                                            <br /><small className='fw-bold'>Logo</small>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container top container-2'>
                                        <div className='text-center'>
                                            <input type="file"
                                                className="visually-hidden"
                                                id="uploadResource" name="urlResource"
                                                accept="image/png, image/jpeg"
                                                onChange={e => {
                                                    if (e.target.files.length > 0) {
                                                        let reader = new FileReader();
                                                        let file = e.target.files[0];
                                                        if (file.size < 2000000) {
                                                            let ext = file.name.split('.').pop();
                                                            ext = ext.toLowerCase();
                                                            switch (ext) {
                                                                case 'jpg':
                                                                case 'jpeg':
                                                                case 'png':
                                                                    reader.onload = function (e) {
                                                                        $('#enterpriseResource').attr('src', e.target.result);
                                                                    };
                                                                    reader.readAsDataURL(e.target.files[0]);
                                                                    setBenefitForm(prevSatate => {
                                                                        return {
                                                                            ...prevSatate,
                                                                            urlResource: file
                                                                        }
                                                                    });
                                                                    break;
                                                                default:
                                                                    toastr.warning("El formato debe ser jpg, jpeg o png.", "¡Ooops!");
                                                                    break;
                                                            }
                                                        } else {
                                                            toastr.warning("El peso de la imagen debe ser menor a 2 MB.", "¡Ooops!");
                                                        }
                                                    }
                                                }}
                                            />
                                            <img
                                                src={urlResource}
                                                id="enterpriseResource"
                                                alt="Recurso empresa"
                                                className="img-thumbnail pointer pull-up"
                                                style={{
                                                    width: '30%',
                                                    aspectRatio: '3/2',
                                                    objectFit: 'contain',
                                                }}
                                                onClick={() => { $('#uploadResource').trigger('click') }}
                                            />
                                            <br /><small className='fw-bold'>Recurso</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Título</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="Título"
                                        name="title"
                                        id="title"
                                        value={title} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Nombre</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="Nombre"
                                        name="nameEnterprise"
                                        id="nameEnterprise"
                                        value={nameEnterprise} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Descripción</span>
                                    <textarea
                                        type="text"
                                        className="form-control textarea"
                                        //placeholder="Descripción"
                                        name="description"
                                        id="description"
                                        value={description} onChange={handleInputChange}
                                        rows='3'
                                    ></textarea>
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Web</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="Web"
                                        name="urlEnterprise"
                                        id="urlEnterprise"
                                        value={urlEnterprise} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Utilizar servicio</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="Web"
                                        name="urlService"
                                        id="urlService"
                                        value={urlService} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Teléfono</span>
                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="458 123 4567"
                                        name="phone"
                                        value={phone} onChange={handleInputChange}
                                    /> */}
                                    <GenericNumberInput
                                        generalValue={benefitForm}
                                        value={phone}
                                        setValue={setBenefitForm}
                                        maxValue={10}
                                        name={'phone'}
                                        id={'phone'}
                                        className={'form-control'}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Whatsapp</span>
                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="458 123 4567"
                                        name="whatsapp"
                                        value={whatsapp} onChange={handleInputChange}
                                    /> */}
                                    <GenericNumberInput
                                        generalValue={benefitForm}
                                        value={whatsapp}
                                        setValue={setBenefitForm}
                                        maxValue={10}
                                        name={'whatsapp'}
                                        id={'whatsapp'}
                                        className={'form-control'}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">URL de contacto</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        //placeholder="https://somosguay/contacto"
                                        name="urlContact"
                                        id="urlContact"
                                        value={urlContact} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-15">Correo</span>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        //placeholder="guay@guay.digital"
                                        name="email"
                                        id="email"
                                        value={email} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <DragDrop
                                columns={columns}
                                setColumns={setColumns}
                                idKey={'idPlanPromotion'}
                            />
                            <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container top container-2 mt-3'>
                                <div className='text-center'>
                                    {urlManual !== '' && (
                                        <span
                                            className='me-5 fw-bold pointer'
                                            onClick={() => handlePreview(urlManual)}
                                        ><i className="bi bi-eye font-weight-bold align-middle me-1" /><small>Ver manual</small></span>
                                    )}
                                    {/* {
                                        urlManual !== '' ?
                                            (
                                                <span className="text-base fake-select pointer" onClick={() => window.open(urlManual)} style={{ marginRight: "5px" }}>
                                                    <i className="bi bi-file-earmark-arrow-down font-weight-bold align-middle mr-2" style={{ color: "#2188b4" }}></i>
                                                </span>

                                            ) : null
                                    } */}
                                    <span className="text-base fake-select pointer" onClick={() => { $('#uploadManual').trigger('click') }}>
                                        <input
                                            type="file"
                                            id="uploadManual" name="urlManual"
                                            className="visually-hidden"
                                            accept="image/png, image/jpg, image/jpeg, application/pdf, .doc, .docx, .ppt, .pptx"
                                            onChange={e => {
                                                if (e.target.files.length > 0) {
                                                    let reader = new FileReader();
                                                    let file = e.target.files[0];
                                                    if (file.size <= 0) {
                                                        toastr.warning("El archivo no tiene contenido o esta dañado.", "¡Ooops!");
                                                        $('#lblManual').text(' Manual');

                                                    } else if (file.size < 10000000) {
                                                        let ext = file.name.split('.').pop();
                                                        ext = ext.toLowerCase();
                                                        switch (ext) {
                                                            case 'jpg':
                                                            case 'jpeg':
                                                            case 'png':
                                                            case 'pdf':
                                                            case 'doc':
                                                            case 'docx':
                                                            case 'ppt':
                                                            case 'pptx':
                                                                reader.onload = function () {
                                                                    $('#lblManual').text(` Manual: ${file.name}`);
                                                                };
                                                                reader.readAsDataURL(e.target.files[0]);
                                                                setBenefitForm(prevSatate => {
                                                                    return {
                                                                        ...prevSatate,
                                                                        urlManual: file
                                                                    }
                                                                });
                                                                break;
                                                            default:
                                                                toastr.warning("El formato debe ser jpg, jpeg, png, pdf, doc, docx, ppt, pptx.", "¡Ooops!");
                                                                break;
                                                        }
                                                    } else {
                                                        toastr.warning("El archivo es mayor a 10MB.", "¡Ooops!");
                                                    }


                                                } else {
                                                    $('#lblManual').text(' Manual');
                                                }
                                            }}
                                        />
                                        <i className="bi bi-upload font-weight-bold align-middle me-1"></i>
                                        <small id="lblManual" className='fw-bold'>Subir Manual</small>
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-6 col-xxl-6 generic__show-container top container-2 mt-3'>
                                <div className='text-center'>
                                    {urlDigdesc !== '' && (
                                        <span
                                            className='me-5 fw-bold pointer'
                                            onClick={() => handlePreview(urlDigdesc)}
                                        ><i className="bi bi-eye font-weight-bold align-middle me-1" /><small>Ver Descripción Digital</small></span>
                                    )}
                                    {/* {
                                        urlDigdesc !== '' && urlDigdesc !== '' ?
                                            (
                                                <span className="text-base fake-select pointer" onClick={() => window.open(urlManual)} style={{ marginRight: "5px" }}>
                                                    <i className="bi bi-file-earmark-arrow-down font-weight-bold align-middle mr-2" style={{ color: "#2188b4" }}></i>
                                                </span>

                                            ) : null
                                    } */}
                                    <span className="text-base fake-select pointer" onClick={() => { $('#uploadDigdesc').trigger('click') }}>
                                        <input
                                            type="file"
                                            id="uploadDigdesc" name="urlDigdesc"
                                            className="visually-hidden"
                                            accept="image/png, image/jpeg, image/jpeg, application/pdf, .doc, .docx, .ppt, .pptx"
                                            // value={urlDigdesc}
                                            onChange={e => {
                                                if (e.target.files.length > 0) {
                                                    let reader = new FileReader();
                                                    let file = e.target.files[0];
                                                    if (file.size <= 0) {
                                                        toastr.warning("El archivo no tiene contenido o esta dañado.", "¡Ooops!");
                                                        $('#lblDigdesc').text(' Descripción Digital');

                                                    } else if (file.size < 10000000) {
                                                        let ext = file.name.split('.').pop();
                                                        ext = ext.toLowerCase();
                                                        switch (ext) {
                                                            case 'jpg':
                                                            case 'jpeg':
                                                            case 'png':
                                                            case 'pdf':
                                                            case 'doc':
                                                            case 'docx':
                                                            case 'ppt':
                                                            case 'pptx':
                                                                reader.onload = function () {
                                                                    $('#lblDigdesc').text(` Descripción Digital: ${file.name}`);
                                                                };
                                                                reader.readAsDataURL(e.target.files[0]);
                                                                setBenefitForm(prevSatate => {
                                                                    return {
                                                                        ...prevSatate,
                                                                        urlDigdesc: file
                                                                    }
                                                                });
                                                                break;
                                                            default:
                                                                toastr.warning("El formato debe ser jpg, jpeg, png, pdf, doc, docx, ppt, pptx.", "¡Ooops!");
                                                                break;
                                                        }
                                                    }


                                                } else {
                                                    $('#lblDigdesc').text(' Descripción Digital');
                                                }
                                            }}
                                        />
                                        <i className="bi bi-upload font-weight-bold align-middle"></i>
                                        <small id="lblDigdesc" className='fw-bold'> Descripción Digital</small>
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1 mt-3'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className="input-group input-group-guay mb-3 d-flex justify-content-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="active"
                                                    checked={active === 1 ? true : false}
                                                    onChange={handleInputChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                >{'Activo'}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="input-group input-group-guay mb-3 d-flex justify-content-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="flgGral"
                                                    checked={flgGral === 1 ? true : false}
                                                    onChange={handleInputChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                >Beneficio general</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container container-1 d-flex justify-content-between">
                                <button type='button' className='btn btn-sm bg-blue-navy text-white' onClick={handleClickReturn}>Regresar</button>
                                <input type="submit" className="btn btn-sm btn-pink-guay text-white" value="Guardar" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
