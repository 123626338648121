import axios from 'axios';
/**
 * Get specific enterprise by id
 * @param {number} idEnterprise 
 * @returns 
 */
export const getEnterpriseByID = async (idEnterprise) => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "enterprise/getEnterprise?idEnterprise=" + idEnterprise,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get active enterprises
 * @returns 
 */

export const getEnterpriseActive = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "enterprise/getEnterpriseActive",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get enterprises wit aws active/not active realation 
 * @returns 
 */

export const getListEnterpriseAwsRelation = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "aws/getListEnterpriseAwsRelation",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getProductsEnterprise = async (idEnterprise) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "enterprise/getProductsEnterprise?idEnterprise=" + idEnterprise,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Send email to password recovery
 * @param {string} email 
 * @returns 
 */
export const setEnterpriseAwsRelation = async (idEnterprise, idUser, active) => {

    let formData = new FormData();
    formData.append("idEnterprise", idEnterprise);
    formData.append("idUser", idUser);
    formData.append("active", active);

    try {
        let result = await axios({
            url: global.base_url + "aws/setEnterpriseAwsRelation",
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getLaunchsByService = async (idEnterprise, idProduct, idCategory, idSubcategory) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "enterprise/launch/getLaunchsByService?idEnterprise=" + idEnterprise + "&idProduct=" + idProduct + "&idCategory=" + idCategory + "&idSubcategory=" + idSubcategory,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * Get jobs by enterprise
 * @param {number} enterpriseId 
 * @returns 
 */
/* export const getEnterpriseJob = async (enterpriseId) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/getEnterpriseJob?enterpriseId=${enterpriseId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}; */
/**
 * Get bosses by enterprise
 * @param {number} enterpriseId 
 * @returns 
 */
/* export const getBossEnterprise = async (enterpriseId) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/getBossEnterprise?enterpriseId=${enterpriseId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}; */

/**
 * Get branches by enterprise
 * @param {number} enterpriseId 
 * @returns JSON response
 */
export const getBranches = async (enterpriseId) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/getBranches?enterpriseId=${enterpriseId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} enterpriseId 
 * @returns 
 */
export const getCategoryJob = async (enterpriseId) => {
    try {
        let result = await axios({
            url: `${global.base_url}catalogs/getEnterprisesCategory?enterpriseId=${enterpriseId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} enterpriseId 
 * @returns 
 */
export const getAreas = async (enterpriseId) => {
    try {
        let result = await axios({
            url: `${global.base_url}catalogs/getEnterpriseArea?idEnterprise=${enterpriseId}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 *
 * @param {number} enterpriseId
 * @param {number} idGroup
 * @returns
 */
export const getCollabWithOutAssingSpecificLaunch = async (enterpriseId, idGroup, idRol, idUser) => {
    try {
        let result = await axios({
            url: `${global.base_url}enterprise/launch/getCollabWithOutAssingSpecificLaunch?idEnterprise=${enterpriseId}&idGroup=${idGroup}&idRol=${idRol}&idUser=${idUser}`,
            method: 'GET',
            dataType: 'json',
            ContentType: 'application/json',
            headers: {
                Authorization: global.tokenAuth
            }
        })
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setCollabsToSpecificLaunch = async (arrayCollabs, idGroup) => {
    try {
        let formData = new FormData();
        formData.append("arrayCollabs", JSON.stringify(arrayCollabs));
        formData.append("idGroup", idGroup);
        let result = await axios({
            method: 'POST',
            url: global.base_url + "enterprise/launch/setCollabsToSpecificLaunch",
            data: formData,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get list of organizational group with key of active, pending to be active / active, renovation, list of their organizations and organization info
 * @returns 
 */

export const getListOrgGroupInfo = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url_admin + "orgGroupController/getListOrgGroupInfo",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
