import React from 'react';
import { connect } from 'react-redux';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import { AppOutlet } from './AppOutlet';
import { AuthOutlet } from './AuthOutlet';

const AppRouter = ({ isAuthenticated }) => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<AppOutlet isAuthenticated={isAuthenticated} />} />
                <Route path="admin-access/*" element={<AuthOutlet isAuthenticated={isAuthenticated} />} />
            </Routes>
        </BrowserRouter>
    )
}

const mapStateToProps = ({ auth: { logged } }) => {
    return {
        isAuthenticated: logged
    }
}

export default connect(mapStateToProps)(AppRouter);