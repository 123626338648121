import React, { useState, useEffect, useMemo } from 'react';
import { getOrganizationsFree, getSponsors, setUpdateCampaign } from '../../../models/sponsor_model';
import * as toastr from 'toastr';
import { DualList } from '../../genericComponents/DualList';
import Datepicker, { registerLocale } from 'react-datepicker';
import { getDay, addDays } from 'date-fns';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

export const CampaignForm = ({ campaign, setCampaign, handleClickReturn }) => {

    registerLocale('es', es);

    const { idCampaign, title, description, numCollabs, numSponsors, initialDate, finalDate, amount, active } = campaign;

    const [assigns, setAssigns] = useState({
        sponsor: [],
        sponsorAssigned: [],
        organizationsAvailable: [],
        organizationAssigned: [],
    });

    const [returnSelectedOrg, setReturnSelectedOrg] = useState([]);
    const [returnSelectedSpr, setReturnSelectedSpr] = useState([]);

    useEffect(() => {

        getOrganizationsFree(idCampaign).then(response => {
            switch (response?.code) {
                case 1:
                    setAssigns(prevState => ({
                        ...prevState,
                        organizationsAvailable: response.organizationsAvailable.map(organization => ({
                            value: organization.id,
                            label: organization.description,
                            collabs: organization.collabs
                        }
                        )),
                        organizationAssigned: response.organizationsAssigned.map(organization => (
                            organization.id
                        )),
                    }));
                    setReturnSelectedOrg(response.organizationsAssigned.map(organization => (
                        organization.id
                    )));
                    break;

                default:
                    toastr.error("Ocurrió un error inesperado", "getOrganizations");
                    break;
            }
        });

        getSponsors(idCampaign).then(response => {
            switch (response?.code) {
                case 1:
                    setAssigns(prevState => ({
                        ...prevState,
                        sponsor: response.sponsor.map(sponsor => ({
                            value: sponsor.id,
                            label: sponsor.description
                        }
                        )),
                        sponsorAssigned: response.sponsorAssigned.map(sponsor => (
                            sponsor.id
                        )),
                    }));
                    setReturnSelectedSpr(response.sponsorAssigned.map(sponsor => (
                        sponsor.id
                    )));
                    break;

                default:
                    toastr.error("Ocurrió un error inesperado", "getSponsors");
                    break;
            }
        });

    }, [idCampaign]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title !== '' && description !== '' && numCollabs !== 0 && numSponsors !== 0 && initialDate !== '' && finalDate !== '' && active !== '') {
            const campaignSend = {
                ...campaign,
                'orgList': returnSelectedOrg.toString(),
                'sprList': returnSelectedSpr.toString()
            };
            setUpdateCampaign(campaignSend).then(response => {
                switch (response?.code) {
                    case 1:
                        toastr.success("Campaña guardada correctamente");
                        setCampaign({
                            inUse: false,
                            idCampaign: 0,
                            title: '',
                            description: '',
                            initialDate: '',
                            finalDate: '',
                            numCollabs: 0,
                            numSponsors: 0,
                            amount: 0,
                            options: "0",
                            active: 1,
                            orgList: "",
                            sprList: "",
                        });
                        break;

                    default:
                        toastr.error("Ocurrió un error, vuelva a intentarlo mas tarde");
                        break;
                }
            });
        } else {
            toastr.warning('Datos vacíos');
        }
    }

    const handleInputChange = ({ target }) => {
        if (target.name === 'active') {
            if (target.checked === true) {
                setCampaign(prevState => ({
                    ...prevState,
                    [target.name]: 1
                }));
            } else {
                setCampaign(prevState => ({
                    ...prevState,
                    [target.name]: 0
                }));
            }
        } else {
            setCampaign(prevState => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    }

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const convertDateToString = (date) => {
        var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const convertStringToDate = (str) => {
        if (str !== '') {
            var parts = str.match(/(\d+)/g);
            return new Date(parts[0], parts[1] - 1, parts[2]);
        } else {
            return undefined
        }
    }

    const memoInitialDate = useMemo(() => convertStringToDate(initialDate), [initialDate]);
    const memoFinalDate = useMemo(() => convertStringToDate(finalDate), [finalDate]);


    const handleDateChange = (date) => {
        const actualDate = new Date();
        if (addDays(actualDate, 365) <= date) {
            toastr.info("Solo puede seleccionar fechas dentro del periodo de 1 año");
        } else {
            if (date === null) {
                setCampaign(prevState => ({
                    ...prevState,
                    'finalDate': ''
                }));
            } else {
                setCampaign(prevState => ({
                    ...prevState,
                    'finalDate': convertDateToString(date)
                }));
            }
        }
    }

    const ReturnNumCollab = () => {
        const equivalencias = returnSelectedOrg.map(orgId => {
            const org = assigns.organizationsAvailable.find(organization => organization.value === orgId);
            return org.collabs;
        });
        let numCollabs = 0;
        equivalencias.forEach(num => {
            numCollabs = numCollabs + num;
        });
        return numCollabs;
    }

    // eslint-disable-next-line
    const ReturnNumCollabMemo = useMemo(() => ReturnNumCollab(), [returnSelectedOrg]);

    return (
        <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                        <div className='row'>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-25">Nombre</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre de la campaña"
                                        name="title"
                                        value={title} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-25 d-flex flex-wrap align-content-start">Descripción</span>
                                    <textarea
                                        type="text"
                                        className="form-control textarea-unresize"
                                        placeholder="Descripción"
                                        name="description"
                                        value={description} onChange={handleInputChange}
                                        rows='3'
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-11 mx-auto generic__show-container left container-1'>
                        <div className="input-group input-group-guay mb-3">
                            
                        </div>
                    </div>
                    <div className='col-11 mx-auto generic__show-container left container-1'>
                        <div className="input-group input-group-guay mb-3">
                            
                        </div>
                    </div> */}
                    <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                        <div className='row'>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-25">Audiencia</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="numCollabs"
                                        value={numCollabs} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-25">Patrocinadores</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="numSponsors"
                                        value={numSponsors} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                        <div className='row'>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className='input-group input-group-guay mb-3 inline-group-1'>
                                    <span className="input-group-text fw-bold w-25">Fecha inicial</span>
                                    <Datepicker
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        selected={memoInitialDate}
                                        onChange={date => {
                                            if (date === null) {
                                                setCampaign(prevState => ({
                                                    ...prevState,
                                                    'initialDate': ''
                                                }));
                                            } else {
                                                setCampaign(prevState => ({
                                                    ...prevState,
                                                    'initialDate': convertDateToString(date)
                                                }));
                                            }
                                        }}
                                        filterDate={isWeekday}
                                        minDate={addDays(new Date(), 2)}
                                        placeholderText="Selecciona una fecha (dd-MM-yyyy)"
                                        locale="es"
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3 inline-group-1">
                                    <span className="input-group-text fw-bold w-25">Fecha final</span>
                                    <Datepicker
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                        selected={memoFinalDate}
                                        onChange={date => handleDateChange(date)}
                                        filterDate={isWeekday}
                                        minDate={addDays(new Date(), 2)}
                                        placeholderText="Selecciona una fecha (dd-MM-yyyy)"
                                        locale="es"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-11 col-lg-12 col-xxl-11 mx-auto generic__show-container left container-1'>
                        <div className='row'>
                            <div className='col-sm-11 col-lg-6 mx-auto'>
                                <div className="input-group input-group-guay mb-3">
                                    <span className="input-group-text fw-bold w-25">Monto</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Monto"
                                        name="amount"
                                        value={amount} onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-11 col-lg-6 mx-auto d-flex flex-wrap align-content-center'>
                                <div className="input-group d-flex justify-content-center">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="active"
                                            checked={active === 1 ? true : false}
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            className="form-check-label"
                                        >{'Activo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-5 generic__show-container rigth container-2 text-end">
                        <h3 className="text-center">Asignación de organizaciones</h3>
                        <DualList options={assigns.organizationsAvailable} optionsSelected={assigns.organizationAssigned} setReturnSelected={setReturnSelectedOrg} />
                        <span>Colaboradores asignados: <b>{ReturnNumCollabMemo}</b></span>
                    </div>
                    <div className="my-5 generic__show-container left container-3 text-end">
                        <h3 className="text-center">Asignación de patrocinadores</h3>
                        <DualList options={assigns.sponsor} optionsSelected={assigns.sponsorAssigned} setReturnSelected={setReturnSelectedSpr} />
                        <span>Patrocinadores asignados: <b>{returnSelectedSpr.length}</b></span>
                    </div>
                    <div className="col-11 mx-auto generic__show-container container-1 d-flex justify-content-between">
                        <button type='button' className='btn btn-sm bg-blue-navy text-white' onClick={handleClickReturn}>Regresar</button>
                        <input type="submit" className="btn btn-sm btn-pink-guay text-white" value="Guardar" />
                    </div>
                </form>
            </div>
        </div>
    )
}
