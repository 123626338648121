import axios from "axios";

/**
 * Get a list of all applications
 * @returns 
 */
 export const getAllOrdersOfBusiness = async () => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "shop/getAllOrdersOfBusiness",
            method: 'GET',
        });
        return (result.data ? result.data : 0);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Set new application status
 * @param {object} data 
 * @returns 
 */
 export const updStatusOrderFree = async (data) => {
    try {
        let formData = new FormData();
        formData.append("request", JSON.stringify(data));
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "shop/updStatusOrderFree",
            method: 'POST',
            data: formData
        });
        return (result.data ? result.data : 0);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get specific application comertial data
 * @param {number} idOrder 
 * @returns 
 */
 export const getOrderSpecific = async (idOrder) => {
    try {
        let result = await axios({
            headers: {
                Authorization: global.tokenAuth
            },
            url: global.base_url + "shop/getOrderSpecific?idOrder=" + idOrder + "",
            method: 'GET',
        });
        return (result.data ? result.data : 0);
    } catch (error) {
        console.log(error);
    }
};