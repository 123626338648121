import axios from 'axios'
import * as toastr from 'toastr'
/**
 * 
 * @returns 
 */
export const getAllPlansPromotions = async () => {
    try {
        const result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}promotionController/getAllPlansPromotions`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    //toastr.info('No hay promociones activas.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}
/**
 * 
 * @param {*} plan 
 * @param {*} promotionsArr 
 * @returns 
 */
export const savePlanPromotionInfo = async (
    plan,
    promotionsArr,
) => {
    try {
        let formData = new FormData()
        formData.append('idPlan', plan.id)
        formData.append('codePlan', plan.code)
        formData.append('title', plan.title)
        formData.append('description', plan.description)
        formData.append('active', plan.active)
        formData.append('promotions', JSON.stringify(promotionsArr))
        const result = await axios({
            method: 'POST',
            url: `${global.base_url_admin}promotionController/savePlanPromotionInfo`,
            data: formData,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 404:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error);
    }
}
