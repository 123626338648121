import React, { useState } from 'react'
// TODO: API calls
import { setCollabsToSpecificLaunch } from '../../models/enterprise_model'
// TODO: Tools
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table'
import * as toastr from 'toastr'

const CollabTable = ({
    collaborators,
    launchsSelected,
    handleShowTable,
}) => {
    const [selectedCollabs, setSelectedCollabs] = useState([])
    const handleAssignCollaborators = () => {
        let collabIdsArr = []
        selectedCollabs.forEach(e => collabIdsArr.push(e.collaboratorId))
        console.log(collabIdsArr)
        setCollabsToSpecificLaunch(collabIdsArr, launchsSelected).then(response => {
            if (response.code === 1 || response.code === 200) {
                toastr.success('Se asginó correctamente los colaboradores al lanzamiento.', '¡Éxito!');
                handleShowTable(launchsSelected)
            } else {
                toastr.error('No se asginó correctamente los colaboradores al lanzamiento.', '¡Ooops!');
            }
        })
    }
    const handleRowSelect = (row, isSelected, e) => {
        //console.log(row)
        //console.log(isSelected)
        //console.log(e)
        let collabArr = []
        if (isSelected) {
            collabArr = selectedCollabs
            collabArr.push(row)
            setSelectedCollabs(collabArr)
        } else {
            selectedCollabs.forEach(e => {
                if (e.collaboratorId !== row.collaboratorId) {
                    collabArr.push(e)
                }
            })
            setSelectedCollabs(collabArr)
        }
    }
    const handleSelectAll = (isSelected, rows) => {
        //console.log(isSelected)
        //console.log(rows)
        if (isSelected) {
            setSelectedCollabs(rows)
        } else {
            setSelectedCollabs([])
        }
    }
    // Table Props
    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
        hideSizePerPage: true,
        sizePerPage: 10,
    }
    const selectRow = {
        mode: 'checkbox',  // multi select
        clickToSelect: true,
        onSelect: handleRowSelect,
        onSelectAll: handleSelectAll,
    }
    return (
        <div className="col-12 generic__show-container container-1">
            <BootstrapTable
                data={collaborators}
                hover
                striped
                pagination={true}
                options={options}
                tableStyle={{
                    border: 'none',
                    padding: '0px',
                    fontSize: '.8rem',
                    color: '#798a94',
                }}
                trClassName='fs-7 border-0'
                trStyle={{ border: '.5px' }}
                search
                searchPlaceholder='Buscar'
                selectRow={selectRow}
            >
                <TableHeaderColumn
                    dataField="collaboratorId"
                    isKey
                    hidden
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="namesCollab"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >{"Nombre(s)"}</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="lastName"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Apellidos</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="email"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Email</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="categoryEntId"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Cargo</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="areaEntId"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Área</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="branchEntId"
                    dataAlign="center"
                    tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    dataSort={true}
                >Centro de trabajo</TableHeaderColumn>
            </BootstrapTable>
            <div className='col-12 mt-3'>
                <button
                    className='btn btn-sm btn-pink-guay text-white float-end'
                    onClick={handleAssignCollaborators}
                >Asignar a lanzamiento</button>
            </div>
        </div>
    )
}

export default CollabTable