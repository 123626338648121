import React, { useState } from 'react'
// TODO: Generic Func
import { handleScroll } from '../../helpers/genericFunc'

const PromotionsList = ({
    promotions,
    setPromotion,
    setShowForm,
}) => {
    const [showMessage, setShowMessage] = useState(false)
    const handleClick = promotion => {
        setPromotion({
            idPromotion: promotion.idPromotion,
            title: promotion.title,
            nameEnterprise: promotion.nameEnterprise,
            description: promotion.description,
            active: promotion.active,
            urlEnterprise: promotion.urlEnterprise,
            urlLogo: promotion.urlLogo === undefined ? '../assets/img/icons/sin_logo.png' : promotion.urlLogo,
            urlResource: promotion.urlResource === undefined ? '../assets/img/icons/sin_logo.png' : promotion.urlResource,
            phone: promotion.phone,
            whatsapp: promotion.whatsapp === undefined ? '' : promotion.whatsapp,
            urlContact: promotion.urlContact === undefined ? '' : promotion.urlContact,
            email: promotion.email,
            urlManual: promotion.digitalManual === undefined ? '' : promotion.digitalManual,
            urlDigdesc: promotion.digitalDescription === undefined ? '' : promotion.digitalDescription,
            flgGral: promotion.flgGral,
            urlService: promotion.urlService === undefined ? '' : promotion.urlService,
            idPromotionCategory: promotion.idPromotionCategory,
            idPromotionType: promotion.idPromotionType,
        })
        setShowForm(true)
        handleScroll('top-page')
    }
    const sortArray = ({ target }) => {
        let filter, ul, li, a, i, txtValue;
        filter = target.value.toLowerCase();
        ul = document.getElementById('panelPromotions');
        li = ul.getElementsByClassName('promotions-card');
        let records = 0;
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName('span')[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toLowerCase().indexOf(filter) > -1) {
                li[i].style.display = '';
                records = records = 1;
            } else {
                li[i].style.display = 'none';
            }
        }
        if (records === 0) {
            setShowMessage(true)
        } else {
            setShowMessage(false)
        }
    }
    return (
        <div className='row'>
            <div className='col-12'>
                <div className='card shadow border-0 rounded-25px generic__show-main-container container-1'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-11 col-lg-11 col-xxl-12 mx-auto generic__show-container top container-2'>
                                <div className='row d-flex justify-content-end'>
                                    <div className='col-sm-12 col-lg-5 col-xxl-4'>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Buscar'
                                                onChange={sortArray}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 generic__show-container left container-1'>
                                <div
                                    className='row'
                                    id='panelPromotions'
                                >
                                    {showMessage && <h2 className='text-center p-3'>No hay resultados</h2>}
                                    {promotions.map(data => (
                                        <div
                                            key={data.idBenefit}
                                            className='col-sm-11 col-lg-6 col-xxl-4 mb-3 promotions-card'
                                        >
                                            <div
                                                className='card m-0 h-100 border-0 shadow'
                                                onClick={() => handleClick(data)}
                                            >
                                                <div className='card-body'>
                                                    <div className='row h-100'>
                                                        <div className='col-sm-12 col-md-6 col-lg-6 d-flex flex-wrap align-content-between pb-3'>
                                                            <span
                                                                className='invisible'
                                                                style={{ position: 'absolute' }}
                                                            >{data.nameEnterprise}</span>
                                                            <div className='col-12 d-flex justify-content-center'>
                                                                {data.urlLogo === undefined ? (
                                                                    <h2>{data.nameEnterprise}</h2>
                                                                ) : (
                                                                    <img
                                                                        src={data.urlLogo}
                                                                        alt='promotion'
                                                                        style={{
                                                                            height: '50px',
                                                                            maxWidth: '95%',
                                                                            /* width: 'auto', */
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className='col-12'>
                                                                <p className='text-justify fs-7'>{data.description}</p>
                                                            </div>
                                                            <div className='col-12 d-flex justify-content-center'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm bg-blue-navy text-white w-75'
                                                                >Editar</button>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end flex-wrap align-content-end'>
                                                            {data.urlResource === undefined ? (
                                                                <img
                                                                    className='img-responsive'
                                                                    src='../assets/img/icons/sin_logo.png'
                                                                    alt='promotion'
                                                                />
                                                            ) : (
                                                                <img
                                                                    style={{ width: '90%' }}
                                                                    src={data.urlResource}
                                                                    alt='promotion'
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionsList