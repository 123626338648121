import React from 'react'
import $ from 'jquery';
import { setEnterpriseAwsRelation,getEnterpriseActive } from '../../models/enterprise_model';
import * as toastr from 'toastr';

export const ModalConfirmActive = ({dataForm,setdataForm,userId,setEnterprises,setEnterprisesAwsNotActive,setEnterpriseSelected}) => {
    // console.log(dataForm, ' tipo en modal: ', typeof dataForm);
    const {active, enterpriseId, enterpriseName}= dataForm
    const message = active === 1 ? `¿Estas seguro de querer activar la configuración de correo por AWS para la empresa ${enterpriseName}?`: `¿Estas seguro de querer desactivar la configuración de correo por AWS para la empresa ${enterpriseName}?`
    const refreshEnterprises = () => {
        getEnterpriseActive().then(response => {
            if (response) {
                setEnterprises(response)
                setEnterprisesAwsNotActive(response)
                // console.log('response enterprises: ',response);
            }else{
                toastr.error('Hubo un error al cargar las empresas.', '¡Ooops!');
            }
        });
    }
    const handleConfirm = () =>{
        if(enterpriseId === 0 || enterpriseId === '' || enterpriseId === undefined){
            toastr.error("Debe seleccionar una empresa.", "¡Ooops!");
            return false;
        }

        if(userId === 0 || userId=== "" || userId === undefined){
            toastr.error("No se pudo obtener el número de usuario.", "¡Ooops!");
            return false;
        }

        if(active=== "" || active === undefined){
            toastr.error("Debe activar o desactivar la configuración.", "¡Ooops!");
            return false;
        }
        setEnterpriseAwsRelation(enterpriseId,userId,active).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        active === 1 ? toastr.success('Se registró correctamente.', '¡Éxito!') : toastr.success('Se desactivo correctamente.', '¡Éxito!');
                        closeModal()
                        refreshEnterprises()
                        setEnterpriseSelected(0)
                        break;
                    case 99:
                        toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                        break;
                    case 1000:
                        toastr.error("Faltan datos para completar el proceso.", "¡Ooops!");
                        closeModal()
                        break;
                    default:
                        toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                        closeModal()
                        break;
                }
            } else {
                toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.')
                closeModal()
            }
        });
    }

    const closeModal = () =>{
        $("#closeModalActiveAWSRelation").hide()
        setdataForm({
            active:'',
            enterpriseId: '',
            enterpriseName:''
        })
    }
    return (
    <div
        className="modal"
        id="closeModalActiveAWSRelation"
        style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
        data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div
                    className="modal-content"
                    style={{ backgroundColor: 'transparent',borderRadius: '2rem',border: "none" }}
                >
                    <button 
                    type="button" 
                    className="btn-close" 
                    data-bs-dismiss="modal" 
                    style={
                        {
                            position: 'absolute',
                            top: '-10px',
                            right: '-15px',
                            opacity: '1',

                        }
                    }
                    onClick={closeModal}
                    aria-label="Close"></button>

                    <div className="modal-body modal-div-style-1 scrollbar-style">
                        <div className="row mb-1 d-flex justify-content-center">
                            <div className="col-10">
                                <h5 className="text-center fw-bold" >Configuración de correo</h5>
                            </div>
                        </div>
                        <div className="row mb-4 d-flex justify-content-center">
                            <div className="col-12">
                                <p className="font-medium-2 text-base text-center">{message}</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-10 d-flex justify-content-between">
                                <button className="btn btn-small btn-cancel-style2" onClick={closeModal}>No</button>
                                <button className="btn btn-small btn-save-style2" onClick={() => handleConfirm()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  )
}
