// TODO: API calls
import { getListOrgGroupInfo } from "../models/groups_model"

// const
const GET_GROUP_LIST = 'GET_GROUP_LIST'
const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS'
const GET_GROUP_LIST_ERROR = 'GET_GROUP_LIST_ERROR'
const CLEAN_GROUP_LIST = 'CLEAN_GROUP_LIST'

const initialState = {
    fetching: false,
    error: false,
    groupList: [],
}

// reducer
const groupListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GROUP_LIST:
            return {
                ...state,
                fetching: true,
                groupList: []
            }

        case GET_GROUP_LIST_SUCCESS:
            return {
                ...action.payload
            }

        case GET_GROUP_LIST_ERROR:
            return {
                fetching: false,
                error: {
                    message: action.payload,
                },
                groupList: [],
            }

        case CLEAN_GROUP_LIST:
            return {
                ...initialState,
            }

        default:
            return state
    }
}

export default groupListReducer

const saveStorage = function (groups) {
    sessionStorage.setItem('groups', btoa(JSON.stringify(groups)))
}

export const getGroups = () => (dispatch, getState) => {
    dispatch({
        type: GET_GROUP_LIST
    })
    getListOrgGroupInfo().then((response) => {
        if (response.code === 1) {
            dispatch({
                type: GET_GROUP_LIST_SUCCESS,
                payload: {
                    fetching: true,
                    error: false,
                    groupList: response.getListOrgGroupInfo,
                },
            })
            saveStorage(getState().groupList)
        } else {
            dispatch({
                type: GET_GROUP_LIST_ERROR,
                payload: {
                    message: "Hubo un problema para obtener los grupos. Contacte al equipo de soporte o intente nuevamente más tarde.",
                },
            })
        }
    })
}

export const restoreGroupAction = () => (dispatch) => {
    if (sessionStorage.getItem('groups') !== null) {
        const groups = JSON.parse(atob(sessionStorage.getItem('groups')))
        dispatch({
            type: GET_GROUP_LIST_SUCCESS,
            payload: groups,
        })
    }
}

export const cleanGroup = () => (dispatch, _getState) => {
    dispatch({
        type: CLEAN_GROUP_LIST,
    })
}

export const cleanGroupList = () => (dispatch, _getState) => {
    dispatch({
        type: GET_GROUP_LIST
    })
}