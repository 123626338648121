import React, { useEffect}  from 'react'
import { Link } from 'react-router-dom'
import GroupConctactForm from './GroupConctactForm';
import EnterpriseCard from '../enterprise/EnterpriseCard';

import $ from "jquery";
// TODO: Generic tools
import { handleDateToyyyymmdd } from '../../../helpers/genericFunc'
import GroupBenefitsForm from './GroupBenefitsForm';
// TODO: Tools

const GeneralDataGroupForm = ({
    generalData,
    setGeneralData,
    groupContactsList,
    groupProducts,
    setServices,
    services,
    handleOnChangeContact,
    handleDeleteContact,
    handlemanualSendWelcomeEmail,
    handleSubmitForm,
    setHideHeader,
    setShowForm,
    setEnterpriseObj,
    setEntRegisterDate,
    totalContactAdmin,
    enterprisesListAdd,
    setGroupInfo,
    groupName,
    setIndexTemporalEnterprise,
    handleDeleteCard,
    planList,
    groupBenefitPlans,
    handleOnChangeBenefitPlan,
    handleDeleteBenefitPlan,
}) => {
    const {
        groupEnterpriseGroupId,
        groupCommercialName,
        // adminType,
        listOrganization,
    } = generalData

    useEffect(() => {
        const arrServices = [
            {
                idProduct: 1,
                idCategory: 1,
                idSubCategory: 1,
                description: 'Estado Emocional',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 1,
                idSubCategory: 2,
                description: 'Entorno Laboral',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 2,
                idSubCategory: 0,
                description: 'Clima Laboral',
                checked: true,
                status: 1,
                onDate: '',
            },
            {
                idProduct: 1,
                idCategory: 4,
                idSubCategory: 0,
                description: 'Cultura Organizacional',
                checked: true,
                status: 1,
                onDate: '',
            },
        ]
        if (groupEnterpriseGroupId > 0) {
            let newArr = []
            arrServices.forEach(subData => {
                let recorded = false
                groupProducts.forEach(data => {
                    if (data.idProduct === subData.idProduct && data.idCategory === subData.idCategory && data.idSubCategory === subData.idSubCategory) {
                        newArr.push({
                            ...subData,
                            checked: data.active === 1 ? true : false,
                            onDate: handleDateToyyyymmdd(data.onDate),
                        })
                        recorded = true
                    } 
                })
                if (!recorded) {
                    // newArr.push(subData)
                    newArr.push({
                        ...subData,
                        checked: false,
                    })
                }
            })
            //console.log(newArr)
            setServices(newArr)
        } else {
            if(enterprisesListAdd && enterprisesListAdd.length === 0 ) {
                // console.log('no se agregaron empresas');
                setServices(arrServices) 
            }
        }
    }, [groupEnterpriseGroupId, groupProducts,enterprisesListAdd])


    const handleGenDataOnChange = e => {
        setGeneralData({
            ...generalData,
            [e.target.name]: e.target.value,
        })
    }

    const handleServices = (productID, categoryID, subcategoryID) => {
        let newArr = []
        services.forEach(data => {
            if (data.idProduct === productID && data.idCategory === categoryID && data.idSubCategory === subcategoryID) {
                newArr.push({
                    ...data,
                    checked: !data.checked,
                    onDate: data.onDate === '' ? handleDateToyyyymmdd(new Date()) : data.onDate,
                })
            } else {
                newArr.push(data)
            }
        })
        setServices(newArr)
    }
    
  return (
    <div className='col-12 mt-5'>
        <div className='row'>
            <div className='col-11 mx-auto'>
                <div className='row d-flex justify-content-between mb-5'>
                    <div className='col-lg-12 col-xl-12 col-xxl-12'>
                        <div className='form'>
                            <input
                                type='text'
                                className='form__input fs-0875em'
                                placeholder=' '
                                maxLength="100"
                                name='groupCommercialName'
                                id='groupCommercialName'
                                value={groupCommercialName}
                                onChange={(e) => {
                                    handleGenDataOnChange(e)
                                    if(e.target.value === ''){
                                        $('#groupCommercialName').addClass('border-bottom-red')
                                    }else{
                                        $('#groupCommercialName').removeClass('border-bottom-red')
                                    }

                                }}
                            />
                            <label className='form__label'>
                                Nombre grupo:
                            </label>
                        </div>
                    </div>
                    {/* <div className='col-lg-4 col-xl-4 col-xxl-3'>
                        <div className='form-groups'>
                            <label className='label-groups'>
                                Tipo de administración:
                            </label>
                            <div className='row d-flex justify-content-between'>
                                <div className='col-6'>
                                    <div className="form-check form-check-guay" style={{marginTop:'1.3rem'}}>
                                        <input className="form-check-input icon-size-15x15" type="radio" disabled id="chkCentralizada" name="adminType" value={1} onChange={handleGenDataOnChange} checked={parseInt(adminType) === 1}/>
                                        <label className="form-check-label font-family-quicksand">Centralizada</label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-check form-check-guay" style={{marginTop:'1.3rem'}}>
                                        <input className="form-check-input icon-size-15x15" type="radio" id="chkIndividual" name="adminType" value={2} onChange={handleGenDataOnChange} checked={parseInt(adminType) === 2}/>
                                        <label className="form-check-label font-family-quicksand">Individual</label>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div> */}
                </div>
                <div className='row mb-5'>
                        <div className='col-12 mb-3 border-bottom pb-3'>
                            <span className='fs-1em fw-bold font-family-quicksand'>Servicios</span>
                        </div>
                        {services.map(data => (
                            <div
                                className='col-3'
                                key={`servicio-${data.idProduct}${data.idCategory}${data.idSubCategory}`}
                            >
                                <div
                                    className="form-check form-check-guay pointer"
                                    onClick={() => data.status === 1 && handleServices(data.idProduct, data.idCategory, data.idSubCategory)}
                                >
                                    <input
                                        className="form-check-input icon-size-15x15 border-sec-blue-guay pointer"
                                        type="checkbox"
                                        id={`${data.idProduct}-${data.idCategory}-${data.idSubCategory}`}
                                        name={`${data.idProduct}-${data.idCategory}-${data.idSubCategory}`}
                                        value={`${data.idProduct}-${data.idCategory}-${data.idSubCategory}`}
                                        checked={data.checked}
                                        disabled={data.status === 0 ? true : false}
                                        readOnly={true}
                                    />
                                    <label className="form-check-label font-family-quicksand fs-0875em pointer">{data.description}</label>
                                </div>
                            </div>
                        ))}
                    </div>
                <div className='row mb-5'>
                    <div className='col-12 mb-3 border-bottom pb-3'>
                        <span className='fs-1em fw-bold font-family-quicksand'>Beneficios</span>
                    </div>
                    {groupBenefitPlans.map((data,index) => (
                        <GroupBenefitsForm
                            data={data}
                            currentIndex={index}
                            planList={planList}
                            handleOnChangeBenefitPlan={handleOnChangeBenefitPlan}
                            handleDeleteBenefitPlan={handleDeleteBenefitPlan}
                            totalRegister={groupBenefitPlans.length}
                            key={`group-benefit-plan-${index + 1}`}
                        />
                    ))

                    }
                </div>

                <div className='row mb-5'>
                    <div className='col-12 mb-3 border-bottom pb-3' id='contactGroupDiv'>
                        <span className='fs-1em fw-bold font-family-quicksand'>Envío de correo de bienvenida</span>
                    </div>
                    
                    {groupContactsList.map((data, index) => (
                        <GroupConctactForm
                            data={data}
                            currentIndex={index}
                            handleOnChangeFunction={handleOnChangeContact}
                            handleDeleteFunction={handleDeleteContact}
                            handlemanualSendWelcomeEmail={handlemanualSendWelcomeEmail}
                            totalRegister={groupContactsList.length}
                            groupEnterpriseGroupId={groupEnterpriseGroupId}
                            totalContactAdmin={totalContactAdmin}
                            key={`contact-group-${index + 1}`}
                        />
                    ))}
                </div>
                <div className='row mb-5'>
                    <div className='col-12 mb-3 border-bottom pb-3' id='contactGroupOrg'>
                        <span className='fs-1em fw-bold font-family-quicksand'>Organizaciones</span>
                    </div>
                    <div className='row d-flex mx-auto mt-3'>
                        <div className='col-2 mb-4 d-flex justify-content-center'>
                            <div
                                className='card rounded-10px border-0 shadow-sm py-3 h-100 pointer text-decoration-none w-100'
                                onClick={() => {
                                    setShowForm(true)
                                    setHideHeader(true)
                                    setIndexTemporalEnterprise('')
                                }}
                            >
                                <div className='card-body d-flex flex-wrap align-content-center'>
                                    <div className='row'>
                                        <div className='col-12 mb-3 d-flex justify-content-center'>
                                            <img
                                                src='./assets/img/icons/icono_agregar_inactivo_disable.png'
                                                className='img-h30px'
                                                alt='logo'
                                            />
                                        </div>
                                        <div className='col-12 mb-1 d-flex justify-content-center'>
                                            <span className='fs-0625em text-ad-grey-white-guay font-family-quicksand text-center'>Agregar organización</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            listOrganization && listOrganization.length > 0  &&
                            
                            listOrganization.map(data => (
                                <EnterpriseCard
                                    enterpriseData={data}
                                    setEnterpriseObj={setEnterpriseObj}
                                    services={services}
                                    setShowForm={setShowForm}
                                    setHideHeader={setHideHeader}
                                    setEntRegisterDate={setEntRegisterDate}
                                    setGroupInfo={setGroupInfo}
                                    idgroup={generalData.groupEnterpriseGroupId}
                                    groupName={groupName}
                                    setIndexTemporalEnterprise={setIndexTemporalEnterprise}
                                    totalEnterprisesGroup={listOrganization.length}
                                    handleDeleteCard={handleDeleteCard}
                                    key={`enterprise-${data.idEnterprise}`}

                                />
                            ))
                        }
                        {
                            enterprisesListAdd && enterprisesListAdd.length > 0 &&
                            enterprisesListAdd.map((data,index) => (
                                <EnterpriseCard
                                    enterpriseData={data}
                                    setEnterpriseObj={setEnterpriseObj}
                                    services={services}

                                    setShowForm={setShowForm}
                                    setHideHeader={setHideHeader}
                                    setEntRegisterDate={setEntRegisterDate}
                                    setGroupInfo={setGroupInfo}
                                    idgroup={generalData.groupEnterpriseGroupId}
                                    groupName={groupName}
                                    index={index}
                                    setIndexTemporalEnterprise={setIndexTemporalEnterprise}
                                    totalEnterprisesGroup={listOrganization.length}
                                    handleDeleteCard={handleDeleteCard}
                                    key={`group-${groupEnterpriseGroupId}-index-${index}`}

                                />
                            ))
                        }
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-6'>
                                <Link
                                    to='/groups-enterprises'
                                    className='d-flex flex-wrap align-content-center pointer text-decoration-none text-blue'
                                >
                                    <i className='bi bi-chevron-left fs-1 fw-bold' /><span className='d-flex font-family-quicksand flex-wrap align-content-center'>Atrás</span>
                                </Link>
                            </div>
                            <div className='col-6 d-flex justify-content-end flex-wrap align-content-center'>
                                <button className='btn btn-sm bg-blue-navy text-white font-family-quicksand rounded-pill px-4' onClick={(e) => handleSubmitForm(e)}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
  )
}

export default GeneralDataGroupForm
