import React from 'react'
// TODO: API calls
import { savePlanPromotionInfo } from '../../../models/promotions_model'
// TODO: Components
import DragDrop from '../generic/DragDrop'
// TODO: Tools
import * as toastr from 'toastr'
import $ from 'jquery'

const Form = ({
    // TODO: Hooks
    plan,
    setPlan,
    columns,
    setColumns,
    // TODO: Functions
    resetForm,
    handleScroll,
}) => {

    const {
        code,
        title,
        description,
    } = plan

    const handelOnChange = e => {
        setPlan({
            ...plan,
            [e.target.name]: e.target.value,
        })
    }

    const requiredFilds = () => {
        let flag = true
        const handleMessage = (text, element) => {
            $(`#${element}`).addClass('border-danger')
            toastr.info(text, '¡Ooops!')
            handleScroll('element')
            flag = false
        }
        if (code.trim() === '') {
            handleMessage('El campo Código de plan es obligatorio.', 'code')
            return flag
        }
        if (title.trim() === '') {
            handleMessage('El campo Título es obligatorio.', 'title')
            return flag
        }
        if (description.trim() === '') {
            handleMessage('El campo Descripción es obligatorio.', 'description')
            return flag
        }
        if (columns[2].items.length === 0) {
            handleMessage('Debe tener por lo menos un beneficio agregado al plan.', 'dragdropPlans')
            return flag
        }
        return flag
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (requiredFilds()) {
            //console.log(columns[2])
            savePlanPromotionInfo(plan, columns[2].items).then(response => {
                if (response.validation) {
                    //console.log(response.data)
                    toastr.success('Se guardó correctamente.', '¡Éxito!')
                    resetForm()
                    //setPlans(response.data.plans)
                }
            })

        }
    }

    return (
        <div className="col-12">
            <div className='card border-0 shadow rounded-25px generic__show-main-container container-1'>
                <div className='card-body'>
                    <form
                        className='row'
                        onSubmit={handleSubmit}
                    >
                        <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container top container-2'>
                            <div className='row'>
                                <div className='col-sm-11 col-lg-6 col-xxl-6'>
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text fw-bold w-15">Código de plan</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="code"
                                            id="code"
                                            value={code}
                                            onChange={handelOnChange}
                                            maxLength='95'
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-11 col-lg-6 col-xxl-6'>
                                    <div className="input-group input-group-guay mb-3">
                                        <span className="input-group-text fw-bold w-15">Título</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            id="title"
                                            value={title}
                                            onChange={handelOnChange}
                                            maxLength='145'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container top container-2'>
                            <div className="input-group input-group-guay mb-3">
                                <span className="input-group-text fw-bold w-15">Descripción</span>
                                <textarea
                                    type="text"
                                    className="form-control textarea"
                                    name="description"
                                    id="description"
                                    value={description}
                                    onChange={handelOnChange}
                                    rows='3'
                                    maxLength='1450'
                                ></textarea>
                            </div>
                        </div>
                        <DragDrop
                            columns={columns}
                            setColumns={setColumns}
                            idKey={'idPromotion'}
                        />
                        <div className="col-sm-11 col-lg-11 col-xxl-10 mx-auto generic__show-container top container-2 d-flex justify-content-between">
                            <button
                                type='button'
                                className='btn btn-sm bg-blue-navy text-white'
                                onClick={resetForm}
                            >Regresar</button>
                            <input
                                type="submit"
                                className="btn btn-sm btn-pink-guay text-white"
                                value="Guardar"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Form