import React, { useState, useEffect } from 'react';
// API calls
import { getSectorCat } from '../../models/catalog_model';
import { getSponsorList } from '../../models/sponsor_model';
// Components
import SponsorList from './SponsorList';
import SponsorProfile from './SponsorProfile';

const Sponsors = () => {
    const [sponsors, setSponsors] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [subSectors, setSubSectors] = useState([]);
    const [sponsorProfile, setSponsorProfile] = useState({
        logo: '',
        register_date: '',
        dateOfRenovation: '',
        organization: '',
        sector: '',
        subSector: '',
        webPage: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
    });
    const [section, setSection] = useState(1);
    useEffect(() => {
        getSponsorList().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        setSponsors(response.listSponsor);
                        break;
                    default:
                        break;
                }
            }
        });
        getSectorCat().then(response => {
            if (response) {
                setSectors(response);
            }
        });
    }, []);
    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <h3>Patrocinadores</h3>
                </div>
                {
                    section === 1 ? (
                        <SponsorList
                            sponsors={sponsors}
                            setSponsorProfile={setSponsorProfile}
                            setSection={setSection}
                            setSubSectors={setSubSectors}
                        />
                    ) : (null)
                }
                {
                    section === 2 ? (
                        <SponsorProfile
                            sponsorProfile={sponsorProfile}
                            sectors={sectors}
                            subSectors={subSectors}
                            setSection={setSection}
                        />
                    ) : (null)
                }
            </div>
        </div>
    )
}

export default Sponsors
