import React, {
    useState,
    useEffect,
} from 'react';
// API calls
import {
    getSectorCat,
    getCountries,
} from '../../models/catalog_model';
import { getFreeOrganizations } from '../../models/sponsor_model';
// Components
import OrgList from './OrgList';
import OrgProfile from './OrgProfile';

const Organizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [subSectors, setSubSectors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [entProfile, setEntProfile] = useState({
        comercial: '',
        short: '',
        email: '',
        sector: '',
        subSector: '',
        country: '',
        anio: '',
        collaborators: '',
        zip: '',
        idState: '',
        idTown: '',
        idNeighborhood: '',
        street: '',
        numExt: '',
        numInt: '',
    });
    const [section, setSection] = useState(1);
    useEffect(() => {
        getFreeOrganizations().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        setOrganizations(response.listFreeOrganizations);
                        break;
                    default:
                        break;
                }
            }
        });
        getSectorCat().then(response => {
            if (response) {
                setSectors(response);
            }
        });
        getCountries().then(response => {
            if (response) {
                setCountries(response);
            }
        });
    }, []);
    return (
        <div className="col-11 mx-auto my-5">
            <div className="row">
                <div className="col-12 mb-3">
                    <h3>Organizaciones</h3>
                </div>
                {
                    section === 1 ? (
                        <div className="col-12">
                            <OrgList
                                organizations={organizations}
                                setEntProfile={setEntProfile}
                                setSection={setSection}
                                setSubSectors={setSubSectors}
                            />
                        </div>
                    ) : (null)
                }
                {
                    section === 2 ? (
                        <div className="col-12">
                            <OrgProfile
                                entProfile={entProfile}
                                sectors={sectors}
                                countries={countries}
                                subSectors={subSectors}
                                setSection={setSection}
                                setEntProfile={setEntProfile}
                            />
                        </div>
                    ) : (null)
                }
            </div>
        </div>
    );
}

export default Organizations;
